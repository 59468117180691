import { isAxiosError } from "axios";
import { _has } from "../lodash-utils";

export const getErrorMessage = (
  error: any,
  trans: (text: string) => string = (text: string) => text
) => {
  if (isAxiosError(error)) {
    switch (error.code) {
      case "ERR_NETWORK":
        return trans("Network Error");
    }
    return error.message;
  }

  return _has(error, "response.data.message")
    ? error.response.data.message
    : trans("Something went wrong");
};
