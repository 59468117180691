import React, { useContext } from "react";
import { HotelStateContext } from "../../../context/Hotel";
import { requestStatusIsLoading } from "../../../utils/apiCall";
import IpNotPermitted from "../../Pages/IpNotPermitted";

interface iProps {
  children: any;
}

const IpValidatorGuard: React.FC<iProps> = ({ children }) => {
  const {
    activeProperty,
    state: { status },
  } = useContext(HotelStateContext)!;

  if (requestStatusIsLoading(status) || !activeProperty._id.length) return null;

  if (!activeProperty.ipValidated) {
    return <IpNotPermitted />;
  }

  return <>{children}</>;
};

export default IpValidatorGuard;
