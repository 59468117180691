import { useEffect, useState } from "react";

const useElementForPortal = (id: string, force?: boolean) => {
  const [state, setState] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!force) {
      setState(document.getElementById(id));
      return;
    }

    async function getElement() {
      let el = document.getElementById(id);
      if (force) {
        while (!el) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          el = document.getElementById(id);
        }
      }
      setState(el);
    }

    getElement();
  }, [id, force]);

  return state;
};

export default useElementForPortal;
