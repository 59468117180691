import React, { useContext } from "react";
import AddManualDataButton from "../../../components/Buttons/addManualDataButton";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import HistoricDataConsumptionGraphSection from "../../../components/HistoricDataConsumptionGraphSection";
import PageBottomPadding from "../../../components/PageBottomPadding";
import RoadmapTable from "../../../components/RoadmapTable";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { HotelStateContext } from "../../../context/Hotel";
import { PermissionsContext } from "../../../context/Permissions";
import useGetDefaultConverionFactors from "../../../hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { hotelGetMeasuresTrackedInManualData } from "../../../utils/hotels/manualData";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../utils/hotels/subscriptions";
import Showcasing from "./showcasing";
import Unlock from "./unlock";

interface iFuelsWrappedProps {}

const FuelsWrapped: React.FC<iFuelsWrappedProps> = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const measuresTrackedInManualData = hotelGetMeasuresTrackedInManualData(activeProperty);

  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.water,
    initialStatus: measuresTrackedInManualData.water ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });

  return (
    <Flex column gap={40}>
      <HistoricDataConsumptionGraphSection {...{ conversionFactorsRequest, measure: "fuels" }} />
      <Showcasing />
      <RoadmapTable filterByType="fuels" />
    </Flex>
  );
};

const FuelsGuarded: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const { hasPermission } = useContext(PermissionsContext)!;

  return (
    <>
      {propertyActiveSubscriptionTypeIsStarter({ property: activeProperty }) ? (
        <Unlock />
      ) : (
        <div>
          <SecondaryTopBar>
            <div>
              {hasPermission("create:manual-data") && (
                <AddManualDataButton modalProps={{ initialStep: "naturalGas", singleStep: true }} />
              )}
            </div>
          </SecondaryTopBar>
          <FuelsWrapped />
        </div>
      )}
    </>
  );
};

const Fuels: React.FC = () => {
  return (
    <IpValidatorGuard>
      <FuelsGuarded />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Fuels;
