import moment, { MomentInput } from "moment";
import React from "react";
import { COLORS } from "../../../utils/colors";
import ApexChart, { iApexChartProps } from "./Chart";

export interface iAreaChartProps {
  id: string;
  series: iApexChartProps["series"];
  xAxisDateFormat?: string;
  categories: string[];
  yAxis?: iApexChartProps["yaxis"];
}

export const Y_AXIS_BASE_OPTIONS = {
  labels: {
    style: {
      colors: COLORS.secondary,
    },
  },
};

const AreaChart: React.FC<iAreaChartProps> = ({
  id,
  series,
  categories,
  xAxisDateFormat = "DD/MM/YYYY",
  yAxis,
}) => {
  return (
    <ApexChart
      type="line"
      series={series}
      categories={categories}
      {...{
        id,
        stroke: {
          width: 5,
        },
        xaxis: {
          categories,
          type: "category",
          tickAmount: 5,
          labels: {
            rotate: 0,
            show: true,
            formatter: (date: MomentInput) => moment(date).format(xAxisDateFormat),
            style: {
              colors: COLORS.secondary,
            },
          },
        },
        yaxis: yAxis || Y_AXIS_BASE_OPTIONS,
        grid: {
          borderColor: COLORS.gray_400,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
      }}
    />
    // <ReactApexChart
    //   {...{
    //     series: [
    //       {
    //         name: "Website Blog",
    //         type: "column",
    //         data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
    //       },

    //       {
    //         name: "Social Media",
    //         type: "line",
    //         data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
    //       },
    //     ],
    //     options: {
    //       chart: {
    //         height: 350,
    //         type: "line",
    //       },
    //       stroke: {
    //         width: [0, 4],
    //       },
    //       title: {
    //         text: "Traffic Sources",
    //       },
    //       dataLabels: {
    //         enabled: true,
    //         enabledOnSeries: [1],
    //       },
    //       labels: [
    //         "01 Jan 2001",
    //         "02 Jan 2001",
    //         "03 Jan 2001",
    //         "04 Jan 2001",
    //         "05 Jan 2001",
    //         "06 Jan 2001",
    //         "07 Jan 2001",
    //         "08 Jan 2001",
    //         "09 Jan 2001",
    //         "10 Jan 2001",
    //         "11 Jan 2001",
    //         "12 Jan 2001",
    //       ],
    //       yaxis: [
    //         {
    //           title: {
    //             text: "Website Blog",
    //           },
    //         },
    //         {
    //           opposite: true,
    //           title: {
    //             text: "Social Media",
    //           },
    //         },
    //       ],
    //     },
    //   }}
    //   type="line"
    //   series={series}
    //   categories={categories}
    //   {...{
    //     id,
    //     stroke: {
    //       width: 5,
    //     },
    //     xaxis: {
    //       categories,
    //       type: "category",
    //       tickAmount: 5,
    //       labels: {
    //         rotate: 0,
    //         show: true,
    //         formatter: (date: MomentInput) => moment(date).format(xAxisDateFormat),
    //         style: {
    //           colors: COLORS.secondary,
    //         },
    //       },
    //     },
    //     yaxis: yAxis || Y_AXIS_BASE_OPTIONS,
    //     grid: {
    //       borderColor: COLORS.gray_400,
    //       xaxis: {
    //         lines: {
    //           show: false,
    //         },
    //       },
    //     },
    //   }}
    // />
  );
};

export default AreaChart;
