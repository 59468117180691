import { at, sum } from "lodash";
import moment, { MomentInput } from "moment";
import { useContext, useState } from "react";
import { ReactComponent as InsightsIcon } from "../../../../assets/icons/insights.svg";
import indicatorsPlaceholder from "../../../../assets/placeholders/indicators.png";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex/Chart";
import NumberFormatted from "../../../../components/Numbers/NumberFormatted";
import PageSection from "../../../../components/PageSection";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../components/Text";
import InterTag from "../../../../components/Text/Inter";
import { toTextProps } from "../../../../components/Text/utils";
import UnlockPageSection from "../../../../components/UnlockPageSection";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import nUseGetMeasures from "../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { tBinUnitSingular } from "../../../../interfaces/sensorData";
import { TE, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  FORCE_ENERGY_MEASURE_UNIT,
  FORCE_FOOTPRINT_MEASURE_UNIT,
  FORCE_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { generateGraphCategories } from "../../../../utils/graphs";
import { hotelGetMeasuresTracked } from "../../../../utils/hotels/measures";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../../utils/hotels/subscriptions";
import { getMeasureInfo } from "../../../../utils/measures";
import { numberFormatter } from "../../../../utils/numbers";
import { NOYTRALL_0 } from "../../../../utils/others";

interface iIndicatorsWrappedProps extends iIndicatorsProps {}

const IndicatorsWrapped: React.FC<iIndicatorsWrappedProps> = ({
  measuresRequest,
  homologueMeasuresRequest,
  period,
  binUnit,
  binValue,
}) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const trackedMeasures = hotelGetMeasuresTracked(activeProperty);

  const segments: {
    label: iTextProps | string;
    key: "te" | "tw" | "co2";
    converter: nUnitConverter.tFunction;
  }[] = [];

  if (trackedMeasures.te || trackedMeasures.tw) {
    segments.push({
      label: {
        text: trans("general.co_2_emissions"),
        asHTML: true,
      },
      key: "co2",
      converter: convertMassUnit,
    });

    if (trackedMeasures.te)
      segments.push({
        label: trans("general.measures_.electricity"),
        key: "te",
        converter: convertEnergyUnit,
      });
    if (trackedMeasures.tw)
      segments.push({
        label: trans("general.measures_.water"),
        key: "tw",
        converter: convertVolumeUnit,
      });
  }

  if (segments.length === 0) return null;

  const [mainValueSize, mainValueUnitSize, homologueSize] = isMobile ? [26, 14, 14] : [36, 16, 16];

  const renderSegments = () => {
    return segments.map(({ label, key, converter }, i) => {
      let valueColor = COLORS.secondary;
      let labelColor = COLORS.gray_400;

      if (selectedSegmentIndex === i && !isMobile) {
        valueColor = COLORS.primary;
        labelColor = COLORS.primary;
      }

      return (
        <Flex
          key={key}
          column
          gap={8}
          basis={14}
          style={{
            ...(isMobile ? {} : { cursor: "pointer" }),
            padding: "6px",
            borderRadius: "8px",
          }}
          {...{
            ...(isMobile
              ? {}
              : {
                  onClick: () => {
                    if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
                  },
                  className: "hover-darken-white-bg",
                }),
          }}
        >
          <InterTag size={12} color={labelColor} {...{ ...toTextProps(label) }} />
          <Flex column gap={8}>
            {measuresRequest.isLoading ? (
              <>
                <SkeletonText width={"70%"} height={mainValueSize} />
                <SkeletonText width={"70%"} height={homologueSize} />
              </>
            ) : measuresRequest.isResolved ? (
              (() => {
                const path =
                  key === "te"
                    ? "grouped.te.measurements"
                    : key === "tw"
                    ? "grouped.tw.measurements"
                    : "co2";

                const mainData = at(measuresRequest.data, path)[0] as number[];
                const mainSum = sum(mainData);

                const converted = converter(mainSum);
                return (
                  <>
                    <div className="flex flex-row gap-1 items-end">
                      <NumberFormatted
                        Wrapper={InterTag}
                        props={{ size: mainValueSize, color: valueColor }}
                        number={converted.value}
                      />
                      <InterTag
                        color={valueColor}
                        size={mainValueUnitSize}
                        text={converted.unitInHTML}
                        asHTML
                      />
                    </div>
                    {homologueMeasuresRequest.isLoading ? (
                      <SkeletonText width={"70%"} height={homologueSize} />
                    ) : (
                      (() => {
                        const homologueData = at(
                          homologueMeasuresRequest.data,
                          path
                        )[0] as number[];
                        const homologueSum = sum(homologueData);

                        return <TrendingComparison baseValue={homologueSum} value={mainSum} />;
                      })()
                    )}
                  </>
                );
              })()
            ) : null}
          </Flex>
        </Flex>
      );
    });
  };

  const graph = () => {
    if (measuresRequest.isLoading) return <SkeletonText width={"100%"} height={288} />;

    const selectedSegment = segments[selectedSegmentIndex];

    const [path, color, converter, unit] = (() => {
      switch (selectedSegment.key) {
        case "co2":
          return ["co2", COLORS.esg_environment, convertMassUnit, FORCE_FOOTPRINT_MEASURE_UNIT];
        case "te":
          return [
            "grouped.te.measurements",
            getMeasureInfo(TE).color,
            convertEnergyUnit,
            FORCE_ENERGY_MEASURE_UNIT,
          ];
        case "tw":
          return [
            "grouped.tw.measurements",
            getMeasureInfo(TW).color,
            convertVolumeUnit,
            FORCE_WATER_MEASURE_UNIT,
          ];
      }
    })();

    const categories = generateGraphCategories(binUnit, binValue, period[0], period[1]);

    let type: "bar" | "line" = "line";

    const series: any[] = [];

    if (measuresRequest.isResolved) {
      const mainData = ((at(measuresRequest.data, path)[0] || []) as number[]).map(
        (v) => converter(v, { forceUnit: unit }).value
      );

      series.push({
        name: trans("general.period_main"),
        data: mainData,
        color,
      });
      type = mainData.length > 2 ? "line" : "bar";
    }
    if (homologueMeasuresRequest.isResolved) {
      const homologueData = ((at(homologueMeasuresRequest.data, path)[0] || []) as number[]).map(
        (v) => converter(v, { forceUnit: unit }).value
      );

      series.push({
        name: trans("general.period_homologue"),
        data: homologueData,
        color: COLORS.gray_500,
      });
      type = homologueData.length > 2 ? "line" : "bar";
    }
    // return null;
    return (
      <ApexChart
        {...{
          type,
          series,
          stroke: { width: 2 },
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${!val ? NOYTRALL_0 : numberFormatter(val, profile)} ${unit}`;
              },
            },
          },
          xaxis: {
            categories,
            tickAmount: 5,
            labels: {
              rotate: 0,
              show: true,
              formatter(value: string, timestamp?: number, opts?: any) {
                return moment(value).format("DD/MM/YYYY");
              },
            },
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            x: {
              formatter(val: any, opts: any) {
                const date = categories[opts.dataPointIndex];
                return moment(date).format("DD MMM YYYY");
              },
            },
            y: {
              formatter(val: any, opts: any) {
                return `${!val ? NOYTRALL_0 : numberFormatter(val, profile)} ${unit}`;
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            showForSingleSeries: true,
          },
        }}
      />
    );
  };

  return (
    <Flex className="card-m" column>
      <Flex row gap={16} wrap>
        {renderSegments()}
      </Flex>
      {!isMobile && graph()}
    </Flex>
  );
};

interface iIndicatorsProps {
  period: [MomentInput, MomentInput];
  binUnit: tBinUnitSingular;
  binValue: number;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
}

const Indicators: React.FC<iIndicatorsProps> = ({ ...props }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  return (
    <Flex column gap={16}>
      <PageSection
        icon={InsightsIcon}
        title={trans("pages.sustainability.sections.indicators_evolution.title")}
        description={trans("pages.sustainability.sections.indicators_evolution.description")}
      />
      {activeSubscriptionIsStarter ? (
        <UnlockPageSection
          style={{ paddingBottom: "180px" }}
          image={indicatorsPlaceholder}
          title={trans("pages.sustainability.sections.indicators_evolution.unlock.title")}
          description={[0].map((i) =>
            trans(`pages.sustainability.sections.indicators_evolution.unlock.description[${i}]`)
          )}
        />
      ) : (
        <IndicatorsWrapped {...{ ...props }} />
      )}
    </Flex>
  );
};

export default Indicators;
