import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import moment, { MomentInput } from "moment";
import { useContext } from "react";
import { HotelStateContext } from "../../context/Hotel";
import { tHotelSpaceId } from "../../models/hotel";
import { constructApiAddress, USE_SERVER } from "../../utils/apiCall";

type tTotalConsumption = Record<"e" | "w", number>;

function useGetConsumptionTotal(
  id: string,
  params: {
    timeframe: [MomentInput, MomentInput];
    enabled?: boolean;
    spaces?: tHotelSpaceId[];
    measures?: ("e" | "w")[];
  }[]
) {
  const { activeProperty } = useContext(HotelStateContext)!;

  const requests = useQueries<tTotalConsumption[]>({
    queries: params.map(
      ({ timeframe: [startDate, endDate], enabled = false, spaces, measures }) => ({
        queryKey: [`consumption.total|${id}|${activeProperty._id}|${startDate}|${endDate}`],
        enabled,
        queryFn: async ({ signal }: { signal: AbortSignal }) => {
          return axios
            .post<{ consumption: tTotalConsumption }>(
              constructApiAddress(
                `/properties/${activeProperty._id}/consumption/total`,
                USE_SERVER.propertiesMicroservice.local
              ),
              {
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString(),
                spaces,
                measures,
              },
              { signal }
            )
            .then((res) => {
              return res.data.consumption;
            });
        },
      })
    ),
  });

  return requests;
}

export default useGetConsumptionTotal;
