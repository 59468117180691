import React from "react";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import InterTag from "../../Text/Inter";

interface iProps {}

const NoPermissions: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={28} middle>
      <img
        src="https://noytrall-property-app-assets.s3.eu-west-1.amazonaws.com/Do+not+enter+sign-amico+1.svg"
        alt=""
      />
      <Flex column gap={20} middle>
        {[0, 1].map((i) => (
          <InterTag
            key={i}
            size={36}
            text={trans(`components.no_permissions.title[${i}]`)}
            color={COLORS.secondary}
          />
        ))}
      </Flex>
      <Flex column gap={16}>
        {[0, 1, 2].map((i) => (
          <InterTag
            key={i}
            style={{ textWrap: "wrap", textAlign: "center" }}
            size={16}
            color={COLORS.secondary}
            text={trans(`components.no_permissions.description[${i}]`)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default NoPermissions;
