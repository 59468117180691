import React, { PropsWithChildren } from "react";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as HelpIcon } from "../../assets/icons/help.svg";
import useContentDimensionsState from "../../context/ContentDimensions/hooks/useContentDimensions";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { tIcon, tIconElement } from "../../interfaces/icon";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";

export interface iPageSectionProps {
  icon?: tIcon | tIconElement;
  title: string | iTextProps;
  description?: string | iTextProps;
  sideComponent?: React.ReactNode;
  [key: string]: any;
}

const PageSection: React.FC<PropsWithChildren<iPageSectionProps>> = ({
  icon,
  title,
  description,
  children,
  sideComponent,
  ...rest
}) => {
  const { isMobile } = useWindowSizeState();
  const { PageSection } = useContentDimensionsState();

  const renderDescription = () => {
    if (!description || isMobile) return null;

    const descriptionProps = toTextProps(description);
    return (
      <InterTag
        {...{
          size: PageSection.description,
          color: COLORS.gray_600,
          wrap: true,
          ...descriptionProps,
          style: {
            ...descriptionProps.style,
            textAlign: "left",
            textWrap: "wrap",
            lineHeight: `${PageSection.descriptionLineHeight}px`,
          },
        }}
      />
    );
  };

  const renderTitle = () => {
    if (isMobile && description)
      return (
        <Whisper
          placement="bottomStart"
          speaker={<Tooltip>{toTextProps(description).text}</Tooltip>}
          trigger={"click"}
        >
          <Flex row gap={4} top>
            <Flex basis={95}>
              <InterTag
                {...{
                  wrap: true,
                  size: PageSection.title,
                  color: COLORS.secondary,
                  ...toTextProps(title),
                }}
              />
            </Flex>
            <Icon Element={HelpIcon} size={12} fill={COLORS.secondary} />
          </Flex>
        </Whisper>
      );

    return (
      <InterTag
        {...{
          wrap: true,
          size: PageSection.title,
          color: COLORS.secondary,
          ...toTextProps(title),
        }}
      />
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-2">
              {icon && (
                <Icon
                  {...{
                    fill: COLORS.secondary,
                    size: PageSection.icon,
                    ...toIconProps(icon),
                  }}
                />
              )}
              {renderTitle()}
            </div>
            {description && renderDescription()}
          </div>
          {sideComponent && <div className="flex-1">{sideComponent}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageSection;
