import axios from "axios";
import { min } from "lodash";
import React, { useContext, useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as AssignmentIcon } from "../../../../assets/icons/assignment.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import { HotelStateContext } from "../../../../context/Hotel";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../../context/Permissions";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useListStaff from "../../../../hooks/useListStaff";
import useSimpleToaster from "../../../../hooks/useSimpleToaster";
import { tPropertyStaffRole, tPropertyStaffRoleId } from "../../../../models/hotel";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  hotelCheckAccountsShareRoles,
  hotelListVisibleRoles,
} from "../../../../utils/hotels/staff";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { _find } from "../../../../utils/lodash-utils";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../../utils/tables";
import CreateEditRole from "./createEditRole";

type tTableItem = tPropertyStaffRole;

interface iProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const RolesTab: React.FC<iProps> = ({ staffRequest }) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { profile } = useProfileState();
  const { updateHotel } = useHotelDispatch();
  const [roleModal, setRoleModal] = useState<{
    open: boolean;
    roleId?: tPropertyStaffRoleId;
  }>({ open: false, roleId: undefined });
  const deleteRequest = useDeleteRequest();
  const { hasPermission } = useContext(PermissionsContext)!;

  const tableData: tTableItem[] = hotelListVisibleRoles(activeProperty, profile._id);

  const removeStaffRole = (roleId: tPropertyStaffRoleId) => {
    deleteRequest.pending();
    axios
      .delete(
        constructApiAddress(
          `/v2/hotels/${activeProperty._id}/staff-roles/${roleId}`,
          USE_MONOLITH_SERVERLESS
        )
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(activeProperty._id, hotel);
        deleteRequest.resolve();
        toaster.success(
          trans("pages.users.sections.management.segments.roles.notifications.role_removed")
        );
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        deleteRequest.reject(error);
        toaster.error(error);
      });
  };

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (hasPermission("update:roles"))
      options.push({
        key: "edit",
        label: trans("pages.users.sections.management.segments.roles.table.options.edit_role"),
        icon: AssignmentIcon,
        onClick() {
          setRoleModal({
            open: true,
            roleId: item._id,
          });
        },
      });

    if (!item.isAdmin && hasPermission("delete:roles"))
      options.push({
        key: "remove",
        label: {
          color: COLORS.error,
          text: trans("pages.users.sections.management.segments.roles.table.options.remove_role"),
        },
        icon: { Element: DeleteIcon, fill: COLORS.error },
        onClick() {
          removeStaffRole(item._id);
        },
      });
    return options;
  };

  const tableHeight = (min([10, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  return (
    <>
      <CreateEditRole
        open={roleModal.open}
        roleId={roleModal.roleId}
        onClose={() => {
          setRoleModal({ open: false });
        }}
        staffRequest={staffRequest}
      />
      <div className="table-wrapper">
        <Table
          height={tableHeight}
          id="STAFF.TEAMS.TABLE"
          headerHeight={TABLE_HEADER_HEIGHT}
          rowHeight={TABLE_ROW_HEIGHT.M}
          data={tableData}
          loading={deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={AssignmentIcon} name={trans("general.roles")} />
            <SimpleKeyValueCell dataKey="name" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={GroupIcon} name={trans("general.users")} />
            <SimpleKeyValueCell
              dataKey="users"
              textFunction={(item: tTableItem) => {
                const filtered = item.staff.filter((s) => {
                  if (profile._id === s) return true;

                  const staffObj = _find(activeProperty.staff, (st) => st.staffId === s);

                  if (!staffObj) return false;

                  if (!staffObj.shadowAccount) return true;

                  return hotelCheckAccountsShareRoles(
                    activeProperty,
                    profile._id,
                    staffObj.staffId
                  );
                });
                return filtered.length;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

export default RolesTab;
