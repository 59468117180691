import React, { useContext, useState } from "react";
import { Button, ButtonGroup, Tooltip, Whisper } from "rsuite";
import { ReactComponent as ConversionPathIcon } from "../../../assets/icons/conversion_path.svg";
import { ReactComponent as LineAxisIcon } from "../../../assets/icons/line_axis.svg";
import { ReactComponent as TabsIcon } from "../../../assets/icons/tabs.svg";
import DataTypeToggle from "../../../components/DataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import Icon from "../../../components/Icons/Icon";
import { toIconProps } from "../../../components/Icons/Icon/utils";
import RoadmapTable from "../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { DataTypeStateContext } from "../../../context/DataType";
import { TimeframeDispatchContext, TimeframeStateContext } from "../../../context/Timeframe";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import WithContextProvider from "../../../hoc/withProvider";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import SustainabilityContextProvider from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";

export type tBenchmarkSizes = {
  titleSize: number;
  titleIconSize: number;
  typeSize: number;
  typeIconSize: number;
  mainValueSize: number;
  mainValueUnitSize: number;
  mainValuePadding: number;
  benchmarkSize: number;
};

interface iSustainabilityWrappedProps {}

const SustainabilityWrapped: React.FC<iSustainabilityWrappedProps> = () => {
  const [selectedSection, setSelectedSection] = useState(0);
  const dataTypeContext = useContext(DataTypeStateContext)!;
  const timeframeContext = useContext(TimeframeStateContext)!;
  const timeframeDispatchContext = useContext(TimeframeDispatchContext)!;
  const { isMobile } = useWindowSizeState();

  const benchmarkSizes: tBenchmarkSizes = isMobile
    ? {
        titleSize: 18,
        titleIconSize: 22,
        typeSize: 12,
        typeIconSize: 22,
        mainValueSize: 30,
        mainValueUnitSize: 14,
        mainValuePadding: 20,
        benchmarkSize: 14,
      }
    : {
        titleSize: 20,
        titleIconSize: 24,
        typeSize: 12,
        typeIconSize: 24,
        mainValueSize: 36,
        mainValueUnitSize: 16,
        mainValuePadding: 40,
        benchmarkSize: 16,
      };

  const renderButtons = () => {
    const list: { key: string; label: string; icon: tIconElement }[] = [
      { key: "indicators", label: "Indicators", icon: TabsIcon },
      { key: "evolution", label: "Evolution", icon: LineAxisIcon },
      { key: "roadmap", label: "Roadmap", icon: ConversionPathIcon },
    ];
    return (
      <ButtonGroup>
        {list.map(({ key, label, icon }, i) => (
          <Whisper
            key={key}
            trigger="hover"
            placement="bottom"
            speaker={<Tooltip>{label}</Tooltip>}
          >
            <Button
              onClick={() => setSelectedSection(i)}
              appearance={selectedSection === i ? "primary" : "ghost"}
            >
              <Icon {...{ ...toIconProps(icon), size: 16, fill: COLORS.secondary }} />
            </Button>
          </Whisper>
        ))}
      </ButtonGroup>
    );
  };

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 items-center">
            <DataTypeToggle disabled={selectedSection === 2} />
            {dataTypeContext.dataTypeIsLive ? (
              <SimpleDateRangePicker
                disabled={selectedSection === 2}
                value={timeframeContext.data.sensorDataTimeframe}
                onChange={timeframeDispatchContext.setSensorDataTimeframe}
                style={{ paddingLeft: "8px" }}
              />
            ) : (
              <SimpleDateRangePickerForManualDataPeriods2
                {...{
                  manualDataIndex: timeframeContext.data.manualDataIndex,
                  setManualDataIndex: timeframeDispatchContext.setManualDataIndex,
                }}
              />
            )}
          </div>
          {/* <div>{renderButtons()}</div> */}
        </div>
      </SecondaryTopBar>
      <div className="flex flex-col gap-10">
        {dataTypeContext.dataTypeIsLive ? (
          <SensorData
            {...{
              benchmarkSizes,
              selectedSection,
            }}
          />
        ) : (
          <ManualData benchmarkSizes={benchmarkSizes} />
        )}
        <RoadmapTable />
        {/* <div>{selectedSection === 2 ? <RoadmapTable /> : null}</div> */}
        <div />
      </div>
    </div>
  );
};

const Sustainability: React.FC = () => {
  return (
    <IpValidatorGuard>
      <SustainabilityWrapped />
    </IpValidatorGuard>
  );
};

export default WithContextProvider(SustainabilityContextProvider, Sustainability);
