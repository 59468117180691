import { find, floor, min } from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import { Table } from "rsuite";
import { ReactComponent as CalendarClockIcon } from "../../../assets/icons/calendar_clock.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as SellIcon } from "../../../assets/icons/sell.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VolunteerActivismIcon } from "../../../assets/icons/volunteer_activism.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import { toIconProps } from "../../../components/Icons/Icon/utils";
import PageSection from "../../../components/PageSection";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tUseGetRequest } from "../../../hooks/apiRequests/useGetRequest";
import useScreenSize from "../../../hooks/useScreenSize";
import { tHotelAward } from "../../../models/hotel";
import { tReservationAward } from "../../../models/reservation";
import { COLORS } from "../../../utils/colors";
import { getAwardDescription, getAwardTypeInfo } from "../../../utils/hotels/awards";
import { tGuestReservations } from "./guest";

type tTableItem = tReservationAward & { awardObj: tHotelAward };

interface iAwardsWrappedProps extends Pick<iAwardProps, "staysRequest"> {
  outletContainerWidth: number;
}

const AwardsWrapped: React.FC<iAwardsWrappedProps> = ({ outletContainerWidth, staysRequest }) => {
  const { trans, language } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const tableData: any[] = staysRequest.isResolved
    ? Object.values(staysRequest.data.reservations).flatMap((list) => {
        return list.flatMap((r) =>
          r.awards
            .map((ra) => {
              const a = find(activeProperty.awards, (a) => a._id === ra.award);
              if (!a) return null;
              return { ...ra, awardObj: a };
            })
            .filter((o) => o !== null)
        );
      })
    : [];

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "view Award",
      label: trans("View Award"),
      onClick() {},
      icon: VisibilityIcon,
    });

    return options;
  };

  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight = staysRequest.isLoading
    ? rowHeight + headerHeight
    : min([400, tableData.length * rowHeight + headerHeight]);

  const typeWidth = floor(0.15 * outletContainerWidth);
  const descriptionWidth = floor(0.15 * outletContainerWidth);
  const discountWidth = floor(0.15 * outletContainerWidth);
  const receivedWidth = floor(0.15 * outletContainerWidth);
  const validityWidth = floor(0.15 * outletContainerWidth);
  const amountWidth = floor(0.15 * outletContainerWidth);

  return (
    <div className="table-wrapper">
      <Table
        id="TABLE.GUEST.PREVIOUS-STAYS"
        height={tableHeight}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={staysRequest.isLoading}
        data={tableData}
      >
        <Table.Column width={typeWidth}>
          <SimpleHeaderCell name={trans("Type")} icon={CategoryIcon} />
          <BaseCell>
            {(item: tTableItem) => {
              const {
                awardObj: { type },
              } = item;
              const info = getAwardTypeInfo(type);
              return (
                <Flex
                  style={{
                    padding: "4px",
                    width: "fit-content",
                    borderRadius: "4px",
                  }}
                  middle
                  gap={4}
                >
                  <Icon
                    {...{
                      fill: COLORS.secondary,
                      size: 14,
                      ...toIconProps(info.icon),
                    }}
                  />
                  <InterTag size={12} text={trans(type)} color={COLORS.secondary} />
                </Flex>
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={descriptionWidth}>
          <SimpleHeaderCell icon={VerifiedIcon} name={trans("Award")} />
          <SimpleKeyValueCell
            tooltipDisplay
            tooltipTextFunction={(rowData: tTableItem) =>
              `${getAwardDescription(rowData.awardObj, language)?.longDescription}`
            }
            dataKey="awardObj.shortDescription"
            textFunction={(rowData: tTableItem) =>
              `${getAwardDescription(rowData.awardObj, language)?.shortDescription}`
            }
          />
        </Table.Column>
        <Table.Column width={discountWidth}>
          <SimpleHeaderCell icon={SellIcon} name={trans("Discount")} />
          <SimpleKeyValueCell
            dataKey="awardObj.discount"
            textFunction={(rowData: tTableItem) => {
              const {
                awardObj: { discount, discountType },
              } = rowData;
              if (discountType === "nominal") return `${discount}€`;

              if (discount === 100) return trans("Offer");
              return `${discount}%`;
            }}
          />
        </Table.Column>
        <Table.Column width={receivedWidth}>
          <SimpleHeaderCell icon={VolunteerActivismIcon} name={trans("Received")} />
          <SimpleDateCell dataKey="timestamp" />
        </Table.Column>
        <Table.Column width={validityWidth}>
          <SimpleHeaderCell icon={CalendarClockIcon} name={trans("Validity")} />
          <BaseCell>
            {(item: tTableItem) => {
              const { validUntil } = item;

              return (
                <Flex>
                  <InterTag
                    size={12}
                    color={COLORS.secondary}
                    text={moment(validUntil).format("DD MMM YYYY").toLowerCase()}
                  />
                </Flex>
              );
            }}
          </BaseCell>
        </Table.Column>
        <Table.Column width={amountWidth}>
          <SimpleHeaderCell icon={StacksIcon} name={trans("Amount")} />
          <SimpleKeyValueCell dataKey="amount" />
        </Table.Column>
        <Table.Column flexGrow={1} align="right">
          <SimpleHeaderCell />
          <SimpleActionCell options={options} dataKey="_id" />
        </Table.Column>
      </Table>
    </div>
  );
};

interface iAwardProps {
  staysRequest: tUseGetRequest<tGuestReservations>;
}

const Awards: React.FC<iAwardProps> = ({ staysRequest }) => {
  const { trans } = useLocalizationState();
  const { outlet } = useScreenSize();

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("Awards")}
        description={trans("Track discounts and perks earned, redeemable, or enjoyed by the guest")}
        icon={VerifiedIcon}
      />
      {outlet ? (
        <AwardsWrapped staysRequest={staysRequest} outletContainerWidth={outlet.width} />
      ) : null}
    </Flex>
  );
};

export default Awards;
