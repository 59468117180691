import axios from "axios";
import { cloneDeep, find, isUndefined, uniqueId } from "lodash";
import React, { useContext, useState } from "react";
import { IconButton, Input, InputNumber, Modal, ModalProps, SelectPicker } from "rsuite";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as MoreVertIcon } from "../../assets/icons/more_vert.svg";
import { HotelStateContext } from "../../context/Hotel";
import useHotelDispatch from "../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import usePatchRequest from "../../hooks/apiRequests/usePatchRequest";
import useSimpleToaster from "../../hooks/useSimpleToaster";
import { tHotelRoadmap } from "../../models/hotel";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../utils/apiCall";
import { COLORS } from "../../utils/colors";
import {
  HOTEL_ROADMAP_STATUSES,
  hotelRoadmapDifficultyInfo,
  hotelRoadmapPhaseNumberInfo,
  hotelRoadmapStatusInfo,
  hotelRoadmapTypeInfo,
  ROADMAP_DIFFICULTIES,
  ROADMAP_PHASE_NUMBERS,
  ROADMAP_TYPES,
} from "../../utils/hotels/roadmap";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { _toInteger } from "../../utils/lodash-utils";
import { getDescription, preventDefault } from "../../utils/others";
import { DEFAULT_MODAL_PROPS } from "../../utils/rsuite/modals";
import Modal2ActionButtons from "../Buttons/modal2ActionButtons";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import InputWrapper from "../InputWrapper";
import PageSection from "../PageSection";
import SimpleWhisperPopoverDropdown, {
  tSimpleWhisperPopoverDropdownOptions,
} from "../RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../Text/Inter";

const buildTransKey = (keyword: string) => `components.roadmap.modals.add_item.${keyword}`;

type tItem = Pick<
  tHotelRoadmap,
  "phaseNumber" | "descriptions" | "difficulty" | "status" | "type" | "order" | "_id"
>;

interface iAddItemModalWrappedProps extends Pick<iProps, "onClose" | "roadmapId"> {}

const AddItemModalWrapped: React.FC<iAddItemModalWrappedProps> = ({ onClose, roadmapId }) => {
  const toaster = useSimpleToaster();
  const { trans, language } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const request = usePatchRequest();
  const [state, setState] = useState<{
    items: tItem[];
    selected: number;
  }>(() => {
    if (!roadmapId)
      return {
        selected: 0,
        items: [
          {
            _id: uniqueId(),
            phaseNumber: 1,
            descriptions: [{ language: language, description: "" }],
            difficulty: "low",
            status: "idle",
            type: "electricity",
            order: activeProperty.roadmap.length + 1,
          },
        ],
      };

    const item = find(activeProperty.roadmap, (r) => r._id === roadmapId);

    if (!item) {
      return {
        selected: 0,
        items: [
          {
            _id: uniqueId(),
            phaseNumber: 1,
            descriptions: [{ language: language, description: "" }],
            difficulty: "low",
            status: "idle",
            type: "electricity",
            order: activeProperty.roadmap.length + 1,
          },
        ],
      };
    }

    return {
      selected: 0,
      items: [
        {
          _id: item._id,
          phaseNumber: item.phaseNumber,
          descriptions: cloneDeep(item.descriptions),
          difficulty: item.difficulty,
          status: item.status,
          type: item.type,
          order: item.order,
        },
      ],
    };
  });

  const [errorsState, setErrorsState] = useState({
    order: false,
    descriptions: false,
  });

  const activeItem = state.items[state.selected];

  const handleConfirm = () => {
    request.pending();
    (roadmapId
      ? axios
          .patch(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/roadmap/${roadmapId}`,
              USE_MONOLITH_SERVERLESS
            ),
            {
              item: {
                ...state.items[0],
                phaseNumber: _toInteger(state.items[0].phaseNumber),
                order: _toInteger(state.items[0].order),
              },
            }
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(activeProperty._id, hotel);
            request.resolve(
              {},
              trans("general.updated_x", {
                parameters: [trans("general.item")],
              })
            );
            onClose();
          })
      : axios
          .post(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/roadmap/`,
              USE_MONOLITH_SERVERLESS
            ),
            {
              items: state.items.map((item) => ({
                ...item,
                phaseNumber: _toInteger(item.phaseNumber),
                order: _toInteger(item.order),
              })),
            }
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(activeProperty._id, hotel);
            request.resolve(
              {},
              trans("general.created_x", {
                parameters: [trans("general.item")],
              })
            );
            onClose();
          })
    ).catch((err) => {
      const error = getErrorMessage(err, trans);
      updateHotel(activeProperty._id, {});
      request.reject(error, true);
    });
  };

  const handleChangeItem = (index: number) => (property: keyof tItem) => (value: any) => {
    setState((prev) => {
      prev.items[index] = { ...prev.items[index], [property]: value };
      return { ...prev, items: [...prev.items] };
    });
  };

  const renderTabs = () => {
    const newTab = () => {
      if (state.items.length === 20) {
        toaster.error(trans(buildTransKey("notifications.max_number_of_items")));
        return;
      }
      setState((prev) => ({
        ...prev,
        items: [
          ...prev.items,
          {
            _id: uniqueId(),
            phaseNumber: 1,
            descriptions: [{ language: language, description: "" }],
            difficulty: "low",
            status: "idle",
            type: "electricity",
            order: activeProperty.roadmap.length + prev.items.length + 1,
          },
        ],
        selected: prev.items.length,
      }));
      setErrorsState((prev) => ({ ...prev, descriptions: true }));
    };

    const options: tSimpleWhisperPopoverDropdownOptions[] = [
      {
        key: "remove-all-but-1",
        label: trans("components.tab.options.remove_all_but_1"),
        onClick: () => {
          setState((prev) => {
            const item = prev.items[prev.selected];
            return { ...prev, items: [item], selected: 0 };
          });
        },
        show: state.items.length > 1,
      },
    ];
    return (
      <div
        style={{ width: "100%", borderBottom: `${COLORS.gray_200} 1px solid` }}
        className={"horizontal-scrollbar"}
      >
        <Flex row>
          <Flex style={{ overflow: "auto", maxWidth: "90%" }} row>
            {state.items.map((item, i) => {
              const typeInfo = hotelRoadmapTypeInfo(item.type);
              return (
                <Flex
                  middle
                  center
                  row
                  color={state.selected === i ? COLORS.gray_200 : COLORS.gray_50}
                  gap={4}
                  key={item._id}
                  onClick={() => {
                    setState((prev) => ({ ...prev, selected: i }));
                  }}
                  size="xs"
                  className="hover-gray-200"
                  style={{
                    padding: "4px 8px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <InterTag text={trans(typeInfo.transKey)} size={12} color={COLORS.secondary} />
                  <InterTag text={`(${item.order})`} color={COLORS.error} size={10} />
                  {state.items.length > 1 && (
                    <IconButton
                      className="hover-gray-300"
                      onClick={(e) => {
                        e.stopPropagation();
                        setState((prev) => {
                          const items = prev.items;
                          items.splice(i, 1);
                          return {
                            ...prev,
                            items: [...items],
                            selected:
                              prev.selected >= items.length ? prev.selected - 1 : prev.selected,
                          };
                        });
                      }}
                      style={{ padding: "3px" }}
                      circle
                      size="xs"
                      appearance="subtle"
                      icon={<Icon Element={CloseIcon} fill={COLORS.secondary} size={12} />}
                    />
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Flex middle center row gap={4} style={{ width: "10%" }}>
            <IconButton
              size="xs"
              icon={<Icon Element={AddIcon} fill={COLORS.secondary} size={16} />}
              onClick={newTab}
            />
            {options.filter((o) => o.show).length > 0 && (
              <SimpleWhisperPopoverDropdown options={options}>
                <IconButton
                  size="xs"
                  icon={<Icon Element={MoreVertIcon} fill={COLORS.secondary} size={16} />}
                />
              </SimpleWhisperPopoverDropdown>
            )}
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={20}>
        <PageSection
          title={`${trans(buildTransKey("title"))}${
            isUndefined(roadmapId) ? ` (${state.items.length})` : ""
          }`}
        />
        {isUndefined(roadmapId) && (
          <Flex column gap={8}>
            {renderTabs()}
            <Flex column gap={4}>
              {errorsState.order && (
                <InterTag
                  size={12}
                  color={COLORS.error}
                  text={trans(buildTransKey("errors.multiple_items_same_order_number"))}
                />
              )}
              {errorsState.descriptions && (
                <InterTag
                  size={12}
                  color={COLORS.error}
                  text={trans(buildTransKey("errors.some_items_no_description"))}
                />
              )}
            </Flex>
          </Flex>
        )}
        <form onSubmit={preventDefault(handleConfirm)}>
          <Flex column gap={12}>
            <Flex row gap={8}>
              <Flex basis={50}>
                <InputWrapper style={{ width: "100%" }} label={trans("Type")}>
                  <SelectPicker
                    disabled={request.isLoading}
                    value={activeItem.type}
                    onChange={(value) => {
                      handleChangeItem(state.selected)("type")(value);
                    }}
                    cleanable={false}
                    data={ROADMAP_TYPES.map((type) => {
                      return {
                        label: trans(hotelRoadmapTypeInfo(type).transKey),
                        value: type,
                      };
                    })}
                  />
                </InputWrapper>
              </Flex>
              <Flex basis={50}>
                <InputWrapper style={{ width: "100%" }} label={trans("Order")}>
                  <InputNumber
                    disabled={request.isLoading}
                    max={activeProperty.roadmap.length + state.items.length}
                    value={activeItem.order}
                    onChange={(value) => {
                      handleChangeItem(state.selected)("order")(value);
                      for (let i = 0; i < state.items.length - 1; i++) {
                        const order1 = _toInteger(
                          i === state.selected ? value : state.items[i].order
                        );
                        for (let j = i + 1; j < state.items.length; j++) {
                          const order2 = _toInteger(
                            j === state.selected ? value : state.items[j].order
                          );
                          if (order1 === order2) {
                            setErrorsState((prev) => ({
                              ...prev,
                              order: true,
                            }));
                            return;
                          }
                        }
                      }
                      setErrorsState((prev) => ({ ...prev, order: false }));
                    }}
                  />
                </InputWrapper>
              </Flex>
            </Flex>
            <Flex row gap={8}>
              <Flex basis={50}>
                <InputWrapper style={{ width: "100%" }} label={trans("Difficulty")}>
                  <SelectPicker
                    disabled={request.isLoading}
                    value={activeItem.difficulty}
                    onChange={(value) => {
                      handleChangeItem(state.selected)("difficulty")(value);
                    }}
                    cleanable={false}
                    data={ROADMAP_DIFFICULTIES.map((difficulty) => {
                      return {
                        label: trans(hotelRoadmapDifficultyInfo(difficulty).transKey),
                        value: difficulty,
                      };
                    })}
                  />
                </InputWrapper>
              </Flex>
              <Flex basis={50}>
                <InputWrapper style={{ width: "100%" }} label={trans("Status")}>
                  <SelectPicker
                    disabled={request.isLoading}
                    value={activeItem.status}
                    onChange={(value) => {
                      handleChangeItem(state.selected)("status")(value);
                    }}
                    cleanable={false}
                    data={HOTEL_ROADMAP_STATUSES.map((status) => {
                      return {
                        label: trans(hotelRoadmapStatusInfo(status).transKey),
                        value: status,
                      };
                    })}
                  />
                </InputWrapper>
              </Flex>
            </Flex>
            <InputWrapper style={{ width: "100%" }} label={trans("Phase")}>
              <SelectPicker
                disabled={request.isLoading}
                value={activeItem.phaseNumber}
                onChange={(value) => {
                  handleChangeItem(state.selected)("phaseNumber")(value);
                }}
                cleanable={false}
                data={ROADMAP_PHASE_NUMBERS.map((phaseNumber) => {
                  return {
                    label: trans(hotelRoadmapPhaseNumberInfo(phaseNumber).transKey),
                    value: phaseNumber,
                  };
                })}
              />
            </InputWrapper>
            <InputWrapper label={trans("general.description")}>
              <Input
                disabled={request.isLoading}
                as="textarea"
                value={getDescription(activeItem.descriptions, language)?.description || ""}
                onChange={(value) => {
                  setState((prev) => {
                    const item = prev.items[prev.selected];

                    const showLanguage =
                      getDescription(activeItem.descriptions, language)?.language || language;

                    const obj = find(item.descriptions, (d) => d.language === showLanguage);
                    if (obj) {
                      obj.description = value;
                    }
                    if (item.descriptions.filter((d) => d.description.length).length === 0) {
                      if (!errorsState.descriptions)
                        setErrorsState((prev) => ({
                          ...prev,
                          descriptions: true,
                        }));
                    } else {
                      if (errorsState.descriptions)
                        setErrorsState((prev) => ({
                          ...prev,
                          descriptions: false,
                        }));
                    }
                    return { ...prev };
                  });
                }}
              />
            </InputWrapper>
          </Flex>
          <input type="submit" style={{ visibility: "hidden" }} />
        </form>
        <Modal2ActionButtons
          confirmButtonDisabled={Object.values(errorsState).filter((v) => v).length > 0}
          isLoading={request.isLoading}
          onClose={onClose}
          onConfirm={handleConfirm}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  roadmapId?: string;
}

const AddItemModal: React.FC<iProps> = ({ onClose, roadmapId, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "lg", onClose, ...props }}>
      <AddItemModalWrapped {...{ onClose, roadmapId }} />
    </Modal>
  );
};

export default AddItemModal;
