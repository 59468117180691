import { sortBy, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Flex from "../../../components/Flex";
import ApexChart from "../../../components/Graphs/Apex/Chart";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
import { GRAPH_COLORS_ELECTRICITY } from "../../../utils/electricity";
import { GRAPH_COLORS_FUELS } from "../../../utils/fuels";
import { generateGraphCategories } from "../../../utils/graphs";
import { hotelGetManualDataByYearWithoutZeroValueYears } from "../../../utils/hotels/manualData";
import {
  calcLaundryEstimatedElectricityKWhPerTon,
  calcLaundryEstimatedWaterM3PerTon,
  calcLaundryGasolineLitersPerTon,
  calcLaundryNaturalGasKWhPerTon,
} from "../../../utils/laundry";
import { _round, _toInteger } from "../../../utils/lodash-utils";
import { numberFormatter } from "../../../utils/numbers";
import { GRAPH_COLORS_WATER } from "../../../utils/water";

interface iProps {
  manualDataByYear: Record<string, tWithRequired<tHotelManualData, "laundry">[]>;
}

const Graph: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const [graphType] = useState<"line" | "bar">("bar");
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const [{ manualDataByYear, selectedYears }, setState] = useState<{
    manualDataByYear: Record<string, tWithRequired<tHotelManualData, "laundry">[]>;
    selectedYears: number[];
  }>({
    manualDataByYear: {},
    selectedYears: [],
  });
  const selectedYearsRef = useRef<number[]>([]);

  useEffect(() => {
    const manualDataByYear = hotelGetManualDataByYearWithoutZeroValueYears(activeProperty, [
      "laundry",
    ]) as Record<string, tWithRequired<tHotelManualData, "laundry">[]>;
    setState({
      manualDataByYear,
      selectedYears: [...sortBy(Object.keys(manualDataByYear).map((y) => _toInteger(y)))],
    });
  }, [activeProperty]);

  const [
    outsourcedKgSeries,
    outsourcedElectricityKWhSeries,
    outsourcedWaterM3Series,
    outsourcedNaturalGasKWhSeries,
    outsourcedGasolineLitersSeries,
  ] = useMemo(() => {
    const outsourcedKgSeries: any[] = [];
    const outsourcedElectricityKWhSeries: any[] = [];
    const outsourcedWaterM3Series: any[] = [];
    const outsourcedNaturalGasKWhSeries: any[] = [];
    const outsourcedGasolineLitersSeries: any[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const outsourcedKgData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const outsourcedElectricityKWhData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const outsourcedWaterM3Data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const outsourcedNaturalGasKWhData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const outsourcedGasolineLitersData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      list.forEach(({ laundry, from }) => {
        if (!laundry) return;
        const index = moment(from).month();

        outsourcedKgData[index] = laundry.kgOutsourced;

        if (laundry.isLaundryOutsourced) {
          outsourcedKgData[index] = laundry.kgOutsourced;
          if (laundry.outsourcedElectricityConsumptionIsKnown) {
            outsourcedElectricityKWhData[index] = laundry.outsourcedElectricityConsumptionKWh;
          } else {
            outsourcedElectricityKWhData[index] = calcLaundryEstimatedElectricityKWhPerTon(
              laundry.kgOutsourced
            );
          }

          if (laundry.outsourcedWaterConsumptionIsKnown) {
            outsourcedWaterM3Data[index] = laundry.outsourcedWaterConsumptionM3;
          } else {
            outsourcedWaterM3Data[index] = calcLaundryEstimatedWaterM3PerTon(laundry.kgOutsourced);
          }

          outsourcedNaturalGasKWhData[index] = calcLaundryNaturalGasKWhPerTon(laundry.kgOutsourced);
          outsourcedGasolineLitersData[index] = calcLaundryGasolineLitersPerTon(
            laundry.kgOutsourced
          );
        }
      });

      outsourcedKgSeries.push({ name: year, data: outsourcedKgData });
      outsourcedElectricityKWhSeries.push({
        name: year,
        data: outsourcedElectricityKWhData,
      });
      outsourcedWaterM3Series.push({ name: year, data: outsourcedWaterM3Data });
      outsourcedNaturalGasKWhSeries.push({
        name: year,
        data: outsourcedNaturalGasKWhData,
      });
      outsourcedGasolineLitersSeries.push({
        name: year,
        data: outsourcedGasolineLitersData,
      });
    });

    return [
      outsourcedKgSeries,
      outsourcedElectricityKWhSeries,
      outsourcedWaterM3Series,
      outsourcedNaturalGasKWhSeries,
      outsourcedGasolineLitersSeries,
    ];
  }, [manualDataByYear]);

  const [
    outsourcedKgValue,
    outsourcedElectricityKWh,
    outsourcedWaterM3,
    outsourcedNaturalGasKWh,
    outsourcedGasolineLiters,
  ] = useMemo(() => {
    let outsourcedKgValue = 0;
    let outsourcedElectricityKWh = 0;
    let outsourcedWaterM3 = 0;
    let outsourcedNaturalGasKWh = 0;
    let outsourcedGasolineLiters = 0;

    selectedYears.forEach((year) => {
      manualDataByYear[year].forEach(({ laundry }) => {
        if (laundry.isLaundryOutsourced) {
          outsourcedKgValue += laundry.kgOutsourced;
          if (laundry.outsourcedElectricityConsumptionIsKnown) {
            outsourcedElectricityKWh += laundry.outsourcedElectricityConsumptionKWh;
          } else {
            outsourcedElectricityKWh += calcLaundryEstimatedElectricityKWhPerTon(
              laundry.kgOutsourced
            );
          }

          if (laundry.outsourcedWaterConsumptionIsKnown) {
            outsourcedWaterM3 += laundry.outsourcedWaterConsumptionM3;
          } else {
            outsourcedWaterM3 += calcLaundryEstimatedWaterM3PerTon(laundry.kgOutsourced);
          }

          outsourcedNaturalGasKWh += calcLaundryNaturalGasKWhPerTon(laundry.kgOutsourced);
          outsourcedGasolineLiters += calcLaundryGasolineLitersPerTon(laundry.kgOutsourced);
        }
      });
    });
    return [
      outsourcedKgValue,
      outsourcedElectricityKWh,
      outsourcedWaterM3,
      outsourcedNaturalGasKWh,
      outsourcedGasolineLiters,
    ];
  }, [manualDataByYear, selectedYears]);

  const segments: {
    key: string;
    label: string;
    value: number;
    mainUnit: string;
    series: number[];
    graphColors: string[];
  }[] = [
    {
      key: "outsourcedKg",
      label: trans("general.outsourced"),
      value: outsourcedKgValue,
      mainUnit: "kg",
      series: outsourcedKgSeries,
      graphColors: [COLORS.primary_900, COLORS.primary_600, COLORS.primary_300],
    },
    {
      key: "electricity",
      label: trans("general.measures_.electricity"),
      value: outsourcedElectricityKWh,
      mainUnit: "kWh",
      series: outsourcedElectricityKWhSeries,
      graphColors: GRAPH_COLORS_ELECTRICITY,
    },
    {
      key: "water",
      label: trans("general.measures_.water"),
      value: outsourcedWaterM3,
      mainUnit: "m3",
      series: outsourcedWaterM3Series,
      graphColors: GRAPH_COLORS_WATER,
    },
    {
      key: "naturalGas",
      label: trans("general.fuel_types.natural_gas"),
      value: outsourcedNaturalGasKWh,
      mainUnit: "kWh",
      series: outsourcedNaturalGasKWhSeries,
      graphColors: GRAPH_COLORS_FUELS,
    },
    {
      key: "gasoline",
      label: trans("general.fuel_types.gasoline"),
      value: outsourcedGasolineLiters,
      mainUnit: "kWh",
      series: outsourcedGasolineLitersSeries,
      graphColors: GRAPH_COLORS_FUELS,
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row>
        {segments.map(({ key, label, mainUnit, value }, i) => {
          let labelColor = COLORS.gray_400;
          let valueColor = COLORS.secondary;

          if (selectedSegmentIndex === i) {
            labelColor = valueColor = COLORS.primary;
          }

          return (
            <Flex
              basis={15}
              key={key}
              column
              gap={8}
              style={{
                cursor: "pointer",
                padding: "6px",
                borderRadius: "8px",
              }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
              }}
            >
              <InterTag size={12} text={label} color={labelColor} />
              <Flex row bottom>
                <InterTag size={36} text={numberFormatter(_round(value, 2))} color={valueColor} />
                <InterTag size={12} text={mainUnit} color={valueColor} />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const renderGraph = () => {
    const categories = generateGraphCategories(
      "month",
      1,
      moment().startOf("year"),
      moment().endOf("year")
    );

    const segment = segments[selectedSegmentIndex];

    const { series, mainUnit, graphColors } = segment;

    return (
      <ApexChart
        {...{
          type: graphType,
          colors: graphColors,
          categories,
          series,
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${_round(val, 2)} ${mainUnit}`;
              },
            },
          },
          xaxis: {
            categories,
            labels: {
              show: true,
              formatter(value: any, timestamp: any, opts: any) {
                return moment(value).format("MMM");
              },
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
          },
          chart: {
            events: {
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  const year = toNumber(options.globals.seriesNames[seriesIndex]);
                  if (selectedYearsRef.current.includes(year)) {
                    setState((prev) => ({
                      ...prev,
                      selectedYears: [...prev.selectedYears.filter((y) => y !== year)],
                    }));
                  } else {
                    setState((prev) => ({
                      ...prev,
                      selectedYears: sortBy(uniq([...prev.selectedYears, year])),
                    }));
                  }
                }
              },
            },
          },
        }}
      />
    );
  };

  return (
    <div className="flex flex-col gap-6 bg-white rounded-md p-4 pb-0">
      {renderSegments()}
      {renderGraph()}
    </div>
  );
};

export default React.memo(Graph);
