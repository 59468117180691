import axios from "axios";
import React, { useState } from "react";
import { Modal, ModalProps, useToaster } from "rsuite";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import PageSection from "../../../../components/PageSection";
import SimpleInputPassword from "../../../../components/RsuiteWrapper/SimpleInputPassword";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";

interface iChangePasswordModalWrappedProps extends Pick<iProps, "onClose"> {}

const ChangePasswordModalWrapped: React.FC<iChangePasswordModalWrappedProps> = ({ onClose }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { updateProfile } = useProfileDispatch();
  const request = usePutRequest();
  const [state, setState] = useState<
    Record<"currentPassword" | "newPassword" | "newPassword2", string>
  >({
    currentPassword: "",
    newPassword: "",
    newPassword2: "",
  });

  const onChange = (key: keyof typeof state) => (value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const handleConfirm = () => {
    request.pending();
    axios
      .put(constructApiAddress(`/v2/managers/manager/change-password`, USE_MONOLITH_SERVERLESS), {
        currentPassword: state.currentPassword,
        newPassword: state.newPassword,
        newPassword2: state.newPassword2,
      })
      .then((res) => {
        const {
          data: { account },
        } = res;
        updateProfile(account);
        request.resolve();
        toaster.push(notification("success", trans("Password updated")), {
          placement: "topEnd",
        });
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
        toaster.push(notification("error", error), {
          placement: "topEnd",
        });
      });
  };

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={20}>
        <PageSection title={trans("Change Password")} />
        <InputWrapper label={trans("Current Password")} required>
          <SimpleInputPassword
            value={state.currentPassword}
            onChange={onChange("currentPassword")}
          />
        </InputWrapper>
        <InputWrapper label={trans("New Password")} required>
          <SimpleInputPassword
            value={state.newPassword}
            name="password"
            onChange={onChange("newPassword")}
            autoComplete="password"
          />
        </InputWrapper>
        <InputWrapper
          label={trans("Confirm New Password")}
          error={
            state.newPassword2.length && state.newPassword !== state.newPassword2
              ? trans("New password don't match")
              : null
          }
          required
        >
          <SimpleInputPassword value={state.newPassword2} onChange={onChange("newPassword2")} />
        </InputWrapper>
        <Modal2ActionButtons
          confirmLabel={trans("Confirm")}
          onClose={onClose}
          onConfirm={handleConfirm}
          confirmButtonDisabled={
            Object.values(state).filter((v) => !v.length).length !== 0 ||
            state.newPassword !== state.newPassword2
          }
          isLoading={request.isLoading}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
}

const ChangePasswordModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      <ChangePasswordModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default ChangePasswordModal;
