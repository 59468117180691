import React, { Fragment, useContext, useState } from "react";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import { PermissionsContext } from "../../../context/Permissions";
import SpaceModal from "./spaceModal";

interface iProps {}

const AddButton: React.FC<iProps> = () => {
  const { hasAllPermissions } = useContext(PermissionsContext)!;
  const [spaceModal, setSpaceModal] = useState<{ open: boolean }>({
    open: false,
  });

  if (!hasAllPermissions(["create:spaces"])) return null;

  return (
    <Fragment>
      <SpaceModal
        onClose={() => setSpaceModal((prev) => ({ ...prev, open: false }))}
        open={spaceModal.open}
      />
      <SimpleAddButton onClick={() => setSpaceModal((prev) => ({ ...prev, open: true }))} />
    </Fragment>
  );
};

export default AddButton;
