import { QueriesResults, useQueries, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import moment, { MomentInput } from "moment";
import { useContext, useMemo } from "react";
import { HotelStateContext } from "../../context/Hotel";
import { tHotelSpaceId } from "../../models/hotel";
import { constructApiAddress, USE_SERVER } from "../../utils/apiCall";

export type tConsumptionSeries = Record<"e" | "w", number[]>;

function useGetConsumptionSeries(
  id: string,
  params: {
    timeframe: [MomentInput, MomentInput];
    enabled?: boolean;
    spaces?: tHotelSpaceId[];
    measures?: ("e" | "w")[];
    binUnit?: "day";
    binSize?: number;
    automaticBin?: boolean;
  }[]
): UseQueryResult<tConsumptionSeries>[] {
  const { activeProperty } = useContext(HotelStateContext)!;

  const requests = useQueries<tConsumptionSeries[], QueriesResults<tConsumptionSeries[]>>({
    queries: params.map(
      ({
        timeframe: [startDate, endDate],
        enabled = false,
        spaces,
        measures,
        binUnit = "day",
        binSize = 1,
        automaticBin = false,
      }) => ({
        queryKey: [
          `consumption.total|${id}|${automaticBin}|${binUnit}|${binSize}|${activeProperty._id}|${startDate}|${endDate}`,
        ],
        enabled,
        initialData: { e: [], w: [] },
        select: (data) => data as tConsumptionSeries,
        queryFn: async ({ signal }: { signal: AbortSignal }): Promise<tConsumptionSeries> => {
          return axios
            .post<{ consumption: tConsumptionSeries }>(
              constructApiAddress(
                `/properties/${activeProperty._id}/consumption/series`,
                USE_SERVER.propertiesMicroservice.local
              ),
              {
                startDate: moment(startDate).valueOf(),
                endDate: moment(endDate).valueOf(),
                spaces,
                measures,
                binUnit,
                binSize,
              },
              { signal }
            )
            .then((res) => {
              return res.data.consumption;
            });
        },
      })
    ),
  }) as UseQueryResult<tConsumptionSeries>[];

  const statuses = JSON.stringify(
    requests.flatMap((r) => [
      r.status,
      r.isFetching,
      r.isRefetching,
      r.dataUpdatedAt,
      r.isPending,
      r.isSuccess,
    ])
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => requests, [statuses]);
}

export default useGetConsumptionSeries;
