import React, { useContext } from "react";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import ConservationLabsEvents from "../../../../components/ConservationLabs/ConservationLabsEvents";
import Flex from "../../../../components/Flex";
import MeasureConsumption from "../../../../components/MeasureConsumption";
import PageSection from "../../../../components/PageSection";
import SpaceMeasureConsumptionTable from "../../../../components/Tables/SpaceMeasureConsumptionTable";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { TimeframeStateContext } from "../../../../context/Timeframe";
import { MEASURES_WATER_SPECIFIC, tMeasureWaterSpecific, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import { convertVolumeUnit, DEFAULT_WATER_MEASURE_UNIT } from "../../../../utils/convertUnits";
import { hotelGetMeasuresTracked } from "../../../../utils/hotels/measures";

const buildTransKey = (key: string) => `pages.water.live.sections.${key}`;

const LiveData: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;

  const trackedMeasures = hotelGetMeasuresTracked(activeProperty);

  const specificMeasures: { measureKey: tMeasureWaterSpecific }[] = [];
  MEASURES_WATER_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  return (
    <div>
      <Flex column gap={40}>
        {trackedMeasures.tw ? (
          <>
            <PageSection
              {...{
                title: trans(buildTransKey("overview.title")),
                description: trans(buildTransKey("overview.description")),
                icon: QueryStatsIcon,
              }}
            >
              <MeasureConsumption
                mainMeasureKey={TW}
                specificMeasures={specificMeasures}
                converter={convertVolumeUnit}
                mainUnit={DEFAULT_WATER_MEASURE_UNIT}
                binUnit={"day"}
                binValue={1}
                from={sensorDataTimeframe[0]}
                to={sensorDataTimeframe[1]}
              />
            </PageSection>
            <PageSection
              {...{
                title: trans(buildTransKey("list.title")),
                description: trans(buildTransKey("list.description")),
                icon: { Element: WaterDropIcon, fill: COLORS.water },
              }}
            >
              <SpaceMeasureConsumptionTable
                mainMeasureKey="tw"
                converter={convertVolumeUnit}
                from={sensorDataTimeframe[0]}
                to={sensorDataTimeframe[1]}
                {...{
                  tableHeader: {
                    icon: { Element: WaterDropIcon, fill: COLORS.water },
                    name: trans("general.measures_.water"),
                  },
                }}
              />
            </PageSection>
            <PageSection title={trans(buildTransKey("events.title"))}>
              <ConservationLabsEvents from={sensorDataTimeframe[0]} to={sensorDataTimeframe[1]} />
            </PageSection>
          </>
        ) : (
          <Flex>
            <InterTag
              text={trans(buildTransKey("not_measured"))}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default LiveData;
