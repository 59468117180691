import React, { useContext } from "react";
import { ReactComponent as ControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageSection from "../../../components/PageSection";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../utils/hotels/subscriptions";
import ChallengesList from "./list";
import Unlock from "./unlock";

const ChallengesWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const { hasPermission } = useContext(PermissionsContext)!;

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!hasPermission("read:challenges")) {
    return <NoPermissions />;
  }

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSection
          title={trans("pages.challenges.sections.list.title")}
          description={trans("pages.challenges.sections.list.description")}
          icon={{ Element: ControllerIcon }}
        />
        <ChallengesList />
      </Flex>
    </Flex>
  );
};

const Challenges: React.FC = () => {
  return (
    <IpValidatorGuard>
      <ChallengesWrapped />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Challenges;
