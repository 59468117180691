import axios from "axios";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../interfaces/apiCalls";
import { constructApiAddress, REQUEST_STATUS, USE_MONOLITH_SERVERLESS } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

export type tConversionFactors = {
  natural_gas_m3_to_kWh: number;
  gasoline_L_to_kWh: number;
  diesel_L_to_kWh: number;
  ethanol_L_to_kWh: number;
  butane_kg_to_kWh: number;
  propane_kg_to_kWh: number;
  biomass_kg_to_kWh: number;
};

const useGetDefaultFuelsToEnergy = (
  country: string,
  {
    doRequest = true,
    initialStatus = REQUEST_STATUS.PENDING,
  }: { doRequest?: boolean; initialStatus?: nRequestStatus.tStatus } = {
    doRequest: true,
    initialStatus: REQUEST_STATUS.PENDING,
  }
) => {
  const { trans } = useLocalizationState();
  const factorsRequest = useGetRequest<tConversionFactors>(
    {
      natural_gas_m3_to_kWh: 0,
      gasoline_L_to_kWh: 0,
      diesel_L_to_kWh: 0,
      ethanol_L_to_kWh: 0,
      butane_kg_to_kWh: 0,
      propane_kg_to_kWh: 0,
      biomass_kg_to_kWh: 0,
    },
    { status: initialStatus }
  );

  useEffectSafe(() => {
    if (doRequest) {
      factorsRequest.pending();
      axios
        .get(constructApiAddress(`/v2/hotels/default-fuels-to-energy`, USE_MONOLITH_SERVERLESS))
        .then((res) => {
          const {
            data: {
              natural_gas_m3_to_kWh,
              gasoline_L_to_kWh,
              diesel_L_to_kWh,
              ethanol_L_to_kWh,
              butane_kg_to_kWh,
              propane_kg_to_kWh,
              biomass_kg_to_kWh,
            },
          } = res;
          factorsRequest.resolve({
            natural_gas_m3_to_kWh,
            gasoline_L_to_kWh,
            diesel_L_to_kWh,
            ethanol_L_to_kWh,
            butane_kg_to_kWh,
            propane_kg_to_kWh,
            biomass_kg_to_kWh,
          });
        })
        .catch((err) => {
          factorsRequest.reject(getErrorMessage(err, trans));
        });
    }
  }, [doRequest, trans]);

  return factorsRequest;
};

export default useGetDefaultFuelsToEnergy;
