import { cloneDeep } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../assets/icons/meeting_room.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import BasicCard, { iBasicCardProps } from "../../../../components/Cards/BasicCard";
import PieDonutChart from "../../../../components/Graphs/Apex/PieDonutChart";
import PageSectionBG from "../../../../components/PageSection/PageSectionBG";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import { TimeframeDispatchContext, TimeframeStateContext } from "../../../../context/Timeframe";
import useGetConsumptionTotalBySpace, {
  tConsumptionTotalBySpace,
} from "../../../../hooks/consumption/useGetConsumptionTotalBySpace";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { propertyIsSettingOn } from "../../../../utils/hotels/settings";
import { propertyFindSpace } from "../../../../utils/hotels/spaces";
import { _reverse, _round, _sortedIndex, _sum } from "../../../../utils/lodash-utils";
import { numberFormatter } from "../../../../utils/numbers";
import { SpacesContext } from "./context";

interface iSpacesConsumptionWrappedProps {}

const SpacesConsumptionWrapped: React.FC<iSpacesConsumptionWrappedProps> = () => {
  const { profile } = useProfileState();
  const spacesContext = useContext(SpacesContext)!;
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;
  const conversionFactors = useGetDefaultConverionFactors("PT");
  const [selectedSegment, setSelectedSegment] = useState<"co2" | "e" | "w">("co2");

  const [request] = useGetConsumptionTotalBySpace("spaces.consumption", [
    {
      timeframe: [sensorDataTimeframe[0], sensorDataTimeframe[1]],
      measures: ["e", "w"],
    },
  ]);
  useEffectSafe(() => {
    request.refetch();
  }, [sensorDataTimeframe, activeProperty]);

  const measuresObj = useMemo(() => {
    const obj: Record<"e" | "w", { series: number[]; labels: string[]; ids: string[] }> = {
      e: { series: [], labels: [], ids: [] },
      w: { series: [], labels: [], ids: [] },
    };
    if (request.isSuccess) {
      (["e", "w"] as ("e" | "w")[]).forEach((measure) => {
        Object.keys(request.data as tConsumptionTotalBySpace).forEach((spaceId) => {
          const space = propertyFindSpace(activeProperty, spaceId);
          if (space) {
            const newValue = (request.data as tConsumptionTotalBySpace)[spaceId][measure];
            if (newValue !== undefined) {
              const index = _sortedIndex(obj[measure].series, newValue);
              obj[measure].series.splice(index, 0, newValue);
              obj[measure].labels.splice(index, 0, space.name);
              obj[measure].ids.splice(index, 0, space._id);
            } else {
              obj[measure].series.unshift(0);
              obj[measure].labels.unshift(space.name);
              obj[measure].ids.unshift(space._id);
            }
          }
        });
      });
    }
    obj.e.series = _reverse(obj.e.series);
    obj.e.labels = _reverse(obj.e.labels);
    obj.e.ids = _reverse(obj.e.ids);
    obj.w.series = _reverse(obj.w.series);
    obj.w.labels = _reverse(obj.w.labels);
    obj.w.ids = _reverse(obj.w.ids);
    return obj;
  }, [request.isSuccess, request.data, activeProperty]);

  const co2Obj = useMemo(() => {
    const obj: { series: number[]; labels: string[]; ids: string[] } = {
      series: [],
      labels: [],
      ids: [],
    };

    if (request.isSuccess && conversionFactors.isResolved) {
      Object.keys(request.data as tConsumptionTotalBySpace).forEach((spaceId) => {
        const space = propertyFindSpace(activeProperty, spaceId);
        if (space) {
          const { e, w } = (request.data as tConsumptionTotalBySpace)[spaceId];
          const co2Value =
            (e || 0 / 1000) * conversionFactors.data.electricity_kWh_to_kgCO2e +
            (w || 0 / 1000) * conversionFactors.data.water_m3_to_kgCO2e;

          const index = _sortedIndex(obj.series, co2Value);
          obj.series.splice(index, 0, co2Value);
          obj.labels.splice(index, 0, space.name);
          obj.ids.splice(index, 0, space._id);
        }
      });
    }
    obj.series = _reverse(obj.series);
    obj.labels = _reverse(obj.labels);
    obj.ids = _reverse(obj.ids);
    return obj;
  }, [
    activeProperty,
    conversionFactors.data.electricity_kWh_to_kgCO2e,
    conversionFactors.data.water_m3_to_kgCO2e,
    conversionFactors.isResolved,
    request.data,
    request.isSuccess,
  ]);

  const totalObjWithSelectedSpaces = useMemo(() => {
    const totalObj: Record<
      "co2" | "e" | "w",
      { series: number[]; labels: string[]; ids: string[] }
    > = cloneDeep({
      co2: co2Obj,
      e: measuresObj.e,
      w: measuresObj.w,
    });

    Object.keys(spacesContext.state.selectedSpaces).forEach((spaceId) => {
      if (!spacesContext.state.selectedSpaces[spaceId]) {
        const eIndex = totalObj.e.ids.indexOf(spaceId);
        if (eIndex !== -1) {
          totalObj.e.series.splice(eIndex, 1);
          totalObj.e.labels.splice(eIndex, 1);
          totalObj.e.ids.splice(eIndex, 1);
        }
        const wIndex = totalObj.w.ids.indexOf(spaceId);
        if (wIndex !== -1) {
          totalObj.w.series.splice(wIndex, 1);
          totalObj.w.labels.splice(wIndex, 1);
          totalObj.w.ids.splice(wIndex, 1);
        }
        const co2Index = totalObj.co2.ids.indexOf(spaceId);
        if (co2Index !== -1) {
          totalObj.co2.series.splice(co2Index, 1);
          totalObj.co2.labels.splice(co2Index, 1);
          totalObj.co2.ids.splice(co2Index, 1);
        }
      }
    });

    return totalObj;
  }, [co2Obj, measuresObj.e, measuresObj.w, spacesContext.state.selectedSpaces]);

  const [donutColor, valueFormatter] = (() => {
    switch (true) {
      case selectedSegment === "w":
        return [
          COLORS.water,
          (val: number) => {
            const converted = convertVolumeUnit(val);
            return `${_round(converted.value, 2)} ${converted.unit}`;
          },
        ];
      case selectedSegment === "e":
        return [
          COLORS.energy,
          (val: number) => {
            const converted = convertEnergyUnit(val);
            return `${_round(converted.value, 2)} ${converted.unit}`;
          },
        ];
      case selectedSegment === "co2":
      default:
        return [
          COLORS.primary_500,
          (val: number) => {
            const converted = convertMassUnit(val);
            return `${_round(converted.value, 2)} ${converted.unit}`;
          },
        ];
    }
  })();

  const cards: ({
    key: "co2" | "e" | "w";
    hide: boolean;
    converter: nUnitConverter.tFunction;
  } & Pick<iBasicCardProps, "icon" | "label" | "valueLoading">)[] = [
    {
      key: "co2",
      hide: !propertyIsSettingOn(activeProperty, "pages.spaces.consumption.co2.show"),
      icon: { Element: EcoIcon, fill: COLORS.primary },
      label: { text: trans("general.co_2_emissions"), asHTML: true },
      valueLoading: request.isLoading || conversionFactors.isLoading,
      converter: convertMassUnit,
    },
    {
      key: "w",
      hide: !propertyIsSettingOn(activeProperty, "pages.spaces.consumption.water.show"),
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      label: trans("general.water"),
      valueLoading: request.isLoading,
      converter: convertVolumeUnit,
    },
    {
      key: "e",
      hide: !propertyIsSettingOn(activeProperty, "pages.spaces.consumption.electricity.show"),
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      label: trans("general.electricity"),
      valueLoading: request.isLoading,
      converter: convertEnergyUnit,
    },
  ];

  const selectedCard = cards.find((c) => c.key === selectedSegment);

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col justify-center gap-2 basis-1/3">
        {cards
          .filter((c) => !c.hide)
          .map(({ converter, icon, key, label, valueLoading }) => (
            <BasicCard
              key={key}
              showBorder={selectedSegment === key}
              hover
              onClick={() => setSelectedSegment(key)}
              icon={icon}
              label={label}
              className={"p-2"}
              valueLoading={valueLoading}
              value={(() => {
                const converted = converter(_sum(totalObjWithSelectedSpaces[key].series));
                return `${numberFormatter(_round(converted.value, 2), profile)} ${converted.unit}`;
              })()}
            />
          ))}
      </div>
      <div className="basis-2/3">
        {selectedCard?.valueLoading ? (
          <SkeletonText style={{ height: 300, width: "100%" }} />
        ) : (
          <PieDonutChart
            type="donut"
            series={totalObjWithSelectedSpaces[selectedSegment].series}
            labels={totalObjWithSelectedSpaces[selectedSegment].labels}
            color={donutColor}
            valueFormatter={valueFormatter}
          />
        )}
      </div>
    </div>
  );
};

const DateSelector: React.FC = () => {
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;
  const { setSensorDataTimeframe } = useContext(TimeframeDispatchContext)!;

  return (
    <SimpleDateRangePicker
      value={sensorDataTimeframe}
      onChange={setSensorDataTimeframe}
      placement="leftStart"
    />
  );
};

const SpacesConsumption: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <PageSectionBG
      title={trans("pages.spaces.tabs.spaces.consumption.title")}
      description={trans("pages.spaces.tabs.spaces.consumption.description")}
      icon={MeetingRoomIcon}
      sideComponent={
        <div className="flex flex-row justify-end">
          <DateSelector />
        </div>
      }
    >
      <SpacesConsumptionWrapped />
    </PageSectionBG>
  );
};

export default SpacesConsumption;
