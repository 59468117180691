import React, { useContext, useState } from "react";
import GettingStarted, { GettingStartedFinalModal } from "../../../../components/GettingStarted";
import { HotelStateContext } from "../../../../context/Hotel";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { tBenchmarkSizes } from "../sustainability";
import Benchmark from "./benchmark";
import Indicators from "./indicators";
import { TimeframeStateContext } from "../../../../context/Timeframe";

interface iProps {
  benchmarkSizes: tBenchmarkSizes;
}

const ManualData: React.FC<iProps> = ({ benchmarkSizes }) => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: activeProperty.manualData.length > 0,
    initialStatus: activeProperty.manualData.length ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });
  const {
    data: { manualDataIndex },
  } = useContext(TimeframeStateContext)!;

  const [gettingStartedFinalModal, setGettingStartedFinalModal] = useState(false);

  return (
    <>
      <GettingStartedFinalModal
        open={gettingStartedFinalModal}
        onClose={() => setGettingStartedFinalModal(false)}
      />

      {activeProperty.manualData.length === 0 ? (
        <GettingStarted startModalOpen={true} onInsert={() => setGettingStartedFinalModal(true)} />
      ) : manualDataIndex >= 0 ? (
        <div className="flex flex-col gap-10">
          <Benchmark {...{ conversionFactorsRequest, benchmarkSizes }} />
          <Indicators {...{ conversionFactorsRequest }} />
        </div>
      ) : null}
    </>
  );
};

export default ManualData;
