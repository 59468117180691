import { createContext, useCallback, useContext, useMemo } from "react";
import { tPropertyRolePermission } from "../../models/hotel/roles";
import { hotelListStaffMemberRoles } from "../../utils/hotels/roles";
import { _uniq } from "../../utils/lodash-utils";
import { HotelStateContext } from "../Hotel";
import useProfileState from "../Profile/hooks/useProfileState";

const PermissionsContext = createContext<{
  hasPermission(permission: tPropertyRolePermission): boolean;
  hasAllPermissions(permissions: tPropertyRolePermission[]): boolean;
  hasAnyPermissions(permissions: tPropertyRolePermission[]): boolean;
  permissionsList: tPropertyRolePermission[];
}>({
  hasPermission: () => false,
  hasAllPermissions: () => false,
  hasAnyPermissions: () => false,
  permissionsList: [],
});

const PermissionsContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { profile } = useProfileState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const permissionsList = useMemo(() => {
    const roles = hotelListStaffMemberRoles(activeProperty, profile._id);

    const permissions = roles.reduce((acc, role) => {
      return _uniq([...acc, ...(role.permissionsList || [])]);
    }, [] as tPropertyRolePermission[]);

    return permissions;
  }, [activeProperty, profile._id]);

  const hasPermission = useCallback(
    (permission: tPropertyRolePermission) => {
      return permissionsList.includes(permission);
    },
    [permissionsList]
  );

  const hasAllPermissions = (permissions: tPropertyRolePermission[]) => {
    return permissions.every((p) => permissionsList.includes(p));
  };
  const hasAnyPermissions = (permissions: tPropertyRolePermission[]) => {
    return permissions.some((p) => permissionsList.includes(p));
  };

  return (
    <PermissionsContext.Provider
      value={{ hasPermission, hasAllPermissions, hasAnyPermissions, permissionsList }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext };

export default PermissionsContextProvider;
