import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { DataTypeStateContext } from "../../../context/DataType";
import { HotelStateContext } from "../../../context/Hotel";
import WithContextProvider from "../../../hoc/withProvider";
import { propertyActiveSubscriptionTypeIsEnterprise } from "../../../utils/hotels/subscriptions";
import { ELEMENT_IDS } from "../../../utils/portal";
import ConsumptionContextProvider from "./context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const Wrapped: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const dataTypeContext = useContext(DataTypeStateContext)!;
  return (
    <>
      {!propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty }) ||
      !dataTypeContext.dataTypeIsLive ? (
        <HistoricData />
      ) : (
        <LiveData />
      )}
    </>
  );
};

const ConsumptionGuarded: React.FC = () => {
  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row gap-2 items-center">
          <DataTypeToggle />
          <div id={ELEMENT_IDS.consumptionPageSecondaryTopBar}></div>
        </div>
      </SecondaryTopBar>
      <Wrapped />
    </div>
  );
};

const Consumption: React.FC = () => {
  return (
    <IpValidatorGuard>
      <ConsumptionGuarded />
    </IpValidatorGuard>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Consumption);
