import React, { useRef } from "react";
import { IconButton, IconButtonProps } from "rsuite";
import { COLORS } from "../../../utils/colors";
import SimpleReloader from "../../RsuiteWrapper/SimpleReloader";

interface iProps extends IconButtonProps {
  iconProps?: { color?: string; size?: number };
  on?: boolean;
}

const ReloadButton: React.FC<iProps> = ({
  onClick,
  size = "xs",
  iconProps = { color: COLORS.secondary, size: 16 },
  on,
  disabled,
}) => {
  const reloadButton = useRef<any>(undefined);

  return (
    <IconButton
      ref={reloadButton}
      onClick={(e) => {
        onClick && onClick(e);
        if (reloadButton.current) {
          reloadButton.current.blur();
        }
      }}
      icon={<SimpleReloader {...{ color: COLORS.secondary, size: 16, on, ...iconProps }} />}
      size={size}
      circle
      appearance="subtle"
      disabled={disabled}
    />
  );
};

export default ReloadButton;
