import { _find } from "./lodash-utils";

// prettier-ignore
export type tCountryCode = 'AC' | 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AQ' | 'AO' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PN' | 'PM' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TA' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XK' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW';

export type tCountryInfo = {
  name: string;
  region: "Africa" | "Americas" | "Asia" | "Europe" | "Oceania" | "";
  timezones: Record<string, string>;
  iso: { alpha2: tCountryCode; alpha3: string; numeric: string };
  phone: string[];
  emoji: string;
  image: string;
};

export const COUNTRIES: tCountryInfo[] = [
  {
    name: "Afghanistan",
    region: "Asia",
    timezones: { "Asia/Kabul": "+04:30" },
    iso: { alpha2: "AF", alpha3: "AFG", numeric: "004" },
    phone: ["+93"],
    emoji: "🇦🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
  },
  {
    name: "Albania",
    region: "Europe",
    timezones: { "Europe/Tirane": "+02:00" },
    iso: { alpha2: "AL", alpha3: "ALB", numeric: "008" },
    phone: ["+355"],
    emoji: "🇦🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
  },
  {
    name: "Algeria",
    region: "Africa",
    timezones: { "Africa/Algiers": "+01:00" },
    iso: { alpha2: "DZ", alpha3: "DZA", numeric: "012" },
    phone: ["+213"],
    emoji: "🇩🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
  },
  {
    name: "American Samoa",
    region: "Oceania",
    timezones: { "Pacific/Pago_Pago": "-11:00" },
    iso: { alpha2: "AS", alpha3: "ASM", numeric: "016" },
    phone: ["+1-684"],
    emoji: "🇦🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
  },
  {
    name: "Andorra",
    region: "Europe",
    timezones: { "Europe/Andorra": "+02:00" },
    iso: { alpha2: "AD", alpha3: "AND", numeric: "020" },
    phone: ["+376"],
    emoji: "🇦🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
  },
  {
    name: "Angola",
    region: "Africa",
    timezones: { "Africa/Luanda": "+01:00" },
    iso: { alpha2: "AO", alpha3: "AGO", numeric: "024" },
    phone: ["+244"],
    emoji: "🇦🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
  },
  {
    name: "Anguilla",
    region: "Americas",
    timezones: { "America/Anguilla": "-04:00" },
    iso: { alpha2: "AI", alpha3: "AIA", numeric: "660" },
    phone: ["+1-264"],
    emoji: "🇦🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
  },
  {
    name: "Antarctica",
    region: "",
    timezones: {
      "Antarctica/Casey": "+11:00",
      "Antarctica/Davis": "+07:00",
      "Antarctica/DumontDUrville": "+10:00",
      "Antarctica/Mawson": "+05:00",
      "Antarctica/McMurdo": "+12:00",
      "Antarctica/Palmer": "-03:00",
      "Antarctica/Rothera": "-03:00",
      "Antarctica/Syowa": "+03:00",
      "Antarctica/Troll": "+02:00",
      "Antarctica/Vostok": "+06:00",
    },
    iso: { alpha2: "AQ", alpha3: "ATA", numeric: "010" },
    phone: ["+"],
    emoji: "🇦🇶",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
  },
  {
    name: "Antigua and Barbuda",
    region: "Americas",
    timezones: { "America/Antigua": "-04:00" },
    iso: { alpha2: "AG", alpha3: "ATG", numeric: "028" },
    phone: ["+1-268"],
    emoji: "🇦🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
  },
  {
    name: "Argentina",
    region: "Americas",
    timezones: {
      "America/Argentina/Buenos_Aires": "-03:00",
      "America/Argentina/Catamarca": "-03:00",
      "America/Argentina/Cordoba": "-03:00",
      "America/Argentina/Jujuy": "-03:00",
      "America/Argentina/La_Rioja": "-03:00",
      "America/Argentina/Mendoza": "-03:00",
      "America/Argentina/Rio_Gallegos": "-03:00",
      "America/Argentina/Salta": "-03:00",
      "America/Argentina/San_Juan": "-03:00",
      "America/Argentina/San_Luis": "-03:00",
      "America/Argentina/Tucuman": "-03:00",
      "America/Argentina/Ushuaia": "-03:00",
    },
    iso: { alpha2: "AR", alpha3: "ARG", numeric: "032" },
    phone: ["+54"],
    emoji: "🇦🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
  },
  {
    name: "Armenia",
    region: "Asia",
    timezones: { "Asia/Yerevan": "+04:00" },
    iso: { alpha2: "AM", alpha3: "ARM", numeric: "051" },
    phone: ["+374"],
    emoji: "🇦🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
  },
  {
    name: "Aruba",
    region: "Americas",
    timezones: { "America/Aruba": "-04:00" },
    iso: { alpha2: "AW", alpha3: "ABW", numeric: "533" },
    phone: ["+297"],
    emoji: "🇦🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
  },
  {
    name: "Australia",
    region: "Oceania",
    timezones: {
      "Antarctica/Macquarie": "+10:00",
      "Australia/Adelaide": "+09:30",
      "Australia/Brisbane": "+10:00",
      "Australia/Broken_Hill": "+09:30",
      "Australia/Darwin": "+09:30",
      "Australia/Eucla": "+08:45",
      "Australia/Hobart": "+10:00",
      "Australia/Lindeman": "+10:00",
      "Australia/Lord_Howe": "+10:30",
      "Australia/Melbourne": "+10:00",
      "Australia/Perth": "+08:00",
      "Australia/Sydney": "+10:00",
    },
    iso: { alpha2: "AU", alpha3: "AUS", numeric: "036" },
    phone: ["+61"],
    emoji: "🇦🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
  },
  {
    name: "Austria",
    region: "Europe",
    timezones: { "Europe/Vienna": "+02:00" },
    iso: { alpha2: "AT", alpha3: "AUT", numeric: "040" },
    phone: ["+43"],
    emoji: "🇦🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
  },
  {
    name: "Azerbaijan",
    region: "Asia",
    timezones: { "Asia/Baku": "+04:00" },
    iso: { alpha2: "AZ", alpha3: "AZE", numeric: "031" },
    phone: ["+994"],
    emoji: "🇦🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
  },
  {
    name: "Bahamas",
    region: "Americas",
    timezones: { "America/Nassau": "-04:00" },
    iso: { alpha2: "BS", alpha3: "BHS", numeric: "044" },
    phone: ["+1-242"],
    emoji: "🇧🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
  },
  {
    name: "Bahrain",
    region: "Asia",
    timezones: { "Asia/Bahrain": "+03:00" },
    iso: { alpha2: "BH", alpha3: "BHR", numeric: "048" },
    phone: ["+973"],
    emoji: "🇧🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
  },
  {
    name: "Bangladesh",
    region: "Asia",
    timezones: { "Asia/Dhaka": "+06:00" },
    iso: { alpha2: "BD", alpha3: "BGD", numeric: "050" },
    phone: ["+880"],
    emoji: "🇧🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
  },
  {
    name: "Barbados",
    region: "Americas",
    timezones: { "America/Barbados": "-04:00" },
    iso: { alpha2: "BB", alpha3: "BRB", numeric: "052" },
    phone: ["+1-246"],
    emoji: "🇧🇧",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
  },
  {
    name: "Belarus",
    region: "Europe",
    timezones: { "Europe/Minsk": "+03:00" },
    iso: { alpha2: "BY", alpha3: "BLR", numeric: "112" },
    phone: ["+375"],
    emoji: "🇧🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
  },
  {
    name: "Belgium",
    region: "Europe",
    timezones: { "Europe/Brussels": "+02:00" },
    iso: { alpha2: "BE", alpha3: "BEL", numeric: "056" },
    phone: ["+32"],
    emoji: "🇧🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
  },
  {
    name: "Belize",
    region: "Americas",
    timezones: { "America/Belize": "-06:00" },
    iso: { alpha2: "BZ", alpha3: "BLZ", numeric: "084" },
    phone: ["+501"],
    emoji: "🇧🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
  },
  {
    name: "Benin",
    region: "Africa",
    timezones: { "Africa/Porto-Novo": "+01:00" },
    iso: { alpha2: "BJ", alpha3: "BEN", numeric: "204" },
    phone: ["+229"],
    emoji: "🇧🇯",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
  },
  {
    name: "Bermuda",
    region: "Americas",
    timezones: { "Atlantic/Bermuda": "-03:00" },
    iso: { alpha2: "BM", alpha3: "BMU", numeric: "060" },
    phone: ["+1-441"],
    emoji: "🇧🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
  },
  {
    name: "Bhutan",
    region: "Asia",
    timezones: { "Asia/Thimphu": "+06:00" },
    iso: { alpha2: "BT", alpha3: "BTN", numeric: "064" },
    phone: ["+975"],
    emoji: "🇧🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
  },
  {
    name: "Bolivia, Plurinational State of",
    region: "Americas",
    timezones: { "America/La_Paz": "-04:00" },
    iso: { alpha2: "BO", alpha3: "BOL", numeric: "068" },
    phone: ["+591"],
    emoji: "🇧🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    region: "Americas",
    timezones: { "America/Kralendijk": "-04:00" },
    iso: { alpha2: "BQ", alpha3: "BES", numeric: "535" },
    phone: ["+599"],
    emoji: "🇧🇶",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    region: "Europe",
    timezones: { "Europe/Sarajevo": "+02:00" },
    iso: { alpha2: "BA", alpha3: "BIH", numeric: "070" },
    phone: ["+387"],
    emoji: "🇧🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
  },
  {
    name: "Botswana",
    region: "Africa",
    timezones: { "Africa/Gaborone": "+02:00" },
    iso: { alpha2: "BW", alpha3: "BWA", numeric: "072" },
    phone: ["+267"],
    emoji: "🇧🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
  },
  {
    name: "Brazil",
    region: "Americas",
    timezones: {
      "America/Araguaina": "-03:00",
      "America/Bahia": "-03:00",
      "America/Belem": "-03:00",
      "America/Boa_Vista": "-04:00",
      "America/Campo_Grande": "-04:00",
      "America/Cuiaba": "-04:00",
      "America/Eirunepe": "-05:00",
      "America/Fortaleza": "-03:00",
      "America/Maceio": "-03:00",
      "America/Manaus": "-04:00",
      "America/Noronha": "-02:00",
      "America/Porto_Velho": "-04:00",
      "America/Recife": "-03:00",
      "America/Rio_Branco": "-05:00",
      "America/Santarem": "-03:00",
      "America/Sao_Paulo": "-03:00",
    },
    iso: { alpha2: "BR", alpha3: "BRA", numeric: "076" },
    phone: ["+55"],
    emoji: "🇧🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
  },
  {
    name: "British Indian Ocean Territory",
    region: "Africa",
    timezones: { "Indian/Chagos": "+06:00" },
    iso: { alpha2: "IO", alpha3: "IOT", numeric: "086" },
    phone: ["+246"],
    emoji: "🇮🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
  },
  {
    name: "Brunei Darussalam",
    region: "Asia",
    timezones: { "Asia/Brunei": "+08:00" },
    iso: { alpha2: "BN", alpha3: "BRN", numeric: "096" },
    phone: ["+673"],
    emoji: "🇧🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
  },
  {
    name: "Bulgaria",
    region: "Europe",
    timezones: { "Europe/Sofia": "+03:00" },
    iso: { alpha2: "BG", alpha3: "BGR", numeric: "100" },
    phone: ["+359"],
    emoji: "🇧🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
  },
  {
    name: "Burkina Faso",
    region: "Africa",
    timezones: { "Africa/Ouagadougou": "+00:00" },
    iso: { alpha2: "BF", alpha3: "BFA", numeric: "854" },
    phone: ["+226"],
    emoji: "🇧🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
  },
  {
    name: "Burundi",
    region: "Africa",
    timezones: { "Africa/Bujumbura": "+02:00" },
    iso: { alpha2: "BI", alpha3: "BDI", numeric: "108" },
    phone: ["+257"],
    emoji: "🇧🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
  },
  {
    name: "Cambodia",
    region: "Asia",
    timezones: { "Asia/Phnom_Penh": "+07:00" },
    iso: { alpha2: "KH", alpha3: "KHM", numeric: "116" },
    phone: ["+855"],
    emoji: "🇰🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
  },
  {
    name: "Cameroon",
    region: "Africa",
    timezones: { "Africa/Douala": "+01:00" },
    iso: { alpha2: "CM", alpha3: "CMR", numeric: "120" },
    phone: ["+237"],
    emoji: "🇨🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
  },
  {
    name: "Canada",
    region: "Americas",
    timezones: {
      "America/Atikokan": "-05:00",
      "America/Blanc-Sablon": "-04:00",
      "America/Cambridge_Bay": "-06:00",
      "America/Creston": "-07:00",
      "America/Dawson": "-07:00",
      "America/Dawson_Creek": "-07:00",
      "America/Edmonton": "-06:00",
      "America/Fort_Nelson": "-07:00",
      "America/Glace_Bay": "-03:00",
      "America/Goose_Bay": "-03:00",
      "America/Halifax": "-03:00",
      "America/Inuvik": "-06:00",
      "America/Iqaluit": "-04:00",
      "America/Moncton": "-03:00",
      "America/Rankin_Inlet": "-05:00",
      "America/Regina": "-06:00",
      "America/Resolute": "-05:00",
      "America/St_Johns": "-02:30",
      "America/Swift_Current": "-06:00",
      "America/Toronto": "-04:00",
      "America/Vancouver": "-07:00",
      "America/Whitehorse": "-07:00",
      "America/Winnipeg": "-05:00",
    },
    iso: { alpha2: "CA", alpha3: "CAN", numeric: "124" },
    phone: ["+1"],
    emoji: "🇨🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
  },
  {
    name: "Cape Verde",
    region: "Africa",
    timezones: { "Atlantic/Cape_Verde": "-01:00" },
    iso: { alpha2: "CV", alpha3: "CPV", numeric: "132" },
    phone: ["+238"],
    emoji: "🇨🇻",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
  },
  {
    name: "Cayman Islands",
    region: "Americas",
    timezones: { "America/Cayman": "-05:00" },
    iso: { alpha2: "KY", alpha3: "CYM", numeric: "136" },
    phone: ["+1-345"],
    emoji: "🇰🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
  },
  {
    name: "Central African Republic",
    region: "Africa",
    timezones: { "Africa/Bangui": "+01:00" },
    iso: { alpha2: "CF", alpha3: "CAF", numeric: "140" },
    phone: ["+236"],
    emoji: "🇨🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
  },
  {
    name: "Chad",
    region: "Africa",
    timezones: { "Africa/Ndjamena": "+01:00" },
    iso: { alpha2: "TD", alpha3: "TCD", numeric: "148" },
    phone: ["+235"],
    emoji: "🇹🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
  },
  {
    name: "Chile",
    region: "Americas",
    timezones: {
      "America/Punta_Arenas": "-03:00",
      "America/Santiago": "-04:00",
      "Pacific/Easter": "-06:00",
    },
    iso: { alpha2: "CL", alpha3: "CHL", numeric: "152" },
    phone: ["+56"],
    emoji: "🇨🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
  },
  {
    name: "China",
    region: "Asia",
    timezones: { "Asia/Shanghai": "+08:00", "Asia/Urumqi": "+06:00" },
    iso: { alpha2: "CN", alpha3: "CHN", numeric: "156" },
    phone: ["+86"],
    emoji: "🇨🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
  },
  {
    name: "Christmas Island",
    region: "Oceania",
    timezones: { "Indian/Christmas": "+07:00" },
    iso: { alpha2: "CX", alpha3: "CXR", numeric: "162" },
    phone: ["+61"],
    emoji: "🇨🇽",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    region: "Oceania",
    timezones: { "Indian/Cocos": "+06:30" },
    iso: { alpha2: "CC", alpha3: "CCK", numeric: "166" },
    phone: ["+61"],
    emoji: "🇨🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
  },
  {
    name: "Colombia",
    region: "Americas",
    timezones: { "America/Bogota": "-05:00" },
    iso: { alpha2: "CO", alpha3: "COL", numeric: "170" },
    phone: ["+57"],
    emoji: "🇨🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
  },
  {
    name: "Comoros",
    region: "Africa",
    timezones: { "Indian/Comoro": "+03:00" },
    iso: { alpha2: "KM", alpha3: "COM", numeric: "174" },
    phone: ["+269"],
    emoji: "🇰🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
  },
  {
    name: "Congo",
    region: "Africa",
    timezones: { "Africa/Brazzaville": "+01:00" },
    iso: { alpha2: "CG", alpha3: "COG", numeric: "178" },
    phone: ["+242"],
    emoji: "🇨🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
  },
  {
    name: "Congo, the Democratic Republic of the",
    region: "Africa",
    timezones: { "Africa/Kinshasa": "+01:00", "Africa/Lubumbashi": "+02:00" },
    iso: { alpha2: "CD", alpha3: "COD", numeric: "180" },
    phone: ["+243"],
    emoji: "🇨🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
  },
  {
    name: "Cook Islands",
    region: "Oceania",
    timezones: { "Pacific/Rarotonga": "-10:00" },
    iso: { alpha2: "CK", alpha3: "COK", numeric: "184" },
    phone: ["+682"],
    emoji: "🇨🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
  },
  {
    name: "Costa Rica",
    region: "Americas",
    timezones: { "America/Costa_Rica": "-06:00" },
    iso: { alpha2: "CR", alpha3: "CRI", numeric: "188" },
    phone: ["+506"],
    emoji: "🇨🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
  },
  {
    name: "Croatia",
    region: "Europe",
    timezones: { "Europe/Zagreb": "+02:00" },
    iso: { alpha2: "HR", alpha3: "HRV", numeric: "191" },
    phone: ["+385"],
    emoji: "🇭🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
  },
  {
    name: "Cuba",
    region: "Americas",
    timezones: { "America/Havana": "-04:00" },
    iso: { alpha2: "CU", alpha3: "CUB", numeric: "192" },
    phone: ["+53"],
    emoji: "🇨🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
  },
  {
    name: "Curaçao",
    region: "Americas",
    timezones: { "America/Curacao": "-04:00" },
    iso: { alpha2: "CW", alpha3: "CUW", numeric: "531" },
    phone: ["+599"],
    emoji: "🇨🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
  },
  {
    name: "Cyprus",
    region: "Asia",
    timezones: { "Asia/Famagusta": "+03:00", "Asia/Nicosia": "+03:00" },
    iso: { alpha2: "CY", alpha3: "CYP", numeric: "196" },
    phone: ["+357"],
    emoji: "🇨🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
  },
  {
    name: "Czech Republic",
    region: "Europe",
    timezones: { "Europe/Prague": "+02:00" },
    iso: { alpha2: "CZ", alpha3: "CZE", numeric: "203" },
    phone: ["+420"],
    emoji: "🇨🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
  },
  {
    name: "Côte d'Ivoire",
    region: "Africa",
    timezones: { "Africa/Abidjan": "+00:00" },
    iso: { alpha2: "CI", alpha3: "CIV", numeric: "384" },
    phone: ["+225"],
    emoji: "🇨🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
  },
  {
    name: "Denmark",
    region: "Europe",
    timezones: { "Europe/Copenhagen": "+02:00" },
    iso: { alpha2: "DK", alpha3: "DNK", numeric: "208" },
    phone: ["+45"],
    emoji: "🇩🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
  },
  {
    name: "Djibouti",
    region: "Africa",
    timezones: { "Africa/Djibouti": "+03:00" },
    iso: { alpha2: "DJ", alpha3: "DJI", numeric: "262" },
    phone: ["+253"],
    emoji: "🇩🇯",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
  },
  {
    name: "Dominica",
    region: "Americas",
    timezones: { "America/Dominica": "-04:00" },
    iso: { alpha2: "DM", alpha3: "DMA", numeric: "212" },
    phone: ["+1-767"],
    emoji: "🇩🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
  },
  {
    name: "Dominican Republic",
    region: "Americas",
    timezones: { "America/Santo_Domingo": "-04:00" },
    iso: { alpha2: "DO", alpha3: "DOM", numeric: "214" },
    phone: ["+1-809", "+1-829"],
    emoji: "🇩🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
  },
  {
    name: "Ecuador",
    region: "Americas",
    timezones: {
      "America/Guayaquil": "-05:00",
      "Pacific/Galapagos": "-06:00",
    },
    iso: { alpha2: "EC", alpha3: "ECU", numeric: "218" },
    phone: ["+593"],
    emoji: "🇪🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
  },
  {
    name: "Egypt",
    region: "Africa",
    timezones: { "Africa/Cairo": "+02:00" },
    iso: { alpha2: "EG", alpha3: "EGY", numeric: "818" },
    phone: ["+20"],
    emoji: "🇪🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
  },
  {
    name: "El Salvador",
    region: "Americas",
    timezones: { "America/El_Salvador": "-06:00" },
    iso: { alpha2: "SV", alpha3: "SLV", numeric: "222" },
    phone: ["+503"],
    emoji: "🇸🇻",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
  },
  {
    name: "Equatorial Guinea",
    region: "Africa",
    timezones: { "Africa/Malabo": "+01:00" },
    iso: { alpha2: "GQ", alpha3: "GNQ", numeric: "226" },
    phone: ["+240"],
    emoji: "🇬🇶",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
  },
  {
    name: "Eritrea",
    region: "Africa",
    timezones: { "Africa/Asmara": "+03:00" },
    iso: { alpha2: "ER", alpha3: "ERI", numeric: "232" },
    phone: ["+291"],
    emoji: "🇪🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
  },
  {
    name: "Estonia",
    region: "Europe",
    timezones: { "Europe/Tallinn": "+03:00" },
    iso: { alpha2: "EE", alpha3: "EST", numeric: "233" },
    phone: ["+372"],
    emoji: "🇪🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
  },
  {
    name: "Ethiopia",
    region: "Africa",
    timezones: { "Africa/Addis_Ababa": "+03:00" },
    iso: { alpha2: "ET", alpha3: "ETH", numeric: "231" },
    phone: ["+251"],
    emoji: "🇪🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    region: "Americas",
    timezones: { "Atlantic/Stanley": "-03:00" },
    iso: { alpha2: "FK", alpha3: "FLK", numeric: "238" },
    phone: ["+500"],
    emoji: "🇫🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
  },
  {
    name: "Faroe Islands",
    region: "Europe",
    timezones: { "Atlantic/Faroe": "+01:00" },
    iso: { alpha2: "FO", alpha3: "FRO", numeric: "234" },
    phone: ["+298"],
    emoji: "🇫🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
  },
  {
    name: "Fiji",
    region: "Oceania",
    timezones: { "Pacific/Fiji": "+12:00" },
    iso: { alpha2: "FJ", alpha3: "FJI", numeric: "242" },
    phone: ["+679"],
    emoji: "🇫🇯",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
  },
  {
    name: "Finland",
    region: "Europe",
    timezones: { "Europe/Helsinki": "+03:00" },
    iso: { alpha2: "FI", alpha3: "FIN", numeric: "246" },
    phone: ["+358"],
    emoji: "🇫🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
  },
  {
    name: "France",
    region: "Europe",
    timezones: { "Europe/Paris": "+02:00" },
    iso: { alpha2: "FR", alpha3: "FRA", numeric: "250" },
    phone: ["+33"],
    emoji: "🇫🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
  },
  {
    name: "French Guiana",
    region: "Americas",
    timezones: { "America/Cayenne": "-03:00" },
    iso: { alpha2: "GF", alpha3: "GUF", numeric: "254" },
    phone: ["+594"],
    emoji: "🇬🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
  },
  {
    name: "French Polynesia",
    region: "Oceania",
    timezones: {
      "Pacific/Gambier": "-09:00",
      "Pacific/Marquesas": "-09:30",
      "Pacific/Tahiti": "-10:00",
    },
    iso: { alpha2: "PF", alpha3: "PYF", numeric: "258" },
    phone: ["+689"],
    emoji: "🇵🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
  },
  {
    name: "French Southern Territories",
    region: "Africa",
    timezones: { "Indian/Kerguelen": "+05:00" },
    iso: { alpha2: "TF", alpha3: "ATF", numeric: "260" },
    phone: ["+"],
    emoji: "🇹🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg",
  },
  {
    name: "Gabon",
    region: "Africa",
    timezones: { "Africa/Libreville": "+01:00" },
    iso: { alpha2: "GA", alpha3: "GAB", numeric: "266" },
    phone: ["+241"],
    emoji: "🇬🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
  },
  {
    name: "Gambia",
    region: "Africa",
    timezones: { "Africa/Banjul": "+00:00" },
    iso: { alpha2: "GM", alpha3: "GMB", numeric: "270" },
    phone: ["+220"],
    emoji: "🇬🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
  },
  {
    name: "Georgia",
    region: "Asia",
    timezones: { "Asia/Tbilisi": "+04:00" },
    iso: { alpha2: "GE", alpha3: "GEO", numeric: "268" },
    phone: ["+995"],
    emoji: "🇬🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
  },
  {
    name: "Germany",
    region: "Europe",
    timezones: { "Europe/Berlin": "+02:00", "Europe/Busingen": "+02:00" },
    iso: { alpha2: "DE", alpha3: "DEU", numeric: "276" },
    phone: ["+49"],
    emoji: "🇩🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
  },
  {
    name: "Ghana",
    region: "Africa",
    timezones: { "Africa/Accra": "+00:00" },
    iso: { alpha2: "GH", alpha3: "GHA", numeric: "288" },
    phone: ["+233"],
    emoji: "🇬🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
  },
  {
    name: "Gibraltar",
    region: "Europe",
    timezones: { "Europe/Gibraltar": "+02:00" },
    iso: { alpha2: "GI", alpha3: "GIB", numeric: "292" },
    phone: ["+350"],
    emoji: "🇬🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
  },
  {
    name: "Greece",
    region: "Europe",
    timezones: { "Europe/Athens": "+03:00" },
    iso: { alpha2: "GR", alpha3: "GRC", numeric: "300" },
    phone: ["+30"],
    emoji: "🇬🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
  },
  {
    name: "Greenland",
    region: "Americas",
    timezones: {
      "America/Danmarkshavn": "+00:00",
      "America/Nuuk": "-02:00",
      "America/Scoresbysund": "+00:00",
      "America/Thule": "-03:00",
    },
    iso: { alpha2: "GL", alpha3: "GRL", numeric: "304" },
    phone: ["+299"],
    emoji: "🇬🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
  },
  {
    name: "Grenada",
    region: "Americas",
    timezones: { "America/Grenada": "-04:00" },
    iso: { alpha2: "GD", alpha3: "GRD", numeric: "308" },
    phone: ["+1-473"],
    emoji: "🇬🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
  },
  {
    name: "Guadeloupe",
    region: "Americas",
    timezones: { "America/Guadeloupe": "-04:00" },
    iso: { alpha2: "GP", alpha3: "GLP", numeric: "312" },
    phone: ["+590"],
    emoji: "🇬🇵",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
  },
  {
    name: "Guam",
    region: "Oceania",
    timezones: { "Pacific/Guam": "+10:00" },
    iso: { alpha2: "GU", alpha3: "GUM", numeric: "316" },
    phone: ["+1-671"],
    emoji: "🇬🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
  },
  {
    name: "Guatemala",
    region: "Americas",
    timezones: { "America/Guatemala": "-06:00" },
    iso: { alpha2: "GT", alpha3: "GTM", numeric: "320" },
    phone: ["+502"],
    emoji: "🇬🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
  },
  {
    name: "Guernsey",
    region: "Europe",
    timezones: { "Europe/Guernsey": "+01:00" },
    iso: { alpha2: "GG", alpha3: "GGY", numeric: "831" },
    phone: ["+44-1481"],
    emoji: "🇬🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
  },
  {
    name: "Guinea",
    region: "Africa",
    timezones: { "Africa/Conakry": "+00:00" },
    iso: { alpha2: "GN", alpha3: "GIN", numeric: "324" },
    phone: ["+224"],
    emoji: "🇬🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
  },
  {
    name: "Guinea-Bissau",
    region: "Africa",
    timezones: { "Africa/Bissau": "+00:00" },
    iso: { alpha2: "GW", alpha3: "GNB", numeric: "624" },
    phone: ["+245"],
    emoji: "🇬🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
  },
  {
    name: "Guyana",
    region: "Americas",
    timezones: { "America/Guyana": "-04:00" },
    iso: { alpha2: "GY", alpha3: "GUY", numeric: "328" },
    phone: ["+592"],
    emoji: "🇬🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
  },
  {
    name: "Haiti",
    region: "Americas",
    timezones: { "America/Port-au-Prince": "-04:00" },
    iso: { alpha2: "HT", alpha3: "HTI", numeric: "332" },
    phone: ["+509"],
    emoji: "🇭🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
  },
  {
    name: "Holy See (Vatican City State)",
    region: "Europe",
    timezones: { "Europe/Vatican": "+02:00" },
    iso: { alpha2: "VA", alpha3: "VAT", numeric: "336" },
    phone: ["+379"],
    emoji: "🇻🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
  },
  {
    name: "Honduras",
    region: "Americas",
    timezones: { "America/Tegucigalpa": "-06:00" },
    iso: { alpha2: "HN", alpha3: "HND", numeric: "340" },
    phone: ["+504"],
    emoji: "🇭🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
  },
  {
    name: "Hong Kong",
    region: "Asia",
    timezones: { "Asia/Hong_Kong": "+08:00" },
    iso: { alpha2: "HK", alpha3: "HKG", numeric: "344" },
    phone: ["+852"],
    emoji: "🇭🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
  },
  {
    name: "Hungary",
    region: "Europe",
    timezones: { "Europe/Budapest": "+02:00" },
    iso: { alpha2: "HU", alpha3: "HUN", numeric: "348" },
    phone: ["+36"],
    emoji: "🇭🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
  },
  {
    name: "Iceland",
    region: "Europe",
    timezones: { "Atlantic/Reykjavik": "+00:00" },
    iso: { alpha2: "IS", alpha3: "ISL", numeric: "352" },
    phone: ["+354"],
    emoji: "🇮🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
  },
  {
    name: "India",
    region: "Asia",
    timezones: { "Asia/Kolkata": "+05:30" },
    iso: { alpha2: "IN", alpha3: "IND", numeric: "356" },
    phone: ["+91"],
    emoji: "🇮🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
  },
  {
    name: "Indonesia",
    region: "Asia",
    timezones: {
      "Asia/Jakarta": "+07:00",
      "Asia/Jayapura": "+09:00",
      "Asia/Makassar": "+08:00",
      "Asia/Pontianak": "+07:00",
    },
    iso: { alpha2: "ID", alpha3: "IDN", numeric: "360" },
    phone: ["+62"],
    emoji: "🇮🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
  },
  {
    name: "Iran, Islamic Republic of",
    region: "Asia",
    timezones: { "Asia/Tehran": "+03:30" },
    iso: { alpha2: "IR", alpha3: "IRN", numeric: "364" },
    phone: ["+98"],
    emoji: "🇮🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
  },
  {
    name: "Iraq",
    region: "Asia",
    timezones: { "Asia/Baghdad": "+03:00" },
    iso: { alpha2: "IQ", alpha3: "IRQ", numeric: "368" },
    phone: ["+964"],
    emoji: "🇮🇶",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
  },
  {
    name: "Ireland",
    region: "Europe",
    timezones: { "Europe/Dublin": "+01:00" },
    iso: { alpha2: "IE", alpha3: "IRL", numeric: "372" },
    phone: ["+353"],
    emoji: "🇮🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
  },
  {
    name: "Isle of Man",
    region: "Europe",
    timezones: { "Europe/Isle_of_Man": "+01:00" },
    iso: { alpha2: "IM", alpha3: "IMN", numeric: "833" },
    phone: ["+44-1624"],
    emoji: "🇮🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
  },
  {
    name: "Israel",
    region: "Asia",
    timezones: { "Asia/Jerusalem": "+03:00" },
    iso: { alpha2: "IL", alpha3: "ISR", numeric: "376" },
    phone: ["+972"],
    emoji: "🇮🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
  },
  {
    name: "Italy",
    region: "Europe",
    timezones: { "Europe/Rome": "+02:00" },
    iso: { alpha2: "IT", alpha3: "ITA", numeric: "380" },
    phone: ["+39"],
    emoji: "🇮🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
  },
  {
    name: "Jamaica",
    region: "Americas",
    timezones: { "America/Jamaica": "-05:00" },
    iso: { alpha2: "JM", alpha3: "JAM", numeric: "388" },
    phone: ["+1-876"],
    emoji: "🇯🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
  },
  {
    name: "Japan",
    region: "Asia",
    timezones: { "Asia/Tokyo": "+09:00" },
    iso: { alpha2: "JP", alpha3: "JPN", numeric: "392" },
    phone: ["+81"],
    emoji: "🇯🇵",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
  },
  {
    name: "Jersey",
    region: "Europe",
    timezones: { "Europe/Jersey": "+01:00" },
    iso: { alpha2: "JE", alpha3: "JEY", numeric: "832" },
    phone: ["+44-1534"],
    emoji: "🇯🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
  },
  {
    name: "Jordan",
    region: "Asia",
    timezones: { "Asia/Amman": "+03:00" },
    iso: { alpha2: "JO", alpha3: "JOR", numeric: "400" },
    phone: ["+962"],
    emoji: "🇯🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
  },
  {
    name: "Kazakhstan",
    region: "Asia",
    timezones: {
      "Asia/Almaty": "+06:00",
      "Asia/Aqtau": "+05:00",
      "Asia/Aqtobe": "+05:00",
      "Asia/Atyrau": "+05:00",
      "Asia/Oral": "+05:00",
      "Asia/Qostanay": "+06:00",
      "Asia/Qyzylorda": "+05:00",
    },
    iso: { alpha2: "KZ", alpha3: "KAZ", numeric: "398" },
    phone: ["+7"],
    emoji: "🇰🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
  },
  {
    name: "Kenya",
    region: "Africa",
    timezones: { "Africa/Nairobi": "+03:00" },
    iso: { alpha2: "KE", alpha3: "KEN", numeric: "404" },
    phone: ["+254"],
    emoji: "🇰🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
  },
  {
    name: "Kiribati",
    region: "Oceania",
    timezones: {
      "Pacific/Kanton": "+13:00",
      "Pacific/Kiritimati": "+14:00",
      "Pacific/Tarawa": "+12:00",
    },
    iso: { alpha2: "KI", alpha3: "KIR", numeric: "296" },
    phone: ["+686"],
    emoji: "🇰🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
  },
  {
    name: "Korea, Democratic People's Republic of",
    region: "Asia",
    timezones: { "Asia/Pyongyang": "+09:00" },
    iso: { alpha2: "KP", alpha3: "PRK", numeric: "408" },
    phone: ["+850"],
    emoji: "🇰🇵",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
  },
  {
    name: "Korea, Republic of",
    region: "Asia",
    timezones: { "Asia/Seoul": "+09:00" },
    iso: { alpha2: "KR", alpha3: "KOR", numeric: "410" },
    phone: ["+82"],
    emoji: "🇰🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
  },
  {
    name: "Kuwait",
    region: "Asia",
    timezones: { "Asia/Kuwait": "+03:00" },
    iso: { alpha2: "KW", alpha3: "KWT", numeric: "414" },
    phone: ["+965"],
    emoji: "🇰🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
  },
  {
    name: "Kyrgyzstan",
    region: "Asia",
    timezones: { "Asia/Bishkek": "+06:00" },
    iso: { alpha2: "KG", alpha3: "KGZ", numeric: "417" },
    phone: ["+996"],
    emoji: "🇰🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
  },
  {
    name: "Lao People's Democratic Republic",
    region: "Asia",
    timezones: { "Asia/Vientiane": "+07:00" },
    iso: { alpha2: "LA", alpha3: "LAO", numeric: "418" },
    phone: ["+856"],
    emoji: "🇱🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
  },
  {
    name: "Latvia",
    region: "Europe",
    timezones: { "Europe/Riga": "+03:00" },
    iso: { alpha2: "LV", alpha3: "LVA", numeric: "428" },
    phone: ["+371"],
    emoji: "🇱🇻",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
  },
  {
    name: "Lebanon",
    region: "Asia",
    timezones: { "Asia/Beirut": "+03:00" },
    iso: { alpha2: "LB", alpha3: "LBN", numeric: "422" },
    phone: ["+961"],
    emoji: "🇱🇧",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
  },
  {
    name: "Lesotho",
    region: "Africa",
    timezones: { "Africa/Maseru": "+02:00" },
    iso: { alpha2: "LS", alpha3: "LSO", numeric: "426" },
    phone: ["+266"],
    emoji: "🇱🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
  },
  {
    name: "Liberia",
    region: "Africa",
    timezones: { "Africa/Monrovia": "+00:00" },
    iso: { alpha2: "LR", alpha3: "LBR", numeric: "430" },
    phone: ["+231"],
    emoji: "🇱🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
  },
  {
    name: "Libya",
    region: "Africa",
    timezones: { "Africa/Tripoli": "+02:00" },
    iso: { alpha2: "LY", alpha3: "LBY", numeric: "434" },
    phone: ["+218"],
    emoji: "🇱🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
  },
  {
    name: "Liechtenstein",
    region: "Europe",
    timezones: { "Europe/Vaduz": "+02:00" },
    iso: { alpha2: "LI", alpha3: "LIE", numeric: "438" },
    phone: ["+423"],
    emoji: "🇱🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
  },
  {
    name: "Lithuania",
    region: "Europe",
    timezones: { "Europe/Vilnius": "+03:00" },
    iso: { alpha2: "LT", alpha3: "LTU", numeric: "440" },
    phone: ["+370"],
    emoji: "🇱🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
  },
  {
    name: "Luxembourg",
    region: "Europe",
    timezones: { "Europe/Luxembourg": "+02:00" },
    iso: { alpha2: "LU", alpha3: "LUX", numeric: "442" },
    phone: ["+352"],
    emoji: "🇱🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
  },
  {
    name: "Macao",
    region: "Asia",
    timezones: { "Asia/Macau": "+08:00" },
    iso: { alpha2: "MO", alpha3: "MAC", numeric: "446" },
    phone: ["+853"],
    emoji: "🇲🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
  },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    region: "Europe",
    timezones: { "Europe/Skopje": "+02:00" },
    iso: { alpha2: "MK", alpha3: "MKD", numeric: "807" },
    phone: ["+389"],
    emoji: "🇲🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
  },
  {
    name: "Madagascar",
    region: "Africa",
    timezones: { "Indian/Antananarivo": "+03:00" },
    iso: { alpha2: "MG", alpha3: "MDG", numeric: "450" },
    phone: ["+261"],
    emoji: "🇲🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
  },
  {
    name: "Malawi",
    region: "Africa",
    timezones: { "Africa/Blantyre": "+02:00" },
    iso: { alpha2: "MW", alpha3: "MWI", numeric: "454" },
    phone: ["+265"],
    emoji: "🇲🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
  },
  {
    name: "Malaysia",
    region: "Asia",
    timezones: { "Asia/Kuala_Lumpur": "+08:00", "Asia/Kuching": "+08:00" },
    iso: { alpha2: "MY", alpha3: "MYS", numeric: "458" },
    phone: ["+60"],
    emoji: "🇲🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
  },
  {
    name: "Maldives",
    region: "Asia",
    timezones: { "Indian/Maldives": "+05:00" },
    iso: { alpha2: "MV", alpha3: "MDV", numeric: "462" },
    phone: ["+960"],
    emoji: "🇲🇻",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
  },
  {
    name: "Mali",
    region: "Africa",
    timezones: { "Africa/Bamako": "+00:00" },
    iso: { alpha2: "ML", alpha3: "MLI", numeric: "466" },
    phone: ["+223"],
    emoji: "🇲🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
  },
  {
    name: "Malta",
    region: "Europe",
    timezones: { "Europe/Malta": "+02:00" },
    iso: { alpha2: "MT", alpha3: "MLT", numeric: "470" },
    phone: ["+356"],
    emoji: "🇲🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
  },
  {
    name: "Marshall Islands",
    region: "Oceania",
    timezones: { "Pacific/Kwajalein": "+12:00", "Pacific/Majuro": "+12:00" },
    iso: { alpha2: "MH", alpha3: "MHL", numeric: "584" },
    phone: ["+692"],
    emoji: "🇲🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
  },
  {
    name: "Martinique",
    region: "Americas",
    timezones: { "America/Martinique": "-04:00" },
    iso: { alpha2: "MQ", alpha3: "MTQ", numeric: "474" },
    phone: ["+596"],
    emoji: "🇲🇶",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
  },
  {
    name: "Mauritania",
    region: "Africa",
    timezones: { "Africa/Nouakchott": "+00:00" },
    iso: { alpha2: "MR", alpha3: "MRT", numeric: "478" },
    phone: ["+222"],
    emoji: "🇲🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
  },
  {
    name: "Mauritius",
    region: "Africa",
    timezones: { "Indian/Mauritius": "+04:00" },
    iso: { alpha2: "MU", alpha3: "MUS", numeric: "480" },
    phone: ["+230"],
    emoji: "🇲🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
  },
  {
    name: "Mayotte",
    region: "Africa",
    timezones: { "Indian/Mayotte": "+03:00" },
    iso: { alpha2: "YT", alpha3: "MYT", numeric: "175" },
    phone: ["+262"],
    emoji: "🇾🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
  },
  {
    name: "Mexico",
    region: "Americas",
    timezones: {
      "America/Bahia_Banderas": "-06:00",
      "America/Cancun": "-05:00",
      "America/Chihuahua": "-06:00",
      "America/Ciudad_Juarez": "-06:00",
      "America/Hermosillo": "-07:00",
      "America/Matamoros": "-05:00",
      "America/Mazatlan": "-07:00",
      "America/Merida": "-06:00",
      "America/Mexico_City": "-06:00",
      "America/Monterrey": "-06:00",
      "America/Ojinaga": "-05:00",
      "America/Tijuana": "-07:00",
    },
    iso: { alpha2: "MX", alpha3: "MEX", numeric: "484" },
    phone: ["+52"],
    emoji: "🇲🇽",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
  },
  {
    name: "Micronesia, Federated States of",
    region: "Oceania",
    timezones: {
      "Pacific/Chuuk": "+10:00",
      "Pacific/Kosrae": "+11:00",
      "Pacific/Pohnpei": "+11:00",
    },
    iso: { alpha2: "FM", alpha3: "FSM", numeric: "583" },
    phone: ["+691"],
    emoji: "🇫🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
  },
  {
    name: "Moldova, Republic of",
    region: "Europe",
    timezones: { "Europe/Chisinau": "+03:00" },
    iso: { alpha2: "MD", alpha3: "MDA", numeric: "498" },
    phone: ["+373"],
    emoji: "🇲🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
  },
  {
    name: "Monaco",
    region: "Europe",
    timezones: { "Europe/Monaco": "+02:00" },
    iso: { alpha2: "MC", alpha3: "MCO", numeric: "492" },
    phone: ["+377"],
    emoji: "🇲🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
  },
  {
    name: "Mongolia",
    region: "Asia",
    timezones: {
      "Asia/Choibalsan": "+08:00",
      "Asia/Hovd": "+07:00",
      "Asia/Ulaanbaatar": "+08:00",
    },
    iso: { alpha2: "MN", alpha3: "MNG", numeric: "496" },
    phone: ["+976"],
    emoji: "🇲🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
  },
  {
    name: "Montenegro",
    region: "Europe",
    timezones: { "Europe/Podgorica": "+02:00" },
    iso: { alpha2: "ME", alpha3: "MNE", numeric: "499" },
    phone: ["+382"],
    emoji: "🇲🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
  },
  {
    name: "Montserrat",
    region: "Americas",
    timezones: { "America/Montserrat": "-04:00" },
    iso: { alpha2: "MS", alpha3: "MSR", numeric: "500" },
    phone: ["+1-664"],
    emoji: "🇲🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
  },
  {
    name: "Morocco",
    region: "Africa",
    timezones: { "Africa/Casablanca": "+00:00", "Africa/El_Aaiun": "+00:00" },
    iso: { alpha2: "MA", alpha3: "MAR", numeric: "504" },
    phone: ["+212"],
    emoji: "🇲🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
  },
  {
    name: "Mozambique",
    region: "Africa",
    timezones: { "Africa/Maputo": "+02:00" },
    iso: { alpha2: "MZ", alpha3: "MOZ", numeric: "508" },
    phone: ["+258"],
    emoji: "🇲🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
  },
  {
    name: "Myanmar",
    region: "Asia",
    timezones: { "Asia/Yangon": "+06:30" },
    iso: { alpha2: "MM", alpha3: "MMR", numeric: "104" },
    phone: ["+95"],
    emoji: "🇲🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
  },
  {
    name: "Namibia",
    region: "Africa",
    timezones: { "Africa/Windhoek": "+02:00" },
    iso: { alpha2: "NA", alpha3: "NAM", numeric: "516" },
    phone: ["+264"],
    emoji: "🇳🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
  },
  {
    name: "Nauru",
    region: "Oceania",
    timezones: { "Pacific/Nauru": "+12:00" },
    iso: { alpha2: "NR", alpha3: "NRU", numeric: "520" },
    phone: ["+674"],
    emoji: "🇳🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
  },
  {
    name: "Nepal",
    region: "Asia",
    timezones: { "Asia/Kathmandu": "+05:45" },
    iso: { alpha2: "NP", alpha3: "NPL", numeric: "524" },
    phone: ["+977"],
    emoji: "🇳🇵",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
  },
  {
    name: "Netherlands",
    region: "Europe",
    timezones: { "Europe/Amsterdam": "+02:00" },
    iso: { alpha2: "NL", alpha3: "NLD", numeric: "528" },
    phone: ["+31"],
    emoji: "🇳🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
  },
  {
    name: "New Caledonia",
    region: "Oceania",
    timezones: { "Pacific/Noumea": "+11:00" },
    iso: { alpha2: "NC", alpha3: "NCL", numeric: "540" },
    phone: ["+687"],
    emoji: "🇳🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
  },
  {
    name: "New Zealand",
    region: "Oceania",
    timezones: { "Pacific/Auckland": "+12:00", "Pacific/Chatham": "+12:45" },
    iso: { alpha2: "NZ", alpha3: "NZL", numeric: "554" },
    phone: ["+64"],
    emoji: "🇳🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
  },
  {
    name: "Nicaragua",
    region: "Americas",
    timezones: { "America/Managua": "-06:00" },
    iso: { alpha2: "NI", alpha3: "NIC", numeric: "558" },
    phone: ["+505"],
    emoji: "🇳🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
  },
  {
    name: "Niger",
    region: "Africa",
    timezones: { "Africa/Niamey": "+01:00" },
    iso: { alpha2: "NE", alpha3: "NER", numeric: "562" },
    phone: ["+227"],
    emoji: "🇳🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
  },
  {
    name: "Nigeria",
    region: "Africa",
    timezones: { "Africa/Lagos": "+01:00" },
    iso: { alpha2: "NG", alpha3: "NGA", numeric: "566" },
    phone: ["+234"],
    emoji: "🇳🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
  },
  {
    name: "Niue",
    region: "Oceania",
    timezones: { "Pacific/Niue": "-11:00" },
    iso: { alpha2: "NU", alpha3: "NIU", numeric: "570" },
    phone: ["+683"],
    emoji: "🇳🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
  },
  {
    name: "Norfolk Island",
    region: "Oceania",
    timezones: { "Pacific/Norfolk": "+11:00" },
    iso: { alpha2: "NF", alpha3: "NFK", numeric: "574" },
    phone: ["+672"],
    emoji: "🇳🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
  },
  {
    name: "Northern Mariana Islands",
    region: "Oceania",
    timezones: { "Pacific/Saipan": "+10:00" },
    iso: { alpha2: "MP", alpha3: "MNP", numeric: "580" },
    phone: ["+1-670"],
    emoji: "🇲🇵",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
  },
  {
    name: "Norway",
    region: "Europe",
    timezones: { "Europe/Oslo": "+02:00" },
    iso: { alpha2: "NO", alpha3: "NOR", numeric: "578" },
    phone: ["+47"],
    emoji: "🇳🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
  },
  {
    name: "Oman",
    region: "Asia",
    timezones: { "Asia/Muscat": "+04:00" },
    iso: { alpha2: "OM", alpha3: "OMN", numeric: "512" },
    phone: ["+968"],
    emoji: "🇴🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
  },
  {
    name: "Pakistan",
    region: "Asia",
    timezones: { "Asia/Karachi": "+05:00" },
    iso: { alpha2: "PK", alpha3: "PAK", numeric: "586" },
    phone: ["+92"],
    emoji: "🇵🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
  },
  {
    name: "Palau",
    region: "Oceania",
    timezones: { "Pacific/Palau": "+09:00" },
    iso: { alpha2: "PW", alpha3: "PLW", numeric: "585" },
    phone: ["+680"],
    emoji: "🇵🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
  },
  {
    name: "Palestine, State of",
    region: "Asia",
    timezones: { "Asia/Gaza": "+02:00", "Asia/Hebron": "+02:00" },
    iso: { alpha2: "PS", alpha3: "PSE", numeric: "275" },
    phone: ["+970"],
    emoji: "🇵🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
  },
  {
    name: "Panama",
    region: "Americas",
    timezones: { "America/Panama": "-05:00" },
    iso: { alpha2: "PA", alpha3: "PAN", numeric: "591" },
    phone: ["+507"],
    emoji: "🇵🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
  },
  {
    name: "Papua New Guinea",
    region: "Oceania",
    timezones: {
      "Pacific/Bougainville": "+11:00",
      "Pacific/Port_Moresby": "+10:00",
    },
    iso: { alpha2: "PG", alpha3: "PNG", numeric: "598" },
    phone: ["+675"],
    emoji: "🇵🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
  },
  {
    name: "Paraguay",
    region: "Americas",
    timezones: { "America/Asuncion": "-04:00" },
    iso: { alpha2: "PY", alpha3: "PRY", numeric: "600" },
    phone: ["+595"],
    emoji: "🇵🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
  },
  {
    name: "Peru",
    region: "Americas",
    timezones: { "America/Lima": "-05:00" },
    iso: { alpha2: "PE", alpha3: "PER", numeric: "604" },
    phone: ["+51"],
    emoji: "🇵🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
  },
  {
    name: "Philippines",
    region: "Asia",
    timezones: { "Asia/Manila": "+08:00" },
    iso: { alpha2: "PH", alpha3: "PHL", numeric: "608" },
    phone: ["+63"],
    emoji: "🇵🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
  },
  {
    name: "Pitcairn",
    region: "Oceania",
    timezones: { "Pacific/Pitcairn": "-08:00" },
    iso: { alpha2: "PN", alpha3: "PCN", numeric: "612" },
    phone: ["+870"],
    emoji: "🇵🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
  },
  {
    name: "Poland",
    region: "Europe",
    timezones: { "Europe/Warsaw": "+02:00" },
    iso: { alpha2: "PL", alpha3: "POL", numeric: "616" },
    phone: ["+48"],
    emoji: "🇵🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
  },
  {
    name: "Portugal",
    region: "Europe",
    timezones: {
      "Atlantic/Azores": "+00:00",
      "Atlantic/Madeira": "+01:00",
      "Europe/Lisbon": "+01:00",
    },
    iso: { alpha2: "PT", alpha3: "PRT", numeric: "620" },
    phone: ["+351"],
    emoji: "🇵🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
  },
  {
    name: "Puerto Rico",
    region: "Americas",
    timezones: { "America/Puerto_Rico": "-04:00" },
    iso: { alpha2: "PR", alpha3: "PRI", numeric: "630" },
    phone: ["+1-787", "+1-939"],
    emoji: "🇵🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
  },
  {
    name: "Qatar",
    region: "Asia",
    timezones: { "Asia/Qatar": "+03:00" },
    iso: { alpha2: "QA", alpha3: "QAT", numeric: "634" },
    phone: ["+974"],
    emoji: "🇶🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
  },
  {
    name: "Romania",
    region: "Europe",
    timezones: { "Europe/Bucharest": "+03:00" },
    iso: { alpha2: "RO", alpha3: "ROU", numeric: "642" },
    phone: ["+40"],
    emoji: "🇷🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
  },
  {
    name: "Russian Federation",
    region: "Europe",
    timezones: {
      "Asia/Anadyr": "+12:00",
      "Asia/Barnaul": "+07:00",
      "Asia/Chita": "+09:00",
      "Asia/Irkutsk": "+08:00",
      "Asia/Kamchatka": "+12:00",
      "Asia/Khandyga": "+09:00",
      "Asia/Krasnoyarsk": "+07:00",
      "Asia/Magadan": "+11:00",
      "Asia/Novokuznetsk": "+07:00",
      "Asia/Novosibirsk": "+07:00",
      "Asia/Omsk": "+06:00",
      "Asia/Sakhalin": "+11:00",
      "Asia/Srednekolymsk": "+11:00",
      "Asia/Tomsk": "+07:00",
      "Asia/Ust-Nera": "+10:00",
      "Asia/Vladivostok": "+10:00",
      "Asia/Yakutsk": "+09:00",
      "Asia/Yekaterinburg": "+05:00",
      "Europe/Astrakhan": "+04:00",
      "Europe/Kaliningrad": "+02:00",
      "Europe/Kirov": "+03:00",
      "Europe/Moscow": "+03:00",
      "Europe/Samara": "+04:00",
      "Europe/Saratov": "+04:00",
      "Europe/Ulyanovsk": "+04:00",
      "Europe/Volgograd": "+03:00",
    },
    iso: { alpha2: "RU", alpha3: "RUS", numeric: "643" },
    phone: ["+7"],
    emoji: "🇷🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
  },
  {
    name: "Rwanda",
    region: "Africa",
    timezones: { "Africa/Kigali": "+02:00" },
    iso: { alpha2: "RW", alpha3: "RWA", numeric: "646" },
    phone: ["+250"],
    emoji: "🇷🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
  },
  {
    name: "Réunion",
    region: "Africa",
    timezones: { "Indian/Reunion": "+04:00" },
    iso: { alpha2: "RE", alpha3: "REU", numeric: "638" },
    phone: ["+262"],
    emoji: "🇷🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
  },
  {
    name: "Saint Barthélemy",
    region: "Americas",
    timezones: { "America/St_Barthelemy": "-04:00" },
    iso: { alpha2: "BL", alpha3: "BLM", numeric: "652" },
    phone: ["+590"],
    emoji: "🇧🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    region: "Africa",
    timezones: { "Atlantic/St_Helena": "+00:00" },
    iso: { alpha2: "SH", alpha3: "SHN", numeric: "654" },
    phone: ["+290"],
    emoji: "🇸🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    region: "Americas",
    timezones: { "America/St_Kitts": "-04:00" },
    iso: { alpha2: "KN", alpha3: "KNA", numeric: "659" },
    phone: ["+1-869"],
    emoji: "🇰🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
  },
  {
    name: "Saint Lucia",
    region: "Americas",
    timezones: { "America/St_Lucia": "-04:00" },
    iso: { alpha2: "LC", alpha3: "LCA", numeric: "662" },
    phone: ["+1-758"],
    emoji: "🇱🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
  },
  {
    name: "Saint Martin (French part)",
    region: "Americas",
    timezones: { "America/Marigot": "-04:00" },
    iso: { alpha2: "MF", alpha3: "MAF", numeric: "663" },
    phone: ["+590"],
    emoji: "🇲🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    region: "Americas",
    timezones: { "America/Miquelon": "-02:00" },
    iso: { alpha2: "PM", alpha3: "SPM", numeric: "666" },
    phone: ["+508"],
    emoji: "🇵🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    region: "Americas",
    timezones: { "America/St_Vincent": "-04:00" },
    iso: { alpha2: "VC", alpha3: "VCT", numeric: "670" },
    phone: ["+1-784"],
    emoji: "🇻🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
  },
  {
    name: "Samoa",
    region: "Oceania",
    timezones: { "Pacific/Apia": "+13:00" },
    iso: { alpha2: "WS", alpha3: "WSM", numeric: "882" },
    phone: ["+685"],
    emoji: "🇼🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
  },
  {
    name: "San Marino",
    region: "Europe",
    timezones: { "Europe/San_Marino": "+02:00" },
    iso: { alpha2: "SM", alpha3: "SMR", numeric: "674" },
    phone: ["+378"],
    emoji: "🇸🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
  },
  {
    name: "Sao Tome and Principe",
    region: "Africa",
    timezones: { "Africa/Sao_Tome": "+00:00" },
    iso: { alpha2: "ST", alpha3: "STP", numeric: "678" },
    phone: ["+239"],
    emoji: "🇸🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
  },
  {
    name: "Saudi Arabia",
    region: "Asia",
    timezones: { "Asia/Riyadh": "+03:00" },
    iso: { alpha2: "SA", alpha3: "SAU", numeric: "682" },
    phone: ["+966"],
    emoji: "🇸🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
  },
  {
    name: "Senegal",
    region: "Africa",
    timezones: { "Africa/Dakar": "+00:00" },
    iso: { alpha2: "SN", alpha3: "SEN", numeric: "686" },
    phone: ["+221"],
    emoji: "🇸🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
  },
  {
    name: "Serbia",
    region: "Europe",
    timezones: { "Europe/Belgrade": "+02:00" },
    iso: { alpha2: "RS", alpha3: "SRB", numeric: "688" },
    phone: ["+381"],
    emoji: "🇷🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
  },
  {
    name: "Seychelles",
    region: "Africa",
    timezones: { "Indian/Mahe": "+04:00" },
    iso: { alpha2: "SC", alpha3: "SYC", numeric: "690" },
    phone: ["+248"],
    emoji: "🇸🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
  },
  {
    name: "Sierra Leone",
    region: "Africa",
    timezones: { "Africa/Freetown": "+00:00" },
    iso: { alpha2: "SL", alpha3: "SLE", numeric: "694" },
    phone: ["+232"],
    emoji: "🇸🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
  },
  {
    name: "Singapore",
    region: "Asia",
    timezones: { "Asia/Singapore": "+08:00" },
    iso: { alpha2: "SG", alpha3: "SGP", numeric: "702" },
    phone: ["+65"],
    emoji: "🇸🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
  },
  {
    name: "Sint Maarten (Dutch part)",
    region: "Americas",
    timezones: { "America/Lower_Princes": "-04:00" },
    iso: { alpha2: "SX", alpha3: "SXM", numeric: "534" },
    phone: ["+599"],
    emoji: "🇸🇽",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
  },
  {
    name: "Slovakia",
    region: "Europe",
    timezones: { "Europe/Bratislava": "+02:00" },
    iso: { alpha2: "SK", alpha3: "SVK", numeric: "703" },
    phone: ["+421"],
    emoji: "🇸🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
  },
  {
    name: "Slovenia",
    region: "Europe",
    timezones: { "Europe/Ljubljana": "+02:00" },
    iso: { alpha2: "SI", alpha3: "SVN", numeric: "705" },
    phone: ["+386"],
    emoji: "🇸🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
  },
  {
    name: "Solomon Islands",
    region: "Oceania",
    timezones: { "Pacific/Guadalcanal": "+11:00" },
    iso: { alpha2: "SB", alpha3: "SLB", numeric: "090" },
    phone: ["+677"],
    emoji: "🇸🇧",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
  },
  {
    name: "Somalia",
    region: "Africa",
    timezones: { "Africa/Mogadishu": "+03:00" },
    iso: { alpha2: "SO", alpha3: "SOM", numeric: "706" },
    phone: ["+252"],
    emoji: "🇸🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
  },
  {
    name: "South Africa",
    region: "Africa",
    timezones: { "Africa/Johannesburg": "+02:00" },
    iso: { alpha2: "ZA", alpha3: "ZAF", numeric: "710" },
    phone: ["+27"],
    emoji: "🇿🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    region: "Americas",
    timezones: { "Atlantic/South_Georgia": "-02:00" },
    iso: { alpha2: "GS", alpha3: "SGS", numeric: "239" },
    phone: ["+"],
    emoji: "🇬🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg",
  },
  {
    name: "South Sudan",
    region: "Africa",
    timezones: { "Africa/Juba": "+02:00" },
    iso: { alpha2: "SS", alpha3: "SSD", numeric: "728" },
    phone: ["+211"],
    emoji: "🇸🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
  },
  {
    name: "Spain",
    region: "Europe",
    timezones: {
      "Africa/Ceuta": "+02:00",
      "Atlantic/Canary": "+01:00",
      "Europe/Madrid": "+02:00",
    },
    iso: { alpha2: "ES", alpha3: "ESP", numeric: "724" },
    phone: ["+34"],
    emoji: "🇪🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
  },
  {
    name: "Sri Lanka",
    region: "Asia",
    timezones: { "Asia/Colombo": "+05:30" },
    iso: { alpha2: "LK", alpha3: "LKA", numeric: "144" },
    phone: ["+94"],
    emoji: "🇱🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
  },
  {
    name: "Sudan",
    region: "Africa",
    timezones: { "Africa/Khartoum": "+02:00" },
    iso: { alpha2: "SD", alpha3: "SDN", numeric: "729" },
    phone: ["+249"],
    emoji: "🇸🇩",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
  },
  {
    name: "Suriname",
    region: "Americas",
    timezones: { "America/Paramaribo": "-03:00" },
    iso: { alpha2: "SR", alpha3: "SUR", numeric: "740" },
    phone: ["+597"],
    emoji: "🇸🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    region: "Europe",
    timezones: { "Arctic/Longyearbyen": "+02:00" },
    iso: { alpha2: "SJ", alpha3: "SJM", numeric: "744" },
    phone: ["+47"],
    emoji: "🇸🇯",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
  },
  {
    name: "Swaziland",
    region: "Africa",
    timezones: { "Africa/Mbabane": "+02:00" },
    iso: { alpha2: "SZ", alpha3: "SWZ", numeric: "748" },
    phone: ["+268"],
    emoji: "🇸🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
  },
  {
    name: "Sweden",
    region: "Europe",
    timezones: { "Europe/Stockholm": "+02:00" },
    iso: { alpha2: "SE", alpha3: "SWE", numeric: "752" },
    phone: ["+46"],
    emoji: "🇸🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
  },
  {
    name: "Switzerland",
    region: "Europe",
    timezones: { "Europe/Zurich": "+02:00" },
    iso: { alpha2: "CH", alpha3: "CHE", numeric: "756" },
    phone: ["+41"],
    emoji: "🇨🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
  },
  {
    name: "Syrian Arab Republic",
    region: "Asia",
    timezones: { "Asia/Damascus": "+03:00" },
    iso: { alpha2: "SY", alpha3: "SYR", numeric: "760" },
    phone: ["+963"],
    emoji: "🇸🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
  },
  {
    name: "Taiwan, Province of China",
    region: "Asia",
    timezones: { "Asia/Taipei": "+08:00" },
    iso: { alpha2: "TW", alpha3: "TWN", numeric: "158" },
    phone: ["+886"],
    emoji: "🇹🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
  },
  {
    name: "Tajikistan",
    region: "Asia",
    timezones: { "Asia/Dushanbe": "+05:00" },
    iso: { alpha2: "TJ", alpha3: "TJK", numeric: "762" },
    phone: ["+992"],
    emoji: "🇹🇯",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
  },
  {
    name: "Tanzania, United Republic of",
    region: "Africa",
    timezones: { "Africa/Dar_es_Salaam": "+03:00" },
    iso: { alpha2: "TZ", alpha3: "TZA", numeric: "834" },
    phone: ["+255"],
    emoji: "🇹🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
  },
  {
    name: "Thailand",
    region: "Asia",
    timezones: { "Asia/Bangkok": "+07:00" },
    iso: { alpha2: "TH", alpha3: "THA", numeric: "764" },
    phone: ["+66"],
    emoji: "🇹🇭",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
  },
  {
    name: "Timor-Leste",
    region: "Asia",
    timezones: { "Asia/Dili": "+09:00" },
    iso: { alpha2: "TL", alpha3: "TLS", numeric: "626" },
    phone: ["+670"],
    emoji: "🇹🇱",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
  },
  {
    name: "Togo",
    region: "Africa",
    timezones: { "Africa/Lome": "+00:00" },
    iso: { alpha2: "TG", alpha3: "TGO", numeric: "768" },
    phone: ["+228"],
    emoji: "🇹🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
  },
  {
    name: "Tokelau",
    region: "Oceania",
    timezones: { "Pacific/Fakaofo": "+13:00" },
    iso: { alpha2: "TK", alpha3: "TKL", numeric: "772" },
    phone: ["+690"],
    emoji: "🇹🇰",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
  },
  {
    name: "Tonga",
    region: "Oceania",
    timezones: { "Pacific/Tongatapu": "+13:00" },
    iso: { alpha2: "TO", alpha3: "TON", numeric: "776" },
    phone: ["+676"],
    emoji: "🇹🇴",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
  },
  {
    name: "Trinidad and Tobago",
    region: "Americas",
    timezones: { "America/Port_of_Spain": "-04:00" },
    iso: { alpha2: "TT", alpha3: "TTO", numeric: "780" },
    phone: ["+1-868"],
    emoji: "🇹🇹",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
  },
  {
    name: "Tunisia",
    region: "Africa",
    timezones: { "Africa/Tunis": "+01:00" },
    iso: { alpha2: "TN", alpha3: "TUN", numeric: "788" },
    phone: ["+216"],
    emoji: "🇹🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
  },
  {
    name: "Turkey",
    region: "Asia",
    timezones: { "Europe/Istanbul": "+03:00" },
    iso: { alpha2: "TR", alpha3: "TUR", numeric: "792" },
    phone: ["+90"],
    emoji: "🇹🇷",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
  },
  {
    name: "Turkmenistan",
    region: "Asia",
    timezones: { "Asia/Ashgabat": "+05:00" },
    iso: { alpha2: "TM", alpha3: "TKM", numeric: "795" },
    phone: ["+993"],
    emoji: "🇹🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
  },
  {
    name: "Turks and Caicos Islands",
    region: "Americas",
    timezones: { "America/Grand_Turk": "-04:00" },
    iso: { alpha2: "TC", alpha3: "TCA", numeric: "796" },
    phone: ["+1-649"],
    emoji: "🇹🇨",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
  },
  {
    name: "Tuvalu",
    region: "Oceania",
    timezones: { "Pacific/Funafuti": "+12:00" },
    iso: { alpha2: "TV", alpha3: "TUV", numeric: "798" },
    phone: ["+688"],
    emoji: "🇹🇻",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
  },
  {
    name: "Uganda",
    region: "Africa",
    timezones: { "Africa/Kampala": "+03:00" },
    iso: { alpha2: "UG", alpha3: "UGA", numeric: "800" },
    phone: ["+256"],
    emoji: "🇺🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
  },
  {
    name: "Ukraine",
    region: "Europe",
    timezones: { "Europe/Kyiv": "+03:00", "Europe/Simferopol": "+03:00" },
    iso: { alpha2: "UA", alpha3: "UKR", numeric: "804" },
    phone: ["+380"],
    emoji: "🇺🇦",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
  },
  {
    name: "United Arab Emirates",
    region: "Asia",
    timezones: { "Asia/Dubai": "+04:00" },
    iso: { alpha2: "AE", alpha3: "ARE", numeric: "784" },
    phone: ["+971"],
    emoji: "🇦🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
  },
  {
    name: "United Kingdom",
    region: "Europe",
    timezones: { "Europe/London": "+01:00" },
    iso: { alpha2: "GB", alpha3: "GBR", numeric: "826" },
    phone: ["+44"],
    emoji: "🇬🇧",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
  },
  {
    name: "United States",
    region: "Americas",
    timezones: {
      "America/Adak": "-09:00",
      "America/Anchorage": "-08:00",
      "America/Boise": "-06:00",
      "America/Chicago": "-05:00",
      "America/Denver": "-06:00",
      "America/Detroit": "-04:00",
      "America/Indiana/Indianapolis": "-04:00",
      "America/Indiana/Knox": "-05:00",
      "America/Indiana/Marengo": "-04:00",
      "America/Indiana/Petersburg": "-04:00",
      "America/Indiana/Tell_City": "-05:00",
      "America/Indiana/Vevay": "-04:00",
      "America/Indiana/Vincennes": "-04:00",
      "America/Indiana/Winamac": "-04:00",
      "America/Juneau": "-08:00",
      "America/Kentucky/Louisville": "-04:00",
      "America/Kentucky/Monticello": "-04:00",
      "America/Los_Angeles": "-07:00",
      "America/Menominee": "-05:00",
      "America/Metlakatla": "-08:00",
      "America/New_York": "-04:00",
      "America/Nome": "-08:00",
      "America/North_Dakota/Beulah": "-05:00",
      "America/North_Dakota/Center": "-05:00",
      "America/North_Dakota/New_Salem": "-05:00",
      "America/Phoenix": "-07:00",
      "America/Sitka": "-08:00",
      "America/Yakutat": "-08:00",
      "Pacific/Honolulu": "-10:00",
    },
    iso: { alpha2: "US", alpha3: "USA", numeric: "840" },
    phone: ["+1"],
    emoji: "🇺🇸",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
  },
  {
    name: "United States Minor Outlying Islands",
    region: "Oceania",
    timezones: { "Pacific/Midway": "-11:00", "Pacific/Wake": "+12:00" },
    iso: { alpha2: "UM", alpha3: "UMI", numeric: "581" },
    phone: ["+1"],
    emoji: "🇺🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg",
  },
  {
    name: "Uruguay",
    region: "Americas",
    timezones: { "America/Montevideo": "-03:00" },
    iso: { alpha2: "UY", alpha3: "URY", numeric: "858" },
    phone: ["+598"],
    emoji: "🇺🇾",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
  },
  {
    name: "Uzbekistan",
    region: "Asia",
    timezones: { "Asia/Samarkand": "+05:00", "Asia/Tashkent": "+05:00" },
    iso: { alpha2: "UZ", alpha3: "UZB", numeric: "860" },
    phone: ["+998"],
    emoji: "🇺🇿",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
  },
  {
    name: "Vanuatu",
    region: "Oceania",
    timezones: { "Pacific/Efate": "+11:00" },
    iso: { alpha2: "VU", alpha3: "VUT", numeric: "548" },
    phone: ["+678"],
    emoji: "🇻🇺",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    region: "Americas",
    timezones: { "America/Caracas": "-04:00" },
    iso: { alpha2: "VE", alpha3: "VEN", numeric: "862" },
    phone: ["+58"],
    emoji: "🇻🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
  },
  {
    name: "Viet Nam",
    region: "Asia",
    timezones: { "Asia/Ho_Chi_Minh": "+07:00" },
    iso: { alpha2: "VN", alpha3: "VNM", numeric: "704" },
    phone: ["+84"],
    emoji: "🇻🇳",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
  },
  {
    name: "Virgin Islands, British",
    region: "Americas",
    timezones: { "America/Tortola": "-04:00" },
    iso: { alpha2: "VG", alpha3: "VGB", numeric: "092" },
    phone: ["+1-284"],
    emoji: "🇻🇬",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
  },
  {
    name: "Virgin Islands, U.S.",
    region: "Americas",
    timezones: { "America/St_Thomas": "-04:00" },
    iso: { alpha2: "VI", alpha3: "VIR", numeric: "850" },
    phone: ["+1-340"],
    emoji: "🇻🇮",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
  },
  {
    name: "Wallis and Futuna",
    region: "Oceania",
    timezones: { "Pacific/Wallis": "+12:00" },
    iso: { alpha2: "WF", alpha3: "WLF", numeric: "876" },
    phone: ["+681"],
    emoji: "🇼🇫",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
  },
  {
    name: "Yemen",
    region: "Asia",
    timezones: { "Asia/Aden": "+03:00" },
    iso: { alpha2: "YE", alpha3: "YEM", numeric: "887" },
    phone: ["+967"],
    emoji: "🇾🇪",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
  },
  {
    name: "Zambia",
    region: "Africa",
    timezones: { "Africa/Lusaka": "+02:00" },
    iso: { alpha2: "ZM", alpha3: "ZMB", numeric: "894" },
    phone: ["+260"],
    emoji: "🇿🇲",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
  },
  {
    name: "Zimbabwe",
    region: "Africa",
    timezones: { "Africa/Harare": "+02:00" },
    iso: { alpha2: "ZW", alpha3: "ZWE", numeric: "716" },
    phone: ["+263"],
    emoji: "🇿🇼",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
  },
  {
    name: "Åland Islands",
    region: "Europe",
    timezones: { "Europe/Mariehamn": "+03:00" },
    iso: { alpha2: "AX", alpha3: "ALA", numeric: "248" },
    phone: ["+358-18"],
    emoji: "🇦🇽",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
  },
];

export const getCountryInfo = (code: tCountryCode | string) => {
  return _find(COUNTRIES, (c) => c.iso.alpha2 === code || c.name === code);
};

export const getCountryFlagURL2 = (code: tCountryCode) => {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`;
};
