import { first, last, max } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useContext, useEffect, useMemo } from "react";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "../../../utils/colors";
import { hotelGetManualDataOrdered } from "../../../utils/hotels/manualData";
import AddManualDataButton from "../../Buttons/addManualDataButton";
import InterTag from "../../Text/Inter";
import SimpleDateRangePicker from "../SimpleDateRangePicker";

interface iWithManualDataProps extends iProps {}

const WithManualData: React.FC<iWithManualDataProps> = ({
  manualDataIndex,
  setManualDataIndex,
  disabled,
}) => {
  const { activeProperty } = useContext(HotelStateContext)!;

  useEffect(() => {
    if (manualDataIndex + 1 > activeProperty.manualData.length || manualDataIndex === -1)
      setManualDataIndex(0);
  }, [activeProperty.manualData.length, manualDataIndex, setManualDataIndex]);

  const orderedManualData = useMemo(
    () => hotelGetManualDataOrdered(activeProperty),
    [activeProperty]
  );

  const selectedManualData = orderedManualData.length
    ? orderedManualData[orderedManualData.length - 1 - manualDataIndex]
    : null;

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of orderedManualData) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [orderedManualData]);

  if (!selectedManualData) return null;

  return (
    <SimpleDateRangePicker
      disabled={disabled}
      {...{
        ...(orderedManualData.length
          ? {
              limitEndYear: max([
                moment(last(orderedManualData)!.to).year() - moment().year() + 1,
                0,
              ]),
              limitStartYear: moment().year() - moment(first(orderedManualData)!.from).year() + 1,
            }
          : {}),
      }}
      showOneCalendar
      oneTap
      hoverRange={"month"}
      shouldDisableDate={(date, selectedDate, selectedDone, target) => {
        for (const [from, to] of availablePeriods) {
          if (moment(date).isSameOrAfter(moment(from)) && moment(date).isSameOrBefore(moment(to))) {
            return false;
          }
        }
        return true;
      }}
      onChange={(range) => {
        if (range) {
          for (const [i, { from, to }] of activeProperty.manualData.entries()) {
            if (
              moment(range[0]).startOf("day").isSame(moment(from).startOf("day")) &&
              moment(range[1]).endOf("day").isSame(moment(to).endOf("day"))
            ) {
              setManualDataIndex(i);
            }
          }
        }
      }}
      value={[selectedManualData.from, selectedManualData.to]}
      ranges={[]}
      cleanable={false}
    />
  );
};

interface iNoManualDataProps extends Pick<iProps, "disabled" | "setManualDataIndex"> {}

const NoManualData: React.FC<iNoManualDataProps> = ({ disabled, setManualDataIndex }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();

  const [textSize, containerHeight] = isMobile ? [12, 24] : [14, 34];

  useEffect(() => {
    setManualDataIndex(-1);
  }, [setManualDataIndex]);

  return (
    <div
      style={{
        padding: isMobile ? "1px 8px" : "0 8px",
        height: `${containerHeight}px`,
      }}
      className={`flex flex-row items-center gap-1.5 border-sky-500 border-2 rounded-md`}
    >
      <InterTag
        text={trans("general.no_manual_data")}
        size={textSize}
        color={disabled ? COLORS.gray_400 : COLORS.secondary}
      />
    </div>
  );
};

interface iProps {
  disabled?: boolean;
  manualDataIndex: number;
  setManualDataIndex: React.Dispatch<React.SetStateAction<number>> | ((index: number) => void);
  showAddButton?: boolean;
}

const SimpleDateRangePickerForManualDataPeriods2: React.FC<iProps> = ({
  manualDataIndex,
  setManualDataIndex,
  disabled,
  showAddButton = true,
}) => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const { hasPermission } = useContext(PermissionsContext)!;

  return (
    <div className="flex flex-row items-center gap-1.5">
      {activeProperty.manualData.length ? (
        <WithManualData {...{ manualDataIndex, setManualDataIndex, disabled, showAddButton }} />
      ) : (
        <NoManualData {...{ setManualDataIndex, disabled, showAddButton }} />
      )}
      {showAddButton && <div>{hasPermission("create:manual-data") && <AddManualDataButton />}</div>}
    </div>
  );
};

export default SimpleDateRangePickerForManualDataPeriods2;
