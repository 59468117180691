import moment from "moment";

const buildTransKey = (key: string) => `components.date_range_picker.ranges.${key}`;

export const TODAY = buildTransKey("today");
export const THIS_WEEK = buildTransKey("this_week");
export const LAST_7_DAYS = buildTransKey("last_7_days");
export const LAST_WEEK = buildTransKey("last_week");
export const THIS_MONTH = buildTransKey("this_month");
export const LAST_30_DAYS = buildTransKey("last_30_days");
export const LAST_MONTH = buildTransKey("last_month");
export const LAST_6_MONTHS = buildTransKey("last_6_months");
export const THIS_YEAR = buildTransKey("this_year");
export const LAST_YEAR = buildTransKey("last_year");

export const DEFAULT_RANGE: [Date, Date] = [
  moment().subtract(6, "days").startOf("day").toDate(),
  moment().startOf("hour").toDate(),
];

export const TODAY_RANGE: [Date, Date] = [
  moment().startOf("day").toDate(),
  moment().endOf("day").toDate(),
];

export type tDateRangeListItem = {
  label: string;
  value: [Date, Date];
};

export const DATE_RANGE_PICKER_RANGES: tDateRangeListItem[] = [
  {
    label: TODAY,
    value: TODAY_RANGE,
  },
  {
    label: THIS_WEEK,
    value: [moment().startOf("week").toDate(), moment().toDate()],
  },
  {
    label: LAST_7_DAYS,
    value: [
      moment().subtract(6, "days").startOf("day").toDate(),
      moment().startOf("hour").toDate(),
    ],
  },
  {
    label: LAST_WEEK,
    value: [
      moment().subtract(1, "week").startOf("week").toDate(),
      moment().subtract(1, "week").endOf("week").toDate(),
    ],
  },
  {
    label: THIS_MONTH,
    value: [moment().startOf("month").toDate(), moment().toDate()],
  },
  {
    label: LAST_30_DAYS,
    value: [
      moment().subtract(29, "days").startOf("day").toDate(),
      moment().startOf("hour").toDate(),
    ],
  },
  {
    label: LAST_MONTH,
    value: [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  },
  {
    label: LAST_6_MONTHS,
    value: [moment().subtract(6, "month").startOf("day").toDate(), moment().toDate()],
  },
  {
    label: THIS_YEAR,
    value: [moment().startOf("year").startOf("day").toDate(), moment().toDate()],
  },
  {
    label: LAST_YEAR,
    value: [
      moment().subtract(1, "year").startOf("year").toDate(),
      moment().subtract(1, "year").endOf("year").toDate(),
    ],
  },
];

export const DATE_RANGES_OBJ: Record<string, [Date, Date]> = Object.fromEntries(
  DATE_RANGE_PICKER_RANGES.map(({ label, value }) => [label, value])
);

export const DATE_RANGE_PICKER_RANGES_YEARLY: tDateRangeListItem[] = [
  {
    label: THIS_YEAR,
    value: [moment().startOf("year").toDate(), moment().endOf("year").toDate()],
  },
  {
    label: LAST_YEAR,
    value: [
      moment().subtract(1, "year").startOf("year").toDate(),
      moment().subtract(1, "year").endOf("year").toDate(),
    ],
  },
];
