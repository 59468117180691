import axios from "axios";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, Loader } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

interface iProps {}

const ForgotPasswordWrapped: React.FC<iProps> = () => {
  const { isTablet } = useWindowSizeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const postRequest = usePostRequest();

  const [emailState, setEmailState] = useState({ value: "", error: "" });

  const handleSubmit = () => {
    if (!emailState.value.length) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("pages.forgot_password.errors.no_email"),
      }));
      return;
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(emailState.value)) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("pages.forgot_password.errors.invalid_email"),
      }));
      return;
    }

    postRequest.pending();
    axios
      .post(constructApiAddress(`/v2/managers/forgot-password`, USE_MONOLITH_SERVERLESS), {
        email: emailState.value,
      })
      .then(() => {
        postRequest.resolve({}, trans("pages.forgot_password.notifications.done"));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  const renderGreeting = (mobile: boolean) => {
    const [titleSize, containerProps] = mobile ? [24, { left: true }] : [32, { middle: true }];
    return (
      <Flex gap={8} column {...{ ...containerProps }}>
        <InterTag
          bold
          text={trans("pages.forgot_password.title")}
          size={titleSize}
          color={COLORS.secondary}
        />
        <InterTag
          wrap
          style={{ lineHeight: "20px" }}
          text={trans("pages.forgot_password.description")}
          size={16}
          color={COLORS.secondary}
        />
      </Flex>
    );
  };

  const renderForm = () => (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Flex column gap={16}>
        <Flex gap={4} className="width-100" column>
          <InputWrapper label={trans("general.email")} error={emailState.error}>
            <InputGroup>
              <InputGroup.Addon>
                <Icon Element={MailIcon} width={24} height={24} fill={COLORS.secondary} />
              </InputGroup.Addon>
              <Input
                value={emailState.value}
                onChange={(value) =>
                  setEmailState((prev) => ({
                    ...prev,
                    error: "",
                    value: value,
                  }))
                }
                type="text"
                placeholder={trans("general.placeholders.email")}
                size="lg"
              />
            </InputGroup>
          </InputWrapper>
        </Flex>
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderConfirmButton = () => (
    <SimpleButton
      loading={postRequest.isLoading}
      onClick={handleSubmit}
      appearance="primary"
      text={{
        text: trans("general.submit"),
        bold: true,
        color: COLORS.white,
        size: 20,
      }}
    />
  );

  const renderFooter = (color: string) => (
    <>
      <Flex row gap={4} center>
        <InterTag color={color} text={trans("pages.setup_password.signin")} />
        <InterTag
          color={COLORS.primary}
          text={trans("general.signin")}
          hoverUnderline
          onClick={() => {
            navigate("/login");
          }}
        />
      </Flex>
      <Flex row gap={4} center>
        <InterTag color={color} text={trans("pages.forgot_password.signup[0]")} />
        <InterTag
          color={COLORS.primary}
          text={trans("pages.forgot_password.signup[1]")}
          hoverUnderline
          onClick={() => {
            navigate("/register");
          }}
        />
        <InterTag color={color} text={trans("pages.forgot_password.signup[2]")} />
      </Flex>
    </>
  );

  if (isTablet)
    return (
      <NoAuthDesktopWrapper>
        <Fragment>
          {postRequest.isLoading && <Loader center backdrop size="lg" />}
          <Flex column middle gap={16}>
            <Flex
              column
              center
              style={{
                backgroundColor: COLORS.light_white,
                borderRadius: "16px",
                padding: "64px",
                minWidth: "640px",
                maxWidth: "640px",
              }}
            >
              <Flex column center gap={24}>
                {renderGreeting(false)}
                {renderForm()}
                {renderConfirmButton()}
              </Flex>
            </Flex>
            <>{renderFooter(COLORS.white)}</>
          </Flex>
        </Fragment>
      </NoAuthDesktopWrapper>
    );

  return (
    <NoAuthMobileWrapper>
      <Flex column style={{ marginTop: "80px", padding: "0 20px" }} gap={32}>
        {renderGreeting(true)}
        <Flex column style={{ padding: "0 8px" }} gap={32}>
          {renderForm()}
          {renderConfirmButton()}
        </Flex>
        <Flex column gap={8}>
          {renderFooter(COLORS.secondary)}
        </Flex>
      </Flex>
    </NoAuthMobileWrapper>
  );
};

export default ForgotPasswordWrapped;
