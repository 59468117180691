import React, { useContext } from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import { HotelStateContext } from "../../../context/Hotel";
import { PermissionsContext } from "../../../context/Permissions";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../utils/hotels/subscriptions";
import AwardsList from "./list";
import AwardsStatistics from "./statistics";
import Unlock from "./unlock";

const AwardsWrapped: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const { hasPermission } = useContext(PermissionsContext)!;
  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!hasPermission("read:awards")) {
    return <NoPermissions />;
  }

  return (
    <>
      <Flex column gap={40}>
        <AwardsStatistics />
        <AwardsList />
      </Flex>
      <PageBottomPadding />
    </>
  );
};

const Awards: React.FC = () => {
  return (
    <IpValidatorGuard>
      <AwardsWrapped />
    </IpValidatorGuard>
  );
};

export default Awards;
