import axios from "axios";
import { min, sum } from "lodash";
import React, { useContext, useState } from "react";
import { Table, useToaster } from "rsuite";
import { ReactComponent as CarTagIcon } from "../../../../assets/icons/car_tag.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as LocalGasStationIcon } from "../../../../assets/icons/local_gas_station.svg";
import { ReactComponent as SwapDrivingAppsWheelIcon } from "../../../../assets/icons/swap_driving_apps_wheel.svg";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import { HotelStateContext } from "../../../../context/Hotel";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import { tHotelFleet } from "../../../../models/hotel";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../../utils/tables";
import EntriesModal from "../entries/modal";
import VehicleModal from "./modal";

type tTableItem = {
  _id: tHotelFleet["_id"];
  makeModel: string;
  licensePlate: string;
  fuelType: string;
  distanceKm: number;
  fuelLiters: number;
};

const List: React.FC = () => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [entryModal, setEntryModal] = useState<{
    open: boolean;
    fleetId?: tHotelFleet["_id"];
  }>({ open: false, fleetId: undefined });
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const [confirmModal, setConfirmModal] = useState<{ open: boolean }>({
    open: false,
  });
  const [vehicleModal, setVehicleModal] = useState<
    { open: false; fleetId: undefined } | { open: true; fleetId: tHotelFleet["_id"] }
  >({ open: false, fleetId: undefined });
  const deleteRequest = useDeleteRequest();

  const tableData: tTableItem[] = activeProperty.fleet.map(
    ({ _id, vehicle: { make, model, fuelType }, licensePlate, entries }) => ({
      _id,
      makeModel: `${make} ${model}`,
      licensePlate,
      fuelType,
      distanceKm: sum(entries.map((e) => e.distanceKm)),
      fuelLiters: sum(entries.map((e) => e.fuelLiters)),
    })
  );

  const tableHeight = (min([5, tableData.length]) || 1) * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT;

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "edit",
      label: trans("pages.fleet.archive.segments.vehicles.sections.list.table.options.edit"),
      onClick() {
        setVehicleModal({ open: true, fleetId: item._id });
      },
    });

    options.push({
      key: "add entry",
      label: trans("pages.fleet.archive.segments.vehicles.sections.list.table.options.add_usage"),
      onClick() {
        setEntryModal({ open: true, fleetId: item._id });
      },
    });

    options.push({
      key: "remove",
      label: {
        text: trans(
          "pages.fleet.archive.segments.vehicles.sections.list.table.options.remove_vehicle"
        ),
        color: COLORS.error,
      },
      icon: { Element: DeleteIcon, fill: COLORS.error },
      onClick() {
        setConfirmModal({ open: true });
        deleteRequest.pending();
        axios
          .delete(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/fleet/${item._id}`,
              USE_MONOLITH_SERVERLESS
            )
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(activeProperty._id, hotel);
            deleteRequest.resolve();
            toaster.push(
              notification(
                "success",
                trans("pages.fleet.archive.segments.vehicles.sections.list.table.vehicle_removed")
              ),
              { placement: "topEnd" }
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            deleteRequest.reject(error);
            toaster.push(notification("error", error), { placement: "topEnd" });
          });
      },
    });

    return options;
  };

  return (
    <>
      <ConfirmModal
        description={trans(
          "pages.fleet.archive.segments.vehicles.sections.list.table.confirm_vehicle_removal"
        )}
        onClose={() => setConfirmModal({ open: false })}
        onConfirm={() => {
          setConfirmModal({ open: false });
        }}
        open={confirmModal.open}
      />
      <EntriesModal
        {...{ ...entryModal }}
        onClose={() => setEntryModal({ open: false, fleetId: undefined })}
      />
      <VehicleModal
        {...{ ...vehicleModal }}
        onClose={() => setVehicleModal({ open: false, fleetId: undefined })}
      />
      <div className="table-wrapper">
        <Table
          id="TABLE.CONSUMPTION.FLEET.VEHICLES"
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={tableData}
          loading={deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.vehicle")} icon={DirectionsCarIcon} />
            <SimpleKeyValueCell dataKey="makeModel" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.license_plate")} icon={CarTagIcon} />
            <SimpleKeyValueCell dataKey="licensePlate" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.fuel_type")} icon={LocalGasStationIcon} />
            <SimpleKeyValueCell dataKey="fuelType" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.fuel_usage")} icon={LocalGasStationIcon} />
            <SimpleKeyValueCell
              dataKey="fuelLiters"
              textFunction={(item: tTableItem) => {
                return `${item.fuelLiters} L`;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell name={trans("general.distance")} icon={SwapDrivingAppsWheelIcon} />
            <SimpleKeyValueCell
              dataKey="distanceKm"
              textFunction={(item: tTableItem) => {
                return `${item.distanceKm} km`;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

export default List;
