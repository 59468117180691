import moment from "moment";
import React, { useContext } from "react";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { TimeframeStateContext } from "../../../../context/Timeframe";
import useGetMeasures from "../../../../hooks/apiRequests/useGetMeasures";
import { TE, tMeasure, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import { hotelGetMeasuresTracked } from "../../../../utils/hotels/measures";
import { tBenchmarkSizes } from "../sustainability";
import SensorDataBenchmark from "./benchmark";
import Indicators from "./indicators";

interface iProps {
  benchmarkSizes: tBenchmarkSizes;
  selectedSection: number;
}

const SensorData: React.FC<iProps> = ({ benchmarkSizes, selectedSection }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const trackedMeasures = hotelGetMeasuresTracked(activeProperty);
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;

  const measuresList: tMeasure[] = [];
  if (trackedMeasures.te) {
    measuresList.push(TE);
  }
  if (trackedMeasures.tw) {
    measuresList.push(TW);
  }

  const measuresRequest = useGetMeasures(
    activeProperty,
    {
      measures: measuresList,
      from: moment(sensorDataTimeframe[0]).toISOString(),
      to: moment(sensorDataTimeframe[1]).toISOString(),
      binUnit: "day",
      binValue: 1,
      spaces: activeProperty.spaces.filter((s) => s.isAccommodation).map((s) => s._id),
    },
    { useLocalApi: true }
  );
  const homologueMeasuresRequest = useGetMeasures(activeProperty, {
    measures: measuresList,
    from: moment(sensorDataTimeframe[0]).subtract(1, "year").toISOString(),
    to: moment(sensorDataTimeframe[1]).subtract(1, "year").toISOString(),
    binUnit: "day",
    binValue: 1,
    spaces: activeProperty.spaces.filter((s) => s.isAccommodation).map((s) => s._id),
  });

  if (measuresList.length === 0)
    return (
      <Flex>
        <InterTag stize={14} color={COLORS.error} text={trans("No data from sensors")} />
      </Flex>
    );

  return (
    <>
      <SensorDataBenchmark
        {...{
          measuresRequest,
          homologueMeasuresRequest,
          period: sensorDataTimeframe,
          noData: measuresList.length === 0,
          benchmarkSizes,
        }}
      />
      <Indicators
        {...{
          measuresRequest,
          homologueMeasuresRequest,
          period: sensorDataTimeframe,
          binUnit: "day",
          binValue: 1,
        }}
      />
    </>
  );

  // switch (selectedSection) {
  //   case 0:
  //     return (
  //       <SensorDataBenchmark
  //         {...{
  //           measuresRequest,
  //           homologueMeasuresRequest,
  //           period: sensorDataTimeframe,
  //           noData: measuresList.length === 0,
  //           benchmarkSizes,
  //         }}
  //       />
  //     );
  //   case 1:
  //     return (
  //       <Indicators
  //         {...{
  //           measuresRequest,
  //           homologueMeasuresRequest,
  //           period: sensorDataTimeframe,
  //           binUnit: "day",
  //           binValue: 1,
  //         }}
  //       />
  //     );
  //   default:
  //     return null;
  // }
};

export default SensorData;
