import React from "react";
import { HotelDispatchContext } from "../";
import { tHotel, tHotelId } from "../../../models/hotel";

const useHotelDispatch = () => {
  const dispatch = React.useContext(HotelDispatchContext);

  if (!dispatch) throw Error("useHotelDispatch must be used within HotelStateContext");

  const updateHotel = (hotelId: string, hotel: Partial<tHotel>) => {
    dispatch({ type: "update hotel", hotelId, hotel });
  };

  const updatingHotel = () => {
    dispatch({ type: "pending" });
  };

  const resolveHotel = () => {
    dispatch({ type: "resolved" });
  };

  const setActiveProperty = (activeProperty: tHotelId) => {
    dispatch({ type: "select hotel", activeProperty });
  };

  return { dispatch, updateHotel, updatingHotel, resolveHotel, setActiveProperty };
};

export default useHotelDispatch;
