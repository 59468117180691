import React from "react";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../utils/colors";
import { TABLE_HEADER_CLASSNAMES, tTableColumn } from "../../utils/tables";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";
import InterTag from "../Text/Inter";

interface iProps {
  columns: tTableColumn[];
}

const TableHeaders: React.FC<iProps> = ({ columns }) => {
  const { trans } = useLocalizationState();
  return (
    <div className={TABLE_HEADER_CLASSNAMES} style={{ backgroundColor: COLORS.gray_300 }}>
      {columns
        .filter((c) => !c.hide)
        .map(({ className, key, label, icon }) => {
          return (
            <div className={`${className} `} key={key}>
              <div className="flex flex-row items-center gap-1">
                {icon && <Icon {...{ size: 16, ...toIconProps(icon) }} />}
                <InterTag asHTML text={trans(label)} size={12} color={COLORS.secondary} bold />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TableHeaders;
