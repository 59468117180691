import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { Button, CheckPicker, Input, Modal, ModalProps, useToaster } from "rsuite";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { ReactComponent as AssignmentIcon } from "../../../../assets/icons/assignment.svg";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../../assets/icons/keyboard_arrow_right.svg";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import PageSection from "../../../../components/PageSection";
import { HotelStateContext } from "../../../../context/Hotel";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../../hooks/apiRequests/useApiRequest";
import useListStaff from "../../../../hooks/useListStaff";
import { tHotelStaff, tPropertyStaffRole, tPropertyStaffRoleId } from "../../../../models/hotel";
import { constructApiAddress, USE_SERVER } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { _find } from "../../../../utils/lodash-utils";
import { notification } from "../../../../utils/notifications";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";

type tData = {
  role?: tPropertyStaffRole;
  roleName: string;
  staff: tHotelStaff["staffId"][];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    roleName: "",
    staff: [],
    role: undefined,
  },
  errors: { roleName: "", staff: "", role: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateEditRoleWrappedProps extends Pick<iProps, "onClose" | "roleId" | "staffRequest"> {}

const CreateEditRoleWrapped: React.FC<iCreateEditRoleWrappedProps> = ({
  onClose,
  roleId,
  staffRequest,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const apiRequest = useApiRequest();

  useEffect(() => {
    if (roleId) {
      const roleFound = _find(activeProperty.staffRoles, (sr) => sr._id === roleId);
      if (roleFound)
        return dispatch({
          type: "data",
          data: {
            roleName: roleFound.name,
            role: roleFound,
            staff: roleFound.staff,
          },
        });
      return onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProperty.staffRoles, roleId]);

  const handleConfirm = () => {
    apiRequest.pending();
    (roleId
      ? (() => {
          const data: Partial<{
            name: string;
            staff: tHotelStaff["staffId"][];
          }> = {};

          if (state.data.role) {
            if (state.data.roleName !== state.data.role.name) {
              data.name = state.data.roleName;
            }

            if (
              JSON.stringify(state.data.staff.sort()) !==
              JSON.stringify(state.data.role.staff.sort())
            )
              data.staff = state.data.staff;
          }

          return axios.patch(
            constructApiAddress(
              `/properties/${activeProperty._id}/staff-roles/${roleId}`,
              USE_SERVER.propertiesMicroservice.local
            ),
            data
          );
        })()
      : axios.post(
          constructApiAddress(
            `/properties/${activeProperty._id}/staff-roles`,
            USE_SERVER.propertiesMicroservice.local
          ),
          {
            name: state.data.roleName,
            staff: state.data.staff,
          }
        )
    )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateHotel(activeProperty._id, property);
        apiRequest.resolve();
        toaster.push(
          notification(
            "success",
            roleId
              ? trans("pages.users.sections.management.modals.roles.notifications.updated")
              : trans("pages.users.sections.management.modals.roles.notifications.created")
          ),
          {
            placement: "topEnd",
          }
        );
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        apiRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const checkboxesDisabled = state.data.role?.isAdmin;

  return (
    <Modal.Body className="modal-body-pb0 create-role-modal">
      <Flex column gap={20}>
        <PageSection
          title={
            roleId
              ? trans("pages.users.sections.management.modals.roles.title.update")
              : trans("pages.users.sections.management.modals.roles.title.create")
          }
          icon={AssignmentIcon}
        />
        <InputWrapper label={trans("general.role")}>
          <Flex row>
            <Input
              style={{ width: "50%" }}
              disabled={apiRequest.isLoading}
              value={state.data.roleName}
              onChange={(value) => dispatch({ type: "key value", key: "roleName", value })}
            />
            {state.data.role !== undefined && (
              <Button
                disabled={apiRequest.isLoading || state.data.role.name === state.data.roleName}
                appearance="link"
                onClick={() => {
                  dispatch({
                    type: "key value",
                    key: "roleName",
                    value: state.data.role!.name,
                  });
                }}
              >
                {trans("Reset")}
              </Button>
            )}
          </Flex>
        </InputWrapper>
        <InputWrapper label={trans("general.users")}>
          <Flex row>
            <CheckPicker
              disabled={apiRequest.isLoading || checkboxesDisabled}
              style={{ width: "50%" }}
              value={state.data.staff}
              onChange={(value, event) => {
                dispatch({ type: "key value", key: "staff", value });
              }}
              data={staffRequest.data.staff.map((s) => ({
                label: s.name,
                value: s._id,
              }))}
              loading={staffRequest.isLoading}
            />
            {state.data.role !== undefined && !checkboxesDisabled && (
              <Button
                disabled={apiRequest.isLoading}
                appearance="link"
                onClick={() => {
                  dispatch({
                    type: "key value",
                    key: "staff",
                    value: state.data.role!.staff,
                  });
                }}
              >
                {trans("general.reset")}
              </Button>
            )}
          </Flex>
        </InputWrapper>
        {/* {renderPermissions()} */}
        <Modal2ActionButtons
          isLoading={apiRequest.isLoading}
          confirmButtonDisabled={state.data.roleName.length === 0}
          onClose={onClose}
          onConfirm={handleConfirm}
          {...{
            ...(roleId
              ? {
                  confirmIcon: KeyboardArrowRightIcon,
                  confirmLabel: trans("general.update"),
                }
              : {
                  confirmIcon: AddIcon,
                  confirmLabel: trans("general.add"),
                }),
          }}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  roleId?: tPropertyStaffRoleId;
  staffRequest: ReturnType<typeof useListStaff>;
}

const CreateEditRole: React.FC<iProps> = ({ onClose, roleId, open, staffRequest, ...props }) => {
  return (
    <Modal
      {...{
        ...DEFAULT_MODAL_PROPS,
        open,
        onClose,
        size: "md",
        overflow: false,
        ...props,
      }}
    >
      <CreateEditRoleWrapped {...{ onClose, roleId, staffRequest }} />
    </Modal>
  );
};

export default CreateEditRole;
