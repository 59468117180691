import React from "react";
import { SelectPicker } from "rsuite";
import useLocalizationDispatch from "../../../context/Localization/hooks/useLocalizationDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { LANGAUGES } from "../../../utils/languages";

const LanguageRow: React.FC = () => {
  const { trans, language } = useLocalizationState();
  const { setLanguage } = useLocalizationDispatch();

  const list = LANGAUGES.filter(({ code }) => ["en", "pt"].includes(code)).map(
    ({ code, language }) => ({
      label: trans(`general.languages_names.${language.toLowerCase()}`),
      value: code,
    })
  );

  return (
    <div className={"flex flex-row justify-between h-12 pl-4 border-b border-gray-200"}>
      <div className="flex flex-row items-center flex-1">
        <p className="text-sm text-secondary font-bold inter">{trans("general.language")}</p>
      </div>
      <div className="flex flex-row flex-1 items-center justify-end">
        <SelectPicker
          appearance="subtle"
          data={list}
          value={language}
          caretAs={undefined}
          cleanable={false}
          placement="leftStart"
          onChange={(value) => {
            if (value) setLanguage(value);
          }}
        />
      </div>
    </div>
  );
};

const GeneralSettingsWrapped: React.FC = () => {
  return (
    <div>
      <LanguageRow />
    </div>
  );
};

const GeneralSettings: React.FC = () => {
  return <GeneralSettingsWrapped />;
};

export default GeneralSettings;
