import { tManager } from "../../models/manager";

export const defaultManager: tManager = {
  _id: "",
  name: "",
  email: "",
  hotels: [],
  properties: [],
  images: [],
  method: "local",
  local: { password: "" },
  facebook: { id: "" },
  google: { id: "" },
  azure: null,
  active: true,
  isDummy: false,
  shadowAccount: false,
  isConsultant: false,
  settings: {
    display: {
      numberFormat: {
        thousandSeparator: ".",
        decimalSeparator: ",",
      },
    },
  },
  phoneNumber: "",

  lastAccess: new Date(),
  lastLogin: new Date(),

  activeProperty: "",
  activeHotel: "",

  confirmEmailToken: "",
  emailConfirmed: true,

  forgotPasswordValidUnitl: new Date(),
  forgotPasswordToken: "",

  createdAt: new Date(),
  updatedAt: new Date(),
};
