import axios, { AxiosResponse } from "axios";
import { findIndex } from "lodash";
import React, { useMemo, useReducer } from "react";
import useEffectSafe from "../../hooks/useEffectSafe";
import useSimpleToaster from "../../hooks/useSimpleToaster";
import tHotel, { tProperty } from "../../models/hotel";
import { tManager } from "../../models/manager";
import { constructApiAddress, USE_SERVER } from "../../utils/apiCall";
import { RUN_CONTEXT } from "../../utils/context";
import { defaultHotel } from "../../utils/hotels";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { _find, _has } from "../../utils/lodash-utils";
import { defaultManager } from "../../utils/managers";
import { sleep } from "../../utils/others";
import useAuthDispatch from "../Auth/hooks/useAuthDispatch";
import useProfileDispatch from "../Profile/hooks/useProfileDispatch";
import { nHotel } from "./interfaces";

const HotelDispatchContext = React.createContext<nHotel.tDispatchContext | undefined>(undefined);
HotelDispatchContext.displayName = "HotelDispatchContext";
const HotelStateContext = React.createContext<nHotel.tStateContext | undefined>(undefined);
HotelStateContext.displayName = "HotelStateContext";

const LS_DATA = "__h__";

const initialState: nHotel.tState = {
  data: {
    hotels: [],
    activeProperty: "",
  },
  status: "pending",
  error: null,
};

const reducer = (state: nHotel.tState, action: nHotel.tAction): nHotel.tState => {
  try {
    switch (action.type) {
      case "set data": {
        const { data } = action;
        localStorage.setItem(LS_DATA, JSON.stringify(data));
        return { ...state, status: "resolved", error: null, data };
      }
      case "select hotel": {
        const { activeProperty } = action;
        const stored = localStorage.getItem(LS_DATA);

        if (!stored) {
          const data = { ...state.data, activeProperty };
          localStorage.setItem(LS_DATA, JSON.stringify(data));

          return { ...state, data };
        } else {
          const data = { ...state.data, activeProperty };
          return { ...state, data };
        }
      }
      case "set hotels": {
        const { hotels } = action;
        const data = {
          ...state.data,
          hotels: hotels.map((h) => ({
            ...defaultHotel,
            ...h,
            settings: {
              ...defaultHotel.settings,
              ...h.settings,
              display: { ...defaultHotel.settings.display, ...h.settings.display },
            },
          })) as nHotel.tHotelAugmented[],
        };
        localStorage.setItem(LS_DATA, JSON.stringify(data));

        return { ...state, status: "resolved", error: null, data };
      }
      case "update hotel": {
        const { hotelId, hotel } = action;
        const index = findIndex(state.data.hotels, (h) => h._id === hotelId);
        if (index >= 0) {
          state.data.hotels[index] = { ...state.data.hotels[index], ...hotel };
          state.data.hotels = [...state.data.hotels];
        }
        localStorage.setItem(LS_DATA, JSON.stringify(state.data));

        return { ...state, status: "resolved", error: null };
      }
      case "resolved": {
        return { ...state, status: "resolved", error: null };
      }
      case "rejected": {
        const { error } = action;
        return { ...state, status: "rejected", error };
      }
      case "pending": {
        return { ...state, status: "pending", error: null };
      }
      default:
        return { ...state };
    }
  } catch (err) {
    return { ...state };
  }
};

const HotelContextProvider: React.FC<nHotel.iContextProps> = ({ children }) => {
  const toaster = useSimpleToaster();
  const [state, dispatch]: [nHotel.tState, React.Dispatch<nHotel.tAction>] = useReducer(
    reducer,
    initialState,
    (initialState) => {
      const data = localStorage.getItem(LS_DATA);
      return {
        ...initialState,
        data: data ? { ...initialState.data, ...JSON.parse(data) } : initialState.data,
      };
    }
  );
  const { logout } = useAuthDispatch();
  const { setProfile } = useProfileDispatch();

  useEffectSafe(() => {
    async function main() {
      dispatch({ type: "pending" });

      let error: any = null;
      for (let tries = 0; tries < RUN_CONTEXT.HOTEL.max_tries; tries++) {
        try {
          const result: AxiosResponse<{
            properties: (tProperty & { ipValidated: boolean })[];
            manager: tManager;
          }> = await axios.get(
            constructApiAddress(`/managers/properties`, USE_SERVER.managersMicroservice.local)
          );
          const {
            data: { properties, manager },
          } = result;
          setProfile(manager || defaultManager);
          dispatch({ type: "set hotels", hotels: properties || [] });
          return;
        } catch (err: any) {
          error = err;
          if (_has(err, "response.status")) {
            if (err.response.status === 403) {
              logout();
              break;
            } else if (err.response.status === 404) {
              if (err.response.data.error === "TOKEN_NOT_FOUND") {
                logout();
                break;
              }
            }
          }
          await sleep(3000);
        }
      }
      if (error) dispatch({ type: "rejected", error: getErrorMessage(error) });
    }

    if (
      window.location.pathname.includes("setup-password") ||
      window.location.pathname.includes("forgot-password")
    )
      logout();
    else {
      main();
    }
  }, [logout, toaster]);

  console.log("HOTEL CONTEXT PROVIDER");

  const hotel = useMemo(() => {
    try {
      const found = state.data.hotels?.find((h) => h._id === state.data.activeProperty);
      return JSON.parse(JSON.stringify({ ...defaultHotel, ...(found || state.data.hotels[0]) }));
    } catch (err) {
      return JSON.parse(JSON.stringify(defaultHotel));
    }
  }, [state.data.hotels, state.data.activeProperty]);

  const activeSubscription = useMemo(() => {
    return (
      _find<tHotel["subscriptions"][0]>(hotel.subscriptions, (s) => s.active) ||
      ({
        type: "starter",
        active: true,
        timestamp: new Date(),
        startAt: new Date(),
      } as tHotel["subscriptions"][0])
    );
  }, [hotel]);

  return (
    <HotelStateContext.Provider value={{ state, activeProperty: hotel, activeSubscription }}>
      <HotelDispatchContext.Provider value={dispatch}>{children}</HotelDispatchContext.Provider>
    </HotelStateContext.Provider>
  );
};

export { HotelContextProvider, HotelDispatchContext, HotelStateContext };
