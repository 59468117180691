import { at, sum } from "lodash";
import React, { useContext } from "react";
import { ReactComponent as EcoIcon } from "../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import BasicCard from "../../../components/Cards/BasicCard";
import Flex from "../../../components/Flex";
import { iIconBgProps } from "../../../components/Icons/IconBg";
import PageSection from "../../../components/PageSection";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../components/Text";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../../hooks/apiRequests/useGetMeasures";
import nUseGetMeasures from "../../../hooks/apiRequests/useGetMeasures/interfaces";
import { tMeasure } from "../../../models/measures";
import { tReservation, tStay } from "../../../models/reservation";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits/";
import { nUnitConverter } from "../../../utils/convertUnits/interfaces";
import { hotelGetMeasuresTracked } from "../../../utils/hotels/measures";
import { _round } from "../../../utils/lodash-utils";

type tCardInfo = {
  key: string;
  measuresObjectPath: "co2" | "grouped.te.measurements" | "grouped.tw.measurements";
  label: string | iTextProps;
  icon: iIconBgProps;
  converter: nUnitConverter.tFunction;
};

interface iAverageConsumptionWrapped2Props {
  measuresRequest: nUseGetMeasures.tFunctionReturn<[]>;
  reservation: tStay;
  cardsInfo: tCardInfo[];
}

const AverageConsumptionWrapped2: React.FC<iAverageConsumptionWrapped2Props> = ({
  measuresRequest,
  reservation,
  cardsInfo,
}) => {
  return (
    <>
      <Flex row gap={16}>
        {cardsInfo.map(({ key, label, icon, measuresObjectPath, converter }) => {
          const value = at(measuresRequest.data, measuresObjectPath)[0] as number[];
          const converted = converter(sum(value));
          return (
            <BasicCard
              key={key}
              label={label}
              icon={icon}
              {...{
                ...(measuresRequest.isLoading
                  ? {
                      value: "",
                      valueLoading: true,
                    }
                  : {
                      value: _round(converted.value, 2),
                      valueUnit: converted.unitInHTML,
                      valueProps: {
                        asHTML: true,
                      },
                    }),
              }}
            />
          );
        })}
      </Flex>
      {measuresRequest.isLoading ? <SkeletonText /> : <div></div>}
    </>
  );
};

interface iProps extends iAverageConsumptionProps {}

const AverageConsumptionWrapped1: React.FC<iProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const { room } = reservation;
  const tracked = hotelGetMeasuresTracked(activeProperty, room);

  const trackedMeasures: tMeasure[] = [];

  const cardsInfo: tCardInfo[] = [
    {
      key: "co2",
      measuresObjectPath: "co2",
      label: { text: trans("general.co_2_emissions"), asHTML: true },
      icon: { Element: EcoIcon, fill: COLORS.emissions },
      converter: convertMassUnit,
    },
  ];

  trackedMeasures.push(...(Object.keys(tracked) as tMeasure[]).filter((key) => tracked[key]));

  if (tracked.te) {
    cardsInfo.push({
      key: "te",
      measuresObjectPath: "grouped.te.measurements",
      label: trans("general.measures_.electricity"),
      icon: {
        Element: ElectricBoltIcon,
        fill: COLORS.energy,
        backgroundTrasparency: 20,
      },
      converter: convertEnergyUnit,
    });
  }

  if (tracked.tw) {
    cardsInfo.push({
      key: "tw",
      measuresObjectPath: "grouped.tw.measurements",
      label: trans("general.measures_.water"),
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      converter: convertVolumeUnit,
    });
  }

  // const [cardsInfo, trackedMeasures] = useMemo(() => {
  //   const tracked = getMeasuresTracked(room);

  //   const trackedMeasures: tMeasure[] = [];

  //   const cardsInfo: tCardInfo[] = [
  //     {
  //       key: "co2",
  //       measuresObjectPath: "co2",
  //       label: { text: trans("general.co_2_emissions"), asHTML: true },
  //       icon: { Element: EcoIcon, fill: COLORS.emissions },
  //       converter: convertMassUnit,
  //     },
  //   ];

  //   trackedMeasures.push(
  //     ...(Object.keys(tracked) as tMeasure[]).filter((key) => tracked[key])
  //   );

  //   if (tracked.te) {
  //     cardsInfo.push({
  //       key: "te",
  //       measuresObjectPath: "grouped.te.measurements",
  //       label: trans("general.measures_.electricity"),
  //       icon: {
  //         Element: ElectricBoltIcon,
  //         fill: COLORS.energy,
  //         backgroundTrasparency: 20,
  //       },
  //       converter: convertEnergyUnit,
  //     });
  //   }

  //   if (tracked.tw) {
  //     cardsInfo.push({
  //       key: "tw",
  //       measuresObjectPath: "grouped.tw.measurements",
  //       label: trans("general.measures_.water"),
  //       icon: { Element: WaterDropIcon, fill: COLORS.water },
  //       converter: convertVolumeUnit,
  //     });
  //   }
  //   return [cardsInfo, trackedMeasures];
  // }, [getMeasuresTracked, room, trans]);

  const measuresRequest = useGetMeasures(
    activeProperty,
    {
      measures: trackedMeasures,
      binUnit: "day",
      binValue: 1,
      from: reservation.startAt,
      to: reservation.endAt,
      spaces: [room],
    },
    { mockData: true }
  );

  if (cardsInfo.length === 1) {
    return <InterTag text={trans("No consumption being tracked. Is the space setup?")} />;
  }

  return (
    <Flex column gap={16}>
      <AverageConsumptionWrapped2 {...{ measuresRequest, reservation, cardsInfo }} />
    </Flex>
  );
};

interface iAverageConsumptionProps {
  reservation: tReservation;
}

const AverageConsumption: React.FC<iAverageConsumptionProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  return (
    <PageSection icon={QueryStatsIcon} title={trans("general.consumption")}>
      <AverageConsumptionWrapped1 {...{ reservation }} />
    </PageSection>
  );
};

export default AverageConsumption;
