import axios from "axios";
import { findIndex, floor, min } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EventLeavingIcon } from "../../../assets/icons/event_leaving.svg";
import { ReactComponent as EventUpcomingIcon } from "../../../assets/icons/event_upcoming.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as StadiaControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/target.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Flex from "../../../components/Flex";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleAwardsCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleAwardsCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tHotelChallenge } from "../../../models/hotel";
import { tHotelChallengeId } from "../../../models/hotel/challenges";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../utils/apiCall";
import { COLORS, tColor } from "../../../utils/colors";
import { propertyGroupSpaceAggregatesByCategory } from "../../../utils/hotels/spaces";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import AddChallenge from "./addChallenge";
import CreateEditChallengeModal, { tDataOnSubmit } from "./createEditChallengeModal";
import NoChallenges from "./noChallenges";

type tChallengesSplit = {
  ongoing: tHotelChallenge[];
  upcoming: tHotelChallenge[];
  history: tHotelChallenge[];
};

interface iChallengesListProps {}

const ChallengesList: React.FC<iChallengesListProps> = () => {
  const toaster = useSimpleToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const tableRef = useRef<any>();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const { hasPermission } = useContext(PermissionsContext)!;

  const getRequest = useGetRequest<tChallengesSplit>({
    ongoing: [],
    upcoming: [],
    history: [],
  });

  const [editChallengeModal, setEditChallengeModal] = useState<{
    open: boolean;
    challengeId?: tHotelChallenge["_id"];
  }>({
    open: false,
    challengeId: undefined,
  });

  const putRequest = usePutRequest();
  const deleteRequest = useDeleteRequest();

  useEffect(() => {
    const ongoing: tHotelChallenge[] = [];
    const upcoming: tHotelChallenge[] = [];
    const history: tHotelChallenge[] = [];

    activeProperty.challenges.forEach((c) => {
      const { from, to } = c;
      if (moment(from).isAfter(moment())) upcoming.push(c);
      else if (moment(to).isBefore(moment())) history.push(c);
      else ongoing.push(c);
    });
    getRequest.resolve({ ongoing, upcoming, history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProperty.challenges]);

  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);

  const isLoading = getRequest.isLoading || deleteRequest.isLoading || putRequest.isLoading;

  const onSelectTopLevelFilter = (key: string) => {
    if (!isLoading) setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key));
  };

  const deleteChallenge = (challengeId: tHotelChallengeId) => {
    deleteRequest.pending();
    axios
      .delete(
        constructApiAddress(
          `/v2/hotels/${activeProperty._id}/challenges/${challengeId}`,
          USE_MONOLITH_SERVERLESS
        )
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(activeProperty._id, hotel);
        deleteRequest.resolve();
        toaster.success(trans("pages.challenges.sections.list.notifications.challenge_removed"));
      })
      .catch((err) => {
        const {
          data: { hotel },
        } = err.response;
        const error = getErrorMessage(err, trans);
        if (hotel) {
          updateHotel(activeProperty._id, hotel);
        } else {
          toaster.error(error);
        }
        deleteRequest.reject(error);
      });
  };

  const options = (
    dataKey: string,
    challenge: tHotelChallenge
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (TOP_LEVEL_FILTERS[topLevelFilterIndex].key === "upcoming") {
      options.push({
        key: "configure",
        label: trans("general.configure"),
        icon: { Element: SettingsIcon },
        onClick() {
          setEditChallengeModal({ open: true, challengeId: challenge._id });
        },
      });
    }
    options.push({
      key: "delete",
      label: { text: trans("general.remove"), color: COLORS.error },
      icon: { Element: DeleteIcon, fill: COLORS.error },
      onClick() {
        deleteChallenge(challenge._id);
      },
    });

    return options;
  };

  const TOP_LEVEL_FILTERS: {
    key: keyof tChallengesSplit;
    label: string;
  }[] = [
    {
      key: "ongoing",
      label: "pages.challenges.sections.list.segments.ongoing",
    },
    {
      key: "upcoming",
      label: "pages.challenges.sections.list.segments.upcoming",
    },
    {
      key: "history",
      label: "pages.challenges.sections.list.segments.history",
    },
  ];

  const segmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={TOP_LEVEL_FILTERS[topLevelFilterIndex].key}
            onSelect={onSelectTopLevelFilter}
            options={TOP_LEVEL_FILTERS.map((f) => {
              const { key, label } = f;
              return {
                key,
                label: { text: trans(label) },
                count: getRequest.data[key].length,
                disabled: isLoading,
              };
            })}
          />
        </Flex>
        {hasPermission("create:challenges") && (
          <Flex
            middle
            style={{
              borderBottom: `2px ${COLORS.gray} solid`,
            }}
          >
            <AddChallenge />
          </Flex>
        )}
      </Flex>
    );
  };

  const handleEditChallenge = (challengeId: tHotelChallengeId) => (challenge: tDataOnSubmit) => {
    putRequest.pending();
    axios
      .put(
        constructApiAddress(
          `/v2/hotels/${activeProperty._id}/challenges/${challengeId}`,
          USE_MONOLITH_SERVERLESS
        ),
        {
          ...challenge,
        }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(activeProperty._id, hotel);
        putRequest.resolve();
        setEditChallengeModal({ open: false, challengeId: undefined });
        toaster.success("pages.challenges.sections.list.notifications.challenge_updated");
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.error(error);
      });
  };

  const selectedChallengesList = getRequest.data[TOP_LEVEL_FILTERS[topLevelFilterIndex].key];

  const tableHeight = isLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([5, selectedChallengesList.length]) as number) * TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  const tableWidth = tableRef.current?.body?.offsetWidth || 0;
  const typeWidth = floor(0.15 * tableWidth);
  const targetWidth = floor(0.15 * tableWidth);
  const startWidth = floor(0.15 * tableWidth);
  const endWidth = floor(0.15 * tableWidth);
  const awardsWidth = floor(0.3 * tableWidth);

  const closeEditChallengeModal = useCallback(() => {
    setEditChallengeModal({ open: false, challengeId: undefined });
  }, []);

  const spaceAggregatesByCategory = propertyGroupSpaceAggregatesByCategory(activeProperty);

  return (
    <>
      {editChallengeModal.open && editChallengeModal.challengeId !== undefined && (
        <CreateEditChallengeModal
          open={true}
          challengeId={editChallengeModal.challengeId}
          onClose={closeEditChallengeModal}
          requestError={putRequest.error}
          requestStatus={putRequest.status}
          onRequest={handleEditChallenge(editChallengeModal.challengeId)}
        />
      )}
      <Flex column gap={16}>
        {segmentation()}
        <div className="table-wrapper">
          <Table
            ref={tableRef}
            height={tableHeight}
            data={selectedChallengesList}
            id={"CHALLENGES.LIST"}
            rowHeight={TABLE_ROW_HEIGHT.M}
            headerHeight={TABLE_HEADER_HEIGHT}
            loading={isLoading}
          >
            <Table.Column width={typeWidth}>
              <SimpleHeaderCell
                icon={{ Element: StadiaControllerIcon }}
                name={trans("general.challenge")}
              />
              <SimpleKeyValueCell dataKey="type" />
            </Table.Column>
            <Table.Column fullText width={targetWidth}>
              <SimpleHeaderCell icon={{ Element: TargetIcon }} name={trans("general.target")} />
              <BaseCell>
                {(challenge: tHotelChallenge) => {
                  const spaces = activeProperty.spaces.filter((s) =>
                    challenge.target.spaces.includes(s._id)
                  );
                  const spaceGroups = spaceAggregatesByCategory["group"].filter((s) =>
                    challenge.target.spaceAggregates.includes(s._id)
                  );
                  const spaceZones = spaceAggregatesByCategory["zone"].filter((s) =>
                    challenge.target.spaceAggregates.includes(s._id)
                  );
                  const spaceTypes = spaceAggregatesByCategory["type"].filter((s) =>
                    challenge.target.spaceAggregates.includes(s._id)
                  );

                  const jsx = (color: tColor, style: React.CSSProperties = {}) => (
                    <Flex column gap={4} style={style}>
                      {[
                        {
                          label: "general.spaces",
                          list: spaces,
                          link: "spaces",
                        },
                        {
                          label: "general.space_categories_.extended.groups",
                          list: spaceGroups,
                          link: "space-groups",
                        },
                        {
                          label: "general.space_categories_.extended.zones",
                          list: spaceZones,
                          link: "space-zones",
                        },
                        {
                          label: "general.space_categories_.extended.types",
                          list: spaceTypes,
                          link: "space-types",
                        },
                      ]
                        .filter(({ list }) => list.length)
                        .map(({ label, list, link }) => (
                          <Flex key={label} row gap={4}>
                            <InterTag text={`${trans(label)}:`} size={12} color={color} />
                            <Flex>
                              {list
                                .flatMap((elem) => [
                                  <InterTag
                                    key={elem._id}
                                    asSpan
                                    hoverUnderline
                                    size={12}
                                    text={elem.name}
                                    color={color}
                                    onClick={() => navigate(`/${link}/${elem._id}`)}
                                  />,
                                  <InterTag
                                    key={`${elem._id} separator`}
                                    asSpan
                                    size={12}
                                    text=",  "
                                    color={color}
                                  />,
                                ])
                                .slice(0, list.length * 2 - 1)}
                            </Flex>
                          </Flex>
                        ))}
                    </Flex>
                  );

                  return jsx(COLORS.secondary);
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column width={startWidth}>
              <SimpleHeaderCell
                icon={{ Element: EventUpcomingIcon }}
                name={trans("general.from_date")}
              />
              <SimpleDateCell dataKey="from" />
            </Table.Column>
            <Table.Column width={endWidth}>
              <SimpleHeaderCell
                icon={{ Element: EventLeavingIcon }}
                name={trans("general.to_date")}
              />
              <SimpleDateCell dataKey="to" />
            </Table.Column>
            <Table.Column width={awardsWidth}>
              <SimpleHeaderCell icon={{ Element: VerifiedIcon }} name={trans("general.awards")} />
              <SimpleAwardsCell dataKey="awards" />
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <SimpleHeaderCell />
              <SimpleActionCell options={options} dataKey="_id" />
            </Table.Column>
          </Table>
          {selectedChallengesList.length === 0 && (
            <Flex column middle gap={40}>
              <NoChallenges label={TOP_LEVEL_FILTERS[topLevelFilterIndex].label}></NoChallenges>
              {hasPermission("create:challenges") && <AddChallenge />}
            </Flex>
          )}
        </div>
      </Flex>
    </>
  );
};

export default ChallengesList;
