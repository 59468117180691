import React, { useState } from "react";
import { InlineEdit, Loader } from "rsuite";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";

interface iProps {
  label: string;
  isLoading?: boolean;
  initValue: string;
  onSave: (value: string) => any;
  lastInPanel?: boolean;
}

const TextInputRow: React.FC<iProps> = ({ label, isLoading, initValue, onSave, lastInPanel }) => {
  const { trans } = useLocalizationState();
  const [input, setInput] = useState(initValue);

  return (
    <div
      className={[
        "flex flex-row justify-between items-center h-12 pl-4",
        lastInPanel ? "" : "border-b border-gray-200",
      ].join(" ")}
    >
      <p className="text-sm font-bold text-secondary inter">{trans(label)}</p>
      {isLoading ? (
        <Loader size="xs" />
      ) : (
        <InlineEdit
          className="inter"
          value={input}
          onChange={setInput}
          onCancel={() => setInput(initValue)}
          onSave={(event: any) => {
            if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
              if (input !== initValue) onSave(input);
              return null;
            }
            if (onSave(input)) setInput(initValue);
          }}
          disabled={isLoading}
        />
      )}
    </div>
  );
};

export default TextInputRow;
