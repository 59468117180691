import React, { useContext, useEffect } from "react";
import HistoricDataConsumptionGraphSection from "../../../../components/HistoricDataConsumptionGraphSection";
import { HotelStateContext } from "../../../../context/Hotel";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { hotelGetMeasuresTrackedInManualData } from "../../../../utils/hotels/manualData";
import { ConsumptionContext } from "../../consumption/context";
import Showcasing from "./showcasing";

const HistoricData: React.FC = () => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { activeProperty } = useContext(HotelStateContext)!;
  const measuresTrackedInManualData = hotelGetMeasuresTrackedInManualData(activeProperty);

  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.water,
    initialStatus: measuresTrackedInManualData.water ? REQUEST_STATUS.PENDING : REQUEST_STATUS.IDLE,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <div className="flex flex-col gap-10">
      <HistoricDataConsumptionGraphSection {...{ conversionFactorsRequest, measure: "water" }} />
      <Showcasing />
    </div>
  );
};

export default HistoricData;
