import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React from "react";
import { ReactComponent as MicrosoftIcon } from "../../../../assets/icons/microsoft.svg";
import { ReactComponent as MicrosoftMonoIcon } from "../../../../assets/icons/microsoft_mono.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icons/Icon";
import SimpleColoredButton from "../../../../components/RsuiteWrapper/SimpleColoredButton";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import usePostRequest from "../../../../hooks/apiRequests/usePostRequest";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../../utils/apiCall";
import { loginRequest } from "../../../../utils/azure/authconfig";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import styles from "../styles.module.css";

const buildTransKey = (key: string) => `pages.settings.panels.connected_accounts.${key}`;

interface iProps {}

const ConnectedAccountsSettings: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const postRequest = usePostRequest();
  const { profile } = useProfileState();
  const { updateProfile } = useProfileDispatch();

  const { instance } = useMsal();

  const handleAzureConnect = () => {
    postRequest.pending();
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        axios
          .post(
            constructApiAddress(
              "/v2/managers/connected-accounts/azure/connect",
              USE_MONOLITH_SERVERLESS
            ),
            {
              ...res,
            }
          )
          .then((res) => {
            const {
              data: { account },
            } = res;

            updateProfile(account);

            postRequest.resolve({}, trans(buildTransKey("items.microsoft.connected")));
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            postRequest.reject(error, true);
          });
      })
      .catch((e) => {
        console.error(e);
        postRequest.reject("");
      });
  };

  const handleAzureDisconnect = () => {
    postRequest.pending();
    axios
      .post(
        constructApiAddress(
          "/v2/managers/connected-accounts/azure/disconnect",
          USE_MONOLITH_SERVERLESS
        )
      )
      .then((res) => {
        const {
          data: { account },
        } = res;

        updateProfile(account);

        postRequest.resolve({}, trans(buildTransKey("items.microsoft.disconnected")));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  return (
    <div>
      <Flex
        row
        between
        middle
        className={[styles["settings-item"], styles["settings-button-item"]].join(" ")}
      >
        <Flex row gap={4} middle>
          <Icon Element={MicrosoftIcon} size={32} />
          <InterTag
            size={14}
            text={trans(
              profile.azure?.endpoints?.common?.uniqueId
                ? buildTransKey("items.is_connected")
                : "general.microsoft"
            )}
            bold
            color={COLORS.secondary}
          />
        </Flex>
        <div>
          {profile.azure?.endpoints?.common?.uniqueId ? (
            <SimpleColoredButton
              loading={postRequest.isLoading}
              onClick={() => {
                handleAzureDisconnect();
              }}
              color={COLORS.water}
              iconPosition="left"
              icon={{
                Element: MicrosoftMonoIcon,
                size: 24,
                fill: COLORS.white,
              }}
              text={{
                text: trans(buildTransKey("items.disconnect")),
                size: 16,
                color: COLORS.white,
              }}
            />
          ) : (
            <SimpleColoredButton
              loading={postRequest.isLoading}
              onClick={() => {
                handleAzureConnect();
              }}
              color={COLORS.water}
              iconPosition="left"
              icon={{
                Element: MicrosoftMonoIcon,
                size: 24,
                fill: COLORS.white,
              }}
              text={{
                text: trans(buildTransKey("items.microsoft.connect")),
                size: 16,
                color: COLORS.white,
              }}
            />
          )}
        </div>
      </Flex>
    </div>
  );
};

export default ConnectedAccountsSettings;
