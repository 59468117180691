import { floor, max, min } from "lodash";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { Loader, Table } from "rsuite";
import { ReactComponent as DateRangeIcon } from "../../../../assets/icons/date_range.svg";
import { ReactComponent as EventRepeatIcon } from "../../../../assets/icons/event_repeat.svg";
import { ReactComponent as PaymentsIcon } from "../../../../assets/icons/payments.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import BaseCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleKeyValueCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../../../hooks/useScreenSize";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import { calcNumberOfNights } from "../../../../utils/dates";
import { hotelGetManualDataOrderedWithoutZeroValues } from "../../../../utils/hotels/manualData";
import { _find, _round } from "../../../../utils/lodash-utils";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../../utils/tables";

type tTableItem = tHotelManualData & { index: number; homologueId: string };

interface iShowcasingWrapped2Props {
  outletContainerWidth: number;
  tableData: tTableItem[];
}

const ShowcasingWrapped2: React.FC<iShowcasingWrapped2Props> = ({
  outletContainerWidth,
  tableData,
}) => {
  const { trans } = useLocalizationState();

  const options = (dataKey: string, item: tTableItem): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];
    return options;
  };

  const tableHeight =
    (min([12, tableData.length]) as number) * TABLE_ROW_HEIGHT.SM + TABLE_HEADER_HEIGHT;

  const width = max([outletContainerWidth, 700]) as number;

  const periodWidth = floor(0.3 * width);
  const waterWidth = floor(0.15 * width);
  const costsWidth = floor(0.15 * width);
  const lastPeriodWidth = floor(0.15 * width);
  const homologueWidth = floor(0.15 * width);

  return (
    <Flex column>
      <div className="table-wrapper">
        <Table
          id="TABLE.WATER.SHOWCASING"
          data={tableData}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.SM}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell name={trans("general.period")} icon={DateRangeIcon} />
            <SimpleKeyValueCell
              dataKey="period"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [
                    moment(from).format("DD MMM YYYY"),
                    moment(to).format("DD MMM YYYY"),
                  ],
                })
              }
            />
          </Table.Column>
          <Table.Column width={waterWidth}>
            <SimpleHeaderCell name={trans("general.measures_.water")} icon={WaterDropIcon} />
            <SimpleKeyValueCell
              textProps={() => ({ asHTML: true })}
              textFunction={(item: tTableItem) => {
                if (!item.water) return "-";
                return `${item.water.totalM3} m<sup>3</sup>`;
              }}
              descriptionProps={() => ({ asHTML: true })}
              descriptionFunction={(item: tTableItem) => {
                if (!item.water) return null;
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${_round(item.water.totalM3 / numberOfNights, 2)} m<sup>3</sup>/${trans(
                  "general.day"
                )}`;
              }}
              dataKey="water"
            />
          </Table.Column>
          <Table.Column width={costsWidth}>
            <SimpleHeaderCell name={trans("general.costs")} icon={PaymentsIcon} />
            <SimpleKeyValueCell
              textProps={() => ({ asHTML: true })}
              textFunction={(item: tTableItem) => {
                if (!item.water) return "-";
                return `${item.water.price} €`;
              }}
              descriptionFunction={(item: tTableItem) => {
                if (!item.water) return null;
                const numberOfNights = calcNumberOfNights(item.from, item.to);
                return `${_round(item.water.price / numberOfNights, 2)} €/${trans("general.day")}`;
              }}
              dataKey="price"
            />
          </Table.Column>
          <Table.Column width={lastPeriodWidth}>
            <SimpleHeaderCell name={trans("general.period_previous")} icon={EventRepeatIcon} />
            <BaseCell dataKey="lastPeriod">
              {(item: tTableItem) => {
                const { index } = item;
                if (!item.water) return <InterTag text="-" size={12} color={COLORS.gray} />;

                if (index + 1 === tableData.length)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                const entry = tableData[index + 1];

                if (!entry || !entry.water)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={item.water.totalM3}
                    baseValue={entry.water.totalM3}
                    size={12}
                    description={trans("components.trending_comparison.last_period_tooltip")}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={homologueWidth}>
            <SimpleHeaderCell name={trans("general.period_homologue")} icon={EventRepeatIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                if (!item.water) return <InterTag text="-" size={12} color={COLORS.gray} />;

                const homologueId = moment(item.from).subtract(1, "year").format("YYYY/MM");
                const homologue = _find(tableData, (t) => t.homologueId === homologueId);

                if (!homologue || !homologue.water)
                  return <InterTag text="-" size={12} color={COLORS.gray} />;

                return (
                  <TrendingComparison
                    value={item.water.totalM3}
                    baseValue={homologue.water.totalM3}
                    size={12}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Flex>
  );
};

interface iShowcasingWrapped1Props {
  outletContainerWidth: number;
}

const ShowcasingWrapped1: React.FC<iShowcasingWrapped1Props> = ({ outletContainerWidth }) => {
  const { activeProperty } = useContext(HotelStateContext)!;

  const tableData: tTableItem[] = useMemo(() => {
    const items = hotelGetManualDataOrderedWithoutZeroValues(activeProperty, ["water"], "desc");
    return items.map((item, index) => ({
      ...item,
      homologueId: moment(item.from).format("YYYY/MM"),
      index,
    }));
  }, [activeProperty]);

  return <ShowcasingWrapped2 {...{ outletContainerWidth, tableData }} />;
};

interface iProps {}

const Showcasing: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.water.archive.sections.showcase.title")}
        description={trans("pages.water.archive.sections.showcase.description")}
        icon={{ Element: WaterDropIcon, fill: COLORS.water }}
      />
      {!outlet ? (
        <Flex>
          <Loader size="lg" />
        </Flex>
      ) : (
        <ShowcasingWrapped1 outletContainerWidth={outlet.width} />
      )}
    </Flex>
  );
};

export default React.memo(Showcasing);
