import moment, { MomentInput } from "moment";
import React from "react";
import { COLORS } from "../../../utils/colors";
import ApexChart, { iApexChartProps } from "./Chart";

export interface iColumnChartProps {
  id: string;
  series: iApexChartProps["series"];
  categories: string[];
  xAxisDateFormat?: string;
  tooltipDateFormat?: string | ((date: MomentInput) => string);
  tooltipValueFormatter?: (val: number) => string;
  yAxisTitle?: string;
}

export const APEX_BASE_TOOLTIP_OPTIONS = (
  categories: string[],
  tooltipDateFormat?: string | ((date: MomentInput) => string),
  tooltipValueFormatter?: (val: number) => string
) => ({
  enabled: true,
  followCursor: false,
  x: {
    formatter(val: any, opts: any) {
      const date = categories[opts.dataPointIndex];
      if (typeof tooltipDateFormat) {
        return typeof tooltipDateFormat === "function"
          ? tooltipDateFormat(date)
          : moment(date).format(tooltipDateFormat);
      }
      return date;
    },
  },
  y: {
    formatter: tooltipValueFormatter,
  },
});

export const APEX_BASE_YAXIS_TITLE_OPTIONS = (title: string) => ({
  text: title,
});

const ColumnChart: React.FC<iColumnChartProps> = ({
  id,
  series,
  categories,
  xAxisDateFormat = "DD/MM",
  tooltipDateFormat = "DD/MM/YYYY HH:mm",
  tooltipValueFormatter,
  yAxisTitle,
}) => {
  return (
    <ApexChart
      type="bar"
      series={series}
      chart={{ type: "bar", stacked: true }}
      {...{
        id,
        xaxis: {
          categories,
          type: "category",
          tickAmount: 5,
          labels: {
            rotate: 0,
            show: true,
            formatter: (date: MomentInput) => moment(date).format(xAxisDateFormat),
            style: {
              colors: COLORS.secondary,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: COLORS.secondary,
            },
          },
          title: yAxisTitle ? APEX_BASE_YAXIS_TITLE_OPTIONS(yAxisTitle) : undefined,
        },
        grid: {
          borderColor: COLORS.secondary,
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: APEX_BASE_TOOLTIP_OPTIONS(categories, tooltipDateFormat, tooltipValueFormatter),
      }}
    />
  );
};

export default ColumnChart;
