import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import RoadmapTable from "../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { DataTypeStateContext } from "../../../context/DataType";
import { HotelStateContext } from "../../../context/Hotel";
import { TimeframeDispatchContext, TimeframeStateContext } from "../../../context/Timeframe";
import WithContextProvider from "../../../hoc/withProvider";
import { propertyActiveSubscriptionTypeIsEnterprise } from "../../../utils/hotels/subscriptions";
import ConsumptionContextProvider from "../consumption/context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const WaterGuarded: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;

  const dataTypeContext = useContext(DataTypeStateContext)!;
  const {
    data: { sensorDataTimeframe, manualDataIndex },
  } = useContext(TimeframeStateContext)!;
  const { setSensorDataTimeframe, setManualDataIndex } = useContext(TimeframeDispatchContext)!;

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 items-center">
            <DataTypeToggle />
            {dataTypeContext.dataTypeIsLive ? (
              <SimpleDateRangePicker
                value={sensorDataTimeframe}
                onChange={setSensorDataTimeframe}
                style={{ paddingLeft: "8px" }}
              />
            ) : (
              <SimpleDateRangePickerForManualDataPeriods2
                {...{ manualDataIndex, setManualDataIndex }}
              />
            )}
          </div>
        </div>
      </SecondaryTopBar>
      <div className="flex flex-col gap-10">
        {!propertyActiveSubscriptionTypeIsEnterprise({ property: activeProperty }) ||
        !dataTypeContext.dataTypeIsLive ? (
          <HistoricData />
        ) : (
          <LiveData />
        )}
        <RoadmapTable filterByType="water" />
        <div />
      </div>
    </div>
  );
};

const Water: React.FC = () => {
  return (
    <IpValidatorGuard>
      <WaterGuarded />
    </IpValidatorGuard>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Water);
