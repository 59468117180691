import React, { useContext, useState } from "react";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import AddManualDataButton from "../../../components/Buttons/addManualDataButton";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSection from "../../../components/PageSection";
import RoadmapTable from "../../../components/RoadmapTable";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../utils/hotels/subscriptions";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const WasteWrapped2: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={40}>
      <Flex gap={20} column>
        <PageSection
          title={trans("pages.waste.archive.sections.overview.title")}
          icon={QueryStatsIcon}
          description={trans("pages.waste.archive.sections.overview.description")}
        />
        <Graph />
      </Flex>
      <List />
      <RoadmapTable filterByType="waste" />
    </Flex>
  );
};

const WasteWrapped: React.FC = () => {
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const { hasPermission } = useContext(PermissionsContext)!;

  return (
    <div>
      {hasPermission("update:manual-data") && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="waste"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <SecondaryTopBar>
        <div>
          {hasPermission("create:manual-data") && (
            <AddManualDataButton onClick={() => setManualDataModal({ open: true })} />
          )}
        </div>
      </SecondaryTopBar>
      <WasteWrapped2 />
    </div>
  );
};

const WasteGuarded: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  if (activeSubscriptionIsStarter) return <Unlock />;

  return <WasteWrapped />;
};

const Waste: React.FC = () => {
  return (
    <IpValidatorGuard>
      <WasteGuarded />
      <PageBottomPadding />
    </IpValidatorGuard>
  );
};

export default Waste;
