import React, { lazy, Suspense } from "react";

const RegisterWrapped = lazy(() => import("./register"));

interface iProps {}

const Register: React.FC<iProps> = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RegisterWrapped />
    </Suspense>
  );
};

export default Register;
