import moment, { MomentInput } from "moment";
import React, { useCallback, useEffect, useReducer } from "react";
import { nTimeframe } from "./interfaces";

const LS_TIMEFRAME_KEY = "__tfr__";

const TimeframeDispatchContext = React.createContext<nTimeframe.tDispatchContext | undefined>(
  undefined
);
TimeframeDispatchContext.displayName = "TimeframeDispatchContext";
const TimeframeStateContext = React.createContext<nTimeframe.tStateContext | undefined>(undefined);
TimeframeStateContext.displayName = "TimeframeStateContext";

const initialState: nTimeframe.tState = {
  data: {
    sensorDataTimeframe: [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    manualDataIndex: 0,
  },
  status: "pending",
};

const reducer = (state: nTimeframe.tState, action: nTimeframe.tAction): nTimeframe.tState => {
  switch (action.type) {
    case "set timeframe": {
      const { timeframe } = action;
      localStorage.setItem(
        LS_TIMEFRAME_KEY,
        JSON.stringify({ ...state.data, sensorDataTimeframe: timeframe })
      );
      return {
        ...state,
        data: { ...state.data, sensorDataTimeframe: timeframe },
        status: "resolved",
      };
    }
    case "set manual data index": {
      const { index } = action;
      localStorage.setItem(
        LS_TIMEFRAME_KEY,
        JSON.stringify({ ...state.data, manualDataIndex: index })
      );
      return {
        ...state,
        data: { ...state.data, manualDataIndex: index },
        status: "resolved",
      };
    }
    case "set data 1st time": {
      const { data } = action;
      return { ...state, data, status: "resolved" };
    }
    default:
      return { ...state };
  }
};

const TimeframeContextProvider: React.FC<nTimeframe.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nTimeframe.tState, React.Dispatch<nTimeframe.tAction>] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    const timeframe = localStorage.getItem(LS_TIMEFRAME_KEY);
    if (timeframe) {
      const state = JSON.parse(timeframe);
      dispatch({
        type: "set data 1st time",
        data: {
          ...state,
          sensorDataTimeframe: [
            moment(state.sensorDataTimeframe[0]).toDate(),
            moment.min(moment(), moment(state.sensorDataTimeframe[1])).toDate(),
          ],
        },
      });
    } else {
      dispatch({ type: "set data 1st time", data: initialState.data });
    }
  }, []);

  const setSensorDataTimeframe = useCallback((timeframe: [MomentInput, MomentInput]) => {
    dispatch({ type: "set timeframe", timeframe });
  }, []);
  const setManualDataIndex = useCallback((index: number) => {
    dispatch({ type: "set manual data index", index });
  }, []);

  return (
    <TimeframeStateContext.Provider value={state}>
      <TimeframeDispatchContext.Provider value={{ setSensorDataTimeframe, setManualDataIndex }}>
        {children}
      </TimeframeDispatchContext.Provider>
    </TimeframeStateContext.Provider>
  );
};

export { TimeframeContextProvider, TimeframeDispatchContext, TimeframeStateContext };
