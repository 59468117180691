import { ApexOptions } from "apexcharts";
import { forwardRef } from "react";
import ReactApexChart from "react-apexcharts";
import { _isArray } from "../../../../utils/lodash-utils";

export interface iApexChartProps {
  id?: string;
  height?: number | string;
  width?: number | string;
  series: ApexOptions["series"];
  colors?: string[];
  categories?: any[];
  xaxis?: ApexOptions["xaxis"];
  yaxis?: ApexOptions["yaxis"];
  legend?: ApexOptions["legend"];
  grid?: ApexOptions["grid"];
  stroke?: ApexOptions["stroke"];
  tooltip?: ApexOptions["tooltip"];
  chart?: ApexOptions["chart"];
  dataLabels?: ApexOptions["dataLabels"];
  markers?: ApexOptions["markers"];
  plotOptions?: ApexOptions["plotOptions"];
  title?: ApexOptions["title"];
  type?: ApexChart["type"];
}

const ApexChart = forwardRef<ReactApexChart, iApexChartProps>(
  (
    {
      id,
      type,
      height = 288,
      width = "100%",
      series,
      colors,
      categories,
      xaxis: _xaxis = {},
      yaxis: _yaxis = {},
      legend: _legend = {},
      grid: _grid = {},
      stroke: _stroke = {},
      tooltip: _tooltip = {},
      chart: _chart = {},
      dataLabels: _dataLabels = {},
      markers: _markers = {},
      plotOptions: _plotOptions = {},
      title: _title = {},
    },
    ref
  ) => {
    const xaxis: ApexOptions["xaxis"] = {
      categories,

      crosshairs: { show: false },
      axisBorder: { show: false /* , color: LABEL_COLOR */ },
      axisTicks: {
        show: false,
        // color: ["#00FF00"],
      },
      ..._xaxis,
      tooltip: { enabled: false, ..._xaxis.tooltip },
      labels: {
        show: true,
        // style: { colors: "#333E48" },
        style: { colors: "#BDBDBD" },
        formatter(value, timestamp, opts) {
          return `${value}`;
        },
        ..._xaxis.labels,
      },
      // labels: { show: false, style: { colors: "#333E48" } },
    };

    const yaxis: ApexOptions["yaxis"] = [
      {
        crosshairs: { show: false },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
          // color: LABEL_COLOR,
        },
        labels: {
          style: { colors: "#BDBDBD" },
          show: true,
        },
        title: {
          style: {
            // color: LABEL_COLOR,
          },
        },
        // logarithmic: true,
        // forceNiceScale: true,
      },
    ];
    if (_yaxis) {
      if (_isArray(_yaxis)) {
        // yaxis.push(..._yaxis);
        yaxis.splice(0, 1, ..._yaxis);
      } else
        yaxis[0] = {
          ...yaxis[0],

          ..._yaxis,
          labels: {
            style: { colors: "#BDBDBD" },
            show: true,
            ..._yaxis.labels,
          },
        };
    }

    const legend: ApexOptions["legend"] = {
      show: false,
      // showForSingleSeries: false,
      // showForNullSeries: true,
      // showForZeroSeries: true,
      // position: "bottom",
      // horizontalAlign: "center",
      // floating: false,
      // fontSize: "14px",
      // fontFamily: "Inter",
      // fontWeight: 400,
      // formatter: undefined,
      // inverseOrder: false,
      // width: undefined,
      // height: undefined,
      // tooltipHoverFormatter: undefined,
      // customLegendItems: [],
      // offsetX: 0,
      // offsetY: 0,
      // labels: {
      //   colors: undefined,
      //   useSeriesColors: false,
      // },
      // markers: {
      //   width: 10,
      //   height: 5,
      //   strokeWidth: 0,
      //   strokeColor: "#ff0000",
      //   fillColors: undefined,
      //   radius: 0,
      //   customHTML: undefined,
      //   onClick: undefined,
      //   offsetX: 0,
      //   offsetY: 0,
      // },
      // itemMargin: {
      //   horizontal: 5,
      //   vertical: 0,
      // },
      // onItemClick: {
      //   toggleDataSeries: false,
      // },
      // onItemHover: {
      //   highlightDataSeries: true,
      // },
      ..._legend,
    };

    const grid: ApexOptions["grid"] = {
      // show: false,
      borderColor: "#F0F0F0",
      // strokeDashArray: 0,
      // position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      // row: {
      //   colors: undefined,
      //   opacity: 0.5,
      // },
      // column: {
      //   colors: undefined,
      //   opacity: 0.5,
      // },
      // padding: {
      //   top: 0,
      //   right: 0,
      //   bottom: 0,
      //   left: 0,
      // },
      ..._grid,
    };

    const tooltip: ApexOptions["tooltip"] = {
      // enabledOnSeries: undefined,
      // shared: true,
      // followCursor: false,
      // intersect: false,
      // inverseOrder: false,
      // custom: undefined,
      // fillSeriesColor: true,
      // style: {
      //   fontSize: "15px",
      //   fontFamily: undefined,
      // },
      // onDatasetHover: {
      //   highlightDataSeries: true,
      // },
      // x: {
      //   show: false,
      //   // format: "dd MMM",
      //   formatter: (v) => {
      //     return `${v}`;
      //   },
      // },
      // y: {
      //   formatter: (val, opts) => {
      //     return `${val}`;
      //     // const roundedValue = _round(val, 1);
      //     // return `${
      //     //   unitConverter
      //     //     ? unitConverter(roundedValue)
      //     //     : defaultUnit
      //     //     ? `${roundedValue} ${defaultUnit}`
      //     //     : roundedValue
      //     // }`;
      //   },
      // },
      // z: {
      //   formatter: undefined,
      //   title: "Size: ",
      // },
      // marker: {
      //   show: true,
      // },
      // items: {
      //   display: "flex",
      // },
      // fixed: {
      //   enabled: false,
      //   position: "topRight",
      //   offsetX: 0,
      //   offsetY: 0,
      // },
      ..._tooltip,
    };

    const chart: ApexOptions["chart"] = {
      // sparkline: {
      //   enabled: true,
      // },
      // parentHeightOffset: 0,
      // offsetX: -9,
      // offsetY: -9,
      stacked: false,
      toolbar: {
        show: false,
        // offsetX: 0,
        // offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: true,
          pan: false,
          reset: false,
          customIcons: [],
        },

        // autoSelected: "zoom",
      },
      ..._chart,
    };

    const dataLabels: ApexOptions["dataLabels"] = {
      enabled: false,
      ..._dataLabels,
    };

    const markers: ApexOptions["markers"] = {
      // size: 2,
      // colors: colorsSet,
      // fillOpacity: 1,
      // strokeColors: colorsSet,
      // strokeWidth: 0,
      // strokeDashArray: 1,
      ..._markers,
    };

    const plotOptions: ApexOptions["plotOptions"] = { ..._plotOptions };

    const stroke: ApexOptions["stroke"] = {
      ...(type === "line" ? { curve: "straight" } : {}),
      width: 2,
      ..._stroke,
    };

    const title: ApexOptions["title"] = { ..._title };

    return (
      <ReactApexChart
        ref={ref}
        width={width}
        height={height}
        type={type}
        series={series}
        id={id}
        options={{
          plotOptions,
          markers,
          chart,
          dataLabels,
          colors,
          stroke,
          xaxis,
          yaxis,
          tooltip,
          legend,
          grid,
          title,
        }}
      />
    );
  }
);

export default ApexChart;
