import React from "react";
import ReactApexChart from "react-apexcharts";
import { COLORS } from "../../../utils/colors";
import { _round, _sum } from "../../../utils/lodash-utils";
import { iApexChartProps } from "./Chart";
import { numberFormatter } from "../../../utils/numbers";

interface iProps {
  type: Extract<iApexChartProps["type"], "pie" | "donut">;
  labels: string[];
  series: number[];
  legend?: iApexChartProps["legend"];
  color?: string;
  valueFormatter?: (value: number) => any;
}

const PieDonutChart: React.FC<iProps> = ({
  type,
  labels,
  series,
  legend,
  color,
  valueFormatter,
}) => {
  return (
    <ReactApexChart
      {...{
        type,
        series,
        options: {
          theme: {
            monochrome: {
              enabled: true,
              color: color || COLORS.primary_500,
              shadeIntensity: 1,
            },
          },
          chart: {
            type: "donut",
          },
          tooltip: {
            enabled: true,
            y: {
              formatter(val) {
                return valueFormatter ? valueFormatter(val) : val;
              },
            },
          },
          labels,
          responsive: [
            {
              breakpoint: 10000,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
          ],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: "right",
            fontFamily: "Inter",

            formatter(legendName, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              const val = opts.w.globals.series[opts.seriesIndex];

              const percentage = (val / _sum(opts.w.globals.series)) * 100;
              return `
  <span>${name}<span/>
  <span style="margin-left: 8px">${
    Number.isNaN(percentage) ? 0 : numberFormatter(_round(percentage, 1))
  } %<span/>
  `;
            },
            ...legend,
          },
          stroke: {
            width: 0,
          },
        },
      }}
    />
  );
};

export default PieDonutChart;
