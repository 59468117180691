import {
  nRequestStatus,
  tDeleteRequest,
  tGetRequest,
  tPostRequest,
  tPutRequest,
} from "../interfaces/apiCalls";

const IPV4 = "localhost";

type tChoice<T = any> = Record<
  "monolith" | "propertiesMicroservice" | "managersMicroservice",
  { local: T; serverless: T }
>;

export const SERVERS_HOSTNAMES: tChoice<string> = {
  monolith: {
    local: `http://${IPV4}:5001`,
    serverless: process.env.REACT_APP_NOYTRALL_MONOLITH_API_URL || "",
  },
  propertiesMicroservice: {
    local: `http://${IPV4}:5002`,
    serverless: process.env.REACT_APP_NOYTRALL_PROPERTIES_API_URL || "",
  },
  managersMicroservice: {
    local: `http://${IPV4}:5003`,
    serverless: process.env.REACT_APP_NOYTRALL_MANAGERS_API_URL || "",
  },
};

export const USE_SERVER: tChoice<number> = {
  monolith: {
    local: 0,
    serverless: 1,
  },
  propertiesMicroservice: {
    local: 2,
    serverless: 3,
  },
  managersMicroservice: {
    local: 4,
    serverless: 5,
  },
};

const numberToHostname = Object.fromEntries(
  (Object.keys(USE_SERVER) as (keyof tChoice)[]).flatMap((typeKey, i) => [
    [i * 2, SERVERS_HOSTNAMES[typeKey].local],
    [i * 2 + 1, SERVERS_HOSTNAMES[typeKey].serverless],
  ])
);

export const USE_MONOLITH_SERVERLESS = 1;

export const apiAddress = (api: number = USE_SERVER.monolith.serverless) => {
  if (api === USE_SERVER.monolith.serverless) return SERVERS_HOSTNAMES.monolith.serverless;
  if (window.location.hostname !== "localhost") return SERVERS_HOSTNAMES.monolith.serverless;

  return SERVERS_HOSTNAMES.monolith.local;
};

export const constructApiAddress = (url: string, api: number = USE_SERVER.monolith.serverless) => {
  let apiHostname = "";
  if (window.location.hostname !== "localhost") {
    if (api % 2 === 0) api = api + 1;
  }
  apiHostname = numberToHostname[api];
  return `${apiHostname}${url}`;
};

export const fakeApiCall = (
  data: any,
  { timeout = 1000 }: { timeout?: number } = {}
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(data);
    }, timeout);
  });
};

export const REQUEST_STATUS: {
  IDLE: nRequestStatus.tIdle;
  PENDING: nRequestStatus.tPending;
  RESOLVED: nRequestStatus.tResolved;
  REJECTED: nRequestStatus.tRejected;
} = {
  IDLE: "idle",
  PENDING: "pending",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

export const requestStatusIsFinal = (requestStatus: nRequestStatus.tStatus): boolean => {
  return ([REQUEST_STATUS.RESOLVED, REQUEST_STATUS.REJECTED] as nRequestStatus.tStatus[]).includes(
    requestStatus
  );
};

export const requestStatusIsLoading = (requestStatus: nRequestStatus.tStatus) => {
  return requestStatus === REQUEST_STATUS.PENDING;
};

export const setGetRequestStateResolved = (
  setState: React.Dispatch<React.SetStateAction<tGetRequest<any>>>,
  data: any
) => {
  setState({
    data,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  });
};

export const setRequestStateRejected = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>,
  error?: string | null
) => {
  setState((prev: any) => ({
    ...prev,
    error: error || null,
    status: REQUEST_STATUS.REJECTED,
  }));
};

export const setRequestStateResolved = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    error: null,
    status: REQUEST_STATUS.RESOLVED,
  }));
};

export const setRequestStatePending = (
  setState:
    | React.Dispatch<React.SetStateAction<tGetRequest<any>>>
    | React.Dispatch<React.SetStateAction<tPutRequest>>
    | React.Dispatch<React.SetStateAction<tPostRequest>>
    | React.Dispatch<React.SetStateAction<tDeleteRequest>>
) => {
  setState((prev: any) => ({
    ...prev,
    status: REQUEST_STATUS.PENDING,
  }));
};
