import { tManager } from "../models/manager";
import { _chunk, _has, _isString, _round } from "./lodash-utils";

const DEFAULT_THOUSANDS_SEPARATOR = ".";
const DEFAULT_DECIMAL_SEPARATOR = ",";

export const numberFormatter = (number: number | string, profile?: tManager) => {
  try {
    const thousandsSeparator =
      profile && _has(profile, "settings.display.numberFormat.thousandSeparator")
        ? profile.settings.display.numberFormat.thousandSeparator
        : DEFAULT_THOUSANDS_SEPARATOR;
    const decimalSeparator =
      profile && _has(profile, "settings.display.numberFormat.decimalSeparator")
        ? profile.settings.display.numberFormat.decimalSeparator
        : DEFAULT_DECIMAL_SEPARATOR;

    if (number === "Infinity" || number === Infinity) return number;

    const fixedNumber = _isString(number)
      ? number
      : _round(number, 0) === number
      ? _round(number, 2)
      : _round(number, 2).toFixed(2);

    const signalSplit = `${fixedNumber}`.split("-");

    const unsigned = signalSplit[1] || signalSplit[0];

    const [integer, decimal] = `${unsigned}`.split(".");
    const thousands = _chunk(integer.split("").reverse(), 3)
      .map((a) => a.join(""))
      .join(thousandsSeparator)
      .split("")
      .reverse()
      .join("");

    const signaled = signalSplit.length === 2 ? `-${thousands}` : thousands;

    if (decimal) return `${signaled}${decimalSeparator}${decimal}`;
    return signaled;
  } catch (err: any) {
    return number;
  }
};
