import axios from "axios";
import { min } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalProps, Table } from "rsuite";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import { ReactComponent as NoytrallSymbolIcon } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleCheckCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleCheckCell";
import SimpleKeyValueCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleKeyValueCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useListPaymentMethods, { tPaymentMethod } from "../../../hooks/useListPaymentMethods";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import { HOTEL_STRIPE_PROPERTY, STRIPE_ENV } from "../../../utils/stripe";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import Icon from "../../Icons/Icon";
import PageSection from "../../PageSection";
import AddPaymentMethodModal from "../AddPaymentMethodModal";

const buildTransKey = (keyword: string) => `components.business_payment.${keyword}`;

interface iBusinessPaymentModalWrappedProps extends Pick<iProps, "onClose"> {
  billingTimePeriod: "annually" | "monthly";
}

const BusinessPaymentModalWrapped: React.FC<iBusinessPaymentModalWrappedProps> = ({
  billingTimePeriod,
  onClose,
}) => {
  const toaster = useSimpleToaster();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const { trans } = useLocalizationState();
  const { request, run } = useListPaymentMethods(activeProperty._id);
  const paymentRequest = usePostRequest();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState({
    open: false,
  });

  useEffect(() => {
    setSelectedMethod(activeProperty[HOTEL_STRIPE_PROPERTY].defaultCard);
  }, [activeProperty]);

  const handlePayment = () => {
    if (selectedMethod.length === 0) {
      toaster.error(trans(buildTransKey("errors.select_payment_method")));
      return;
    }
    paymentRequest.pending();

    axios
      .post(
        constructApiAddress(
          `/v2/hotels/${activeProperty._id}/confirm-first-plan/business`,
          USE_MONOLITH_SERVERLESS
        ),
        {
          billingTimePeriod,
          paymentMethodId: selectedMethod,
        },
        { params: { stripeEnv: STRIPE_ENV } }
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(activeProperty._id, hotel);
        paymentRequest.resolve();
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        paymentRequest.reject(error, true);
      });
  };

  const PRICE =
    billingTimePeriod === "monthly"
      ? activeProperty.subscriptionsMetadata.business.monthly.price
      : activeProperty.subscriptionsMetadata.business.monthly.price * 0.9 * 12;
  const TAX_PERCENTAGE = 0.23;
  const TAX = PRICE * TAX_PERCENTAGE;
  const TOTAL_PRICE = PRICE + TAX;

  const renderInfoCard = () => {
    return (
      <Flex one className="card-m" column gap={40}>
        <Flex row gap={8} middle>
          <Icon Element={NoytrallSymbolIcon} size={24} />
          <InterTag
            text={trans("Noytrall Technologies, Lda.")}
            size={16}
            color={COLORS.secondary}
            bold
          />
        </Flex>
        <Flex column gap={12}>
          <InterTag
            text={`${trans("general.subscribe_x", {
              parameters: [trans("general.noytrall.business")],
            })}`}
            bold
            color={COLORS.secondary}
            size={20}
          />
          <Flex row gap={8} bottom>
            <InterTag text={"€"} size={48} color={COLORS.secondary} bold />
            <InterTag
              text={`${TOTAL_PRICE.toFixed(2)}`.replace(".", ",")}
              size={48}
              color={COLORS.secondary}
              bold
            />
            <InterTag
              text={trans(billingTimePeriod === "monthly" ? "general.monthly" : "general.annually")}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
        <Flex column gap={12}>
          <Flex row between>
            <InterTag
              text={trans("general.noytrall.business")}
              size={16}
              bold
              color={COLORS.secondary}
            />
            <InterTag size={16} bold color={COLORS.secondary} text={`€ ${PRICE.toFixed(2)}`} />
          </Flex>
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans(buildTransKey("sustainability_platform"))}
          />
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans(
              buildTransKey(billingTimePeriod === "monthly" ? "billed_monthly" : "billed_annually")
            )}
          />
          <div
            style={{
              height: "19px",
              borderBottom: `1px solid ${COLORS.secondary}`,
            }}
          />
          <Flex row between>
            <InterTag text={trans("general.subtotal")} size={16} bold color={COLORS.secondary} />
            <InterTag size={16} bold color={COLORS.secondary} text={`€ ${PRICE.toFixed(2)}`} />
          </Flex>
          <Flex row between>
            <InterTag
              text={trans("general.tax_x%", { parameters: [23] })}
              size={16}
              color={COLORS.secondary}
            />
            <InterTag size={16} color={COLORS.secondary} text={`€ ${TAX.toFixed(2)}`} />
          </Flex>
          <div
            style={{
              height: "19px",
              borderBottom: `1px solid ${COLORS.secondary}`,
            }}
          />
          <Flex row between>
            <InterTag
              text={trans(buildTransKey("amount_due"))}
              size={16}
              bold
              color={COLORS.secondary}
            />
            <InterTag
              size={16}
              bold
              color={COLORS.secondary}
              text={`€ ${TOTAL_PRICE.toFixed(2)}`}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderPaymentCard = () => {
    const isLoadingLoading = request.isLoading;

    const tableHeight = isLoadingLoading
      ? TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT.SM
      : (min([3, request.data.paymentMethods.length]) as number) * TABLE_ROW_HEIGHT.SM +
        TABLE_HEADER_HEIGHT;

    return (
      <Flex one className="card-m" column between gap={12}>
        <InterTag
          size={20}
          text={trans(buildTransKey("select_payment_method"))}
          color={COLORS.secondary}
          bold
        />
        <Flex column gap={8} style={{ flexGrow: 1 }}>
          <div style={{ backgroundColor: COLORS.gray_100 }} className="table-wrapper">
            <Table
              rowHeight={TABLE_ROW_HEIGHT.SM}
              headerHeight={TABLE_HEADER_HEIGHT}
              data={request.data.paymentMethods}
              loading={request.isLoading}
              height={tableHeight}
            >
              <Table.Column>
                <SimpleHeaderCell />
                <SimpleCheckCell
                  isChecked={(item: tPaymentMethod) => item.id === selectedMethod}
                  onChange={(item: tPaymentMethod) => {
                    if (item.id === selectedMethod) return null;
                    setSelectedMethod(item.id);
                  }}
                />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.card")} />
                <BaseCell>
                  {(item: tPaymentMethod) => {
                    return (
                      <Flex row gap={4}>
                        <InterTag
                          text={trans("Visa **** $0", {
                            parameters: [item.card?.last4 || ""],
                          })}
                          size={12}
                          color={COLORS.secondary}
                        />
                      </Flex>
                    );
                  }}
                </BaseCell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.expires")} />
                <SimpleKeyValueCell
                  dataKey="expires"
                  textFunction={(item: tPaymentMethod) => {
                    if (item.card) {
                      const date = moment().set({
                        month: item.card.exp_month,
                        year: item.card.exp_year,
                      });
                      return trans("$0/$1", {
                        parameters: [moment(date).format("MM"), moment(date).format("YYYY")],
                      });
                    }
                  }}
                />
              </Table.Column>
            </Table>
          </div>
          <Button
            onClick={() => {
              setAddPaymentMethodModal({ open: true });
            }}
          >
            {trans(buildTransKey("add_payment_methods"))}
          </Button>
        </Flex>

        <Button
          onClick={handlePayment}
          loading={paymentRequest.isLoading}
          appearance="primary"
          disabled={selectedMethod.length === 0}
        >
          {trans("general.confirm_x", {
            parameters: [trans("general.subscription")],
          })}
        </Button>
      </Flex>
    );
  };
  return (
    <>
      <AddPaymentMethodModal
        open={addPaymentMethodModal.open}
        onClose={(action) => {
          if (action === "create") run();
          setAddPaymentMethodModal({ open: false });
        }}
      />
      <Modal.Header closeButton={!paymentRequest.isLoading} />
      <Modal.Body>
        <PageSection
          title={trans("general.payment")}
          description={trans(buildTransKey("description"))}
          icon={PaymentsIcon}
        >
          <Flex row gap={40}>
            {renderInfoCard()}
            {renderPaymentCard()}
          </Flex>
        </PageSection>
      </Modal.Body>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(): void;
  billingTimePeriod: "annually" | "monthly";
}

const BusinessPaymentModal: React.FC<iProps> = ({ onClose, open, billingTimePeriod }) => {
  return (
    <Modal
      {...{
        ...DEFAULT_MODAL_PROPS,
        open,
        onClose,
        size: "lg",
        overflow: false,
      }}
    >
      {open && <BusinessPaymentModalWrapped {...{ billingTimePeriod, onClose }} />}
    </Modal>
  );
};

export default BusinessPaymentModal;
