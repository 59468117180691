import { findIndex } from "lodash";
import React, { useContext, useState } from "react";
import Flex from "../../../../components/Flex";
import Segmentation from "../../../../components/Segmentation";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import useListStaff from "../../../../hooks/useListStaff";
import { COLORS } from "../../../../utils/colors";
import {
  hotelListVisibleRoles,
  hotelListVisibleStaff,
  hoteStaffMemberIsAdmin,
} from "../../../../utils/hotels/staff";
import AddButton from "./addButton";
import PermissionsTab from "./permissionsTab";
import RolesTab from "./rolesTab";
import UsersTab from "./usersTab";

type tSegment = {
  key: string;
  label: string;
  count?: number;
};

interface iUserManagementWrappedProps extends iUserManagementProps {}

const UserManagementWrapped: React.FC<iUserManagementWrappedProps> = ({ staffRequest }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { profile } = useProfileState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);

  const onSelectSegment = (key: string) => {
    if (staffRequest.isResolved) {
      setSelectedSegmentIndex(findIndex(segments, (f) => f.key === key));
    }
  };

  const segments: tSegment[] = [
    {
      key: "users",
      label: trans("general.users"),
      count: hotelListVisibleStaff(activeProperty, profile._id).length,
    },
    {
      key: "roles",
      label: trans("general.roles"),
      count: hotelListVisibleRoles(activeProperty, profile._id).length,
    },
  ];

  if (hoteStaffMemberIsAdmin(activeProperty, profile._id))
    segments.push({
      key: "permissions",
      label: trans("general.permissions"),
    });

  const renderTable = () => {
    switch (segments[selectedSegmentIndex].key) {
      case "users":
        return (
          <UsersTab
            {...{
              staffRequest,
            }}
          />
        );
      case "roles":
        return (
          <RolesTab
            {...{
              staffRequest,
            }}
          />
        );
      case "permissions":
        return <PermissionsTab />;
      default:
        break;
    }
  };

  const segment = segments[selectedSegmentIndex];

  return (
    <Flex column gap={16}>
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={segment.key}
            onSelect={onSelectSegment}
            options={segments.map((f) => {
              const { key, label, count } = f;
              return {
                key,
                label: { text: label },
                count: count,
              };
            })}
          />
        </Flex>
        <Flex
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
          middle
        >
          <AddButton {...{ staffRequest }} />
        </Flex>
      </Flex>
      <div>{renderTable()}</div>
    </Flex>
  );
};

interface iUserManagementProps {
  staffRequest: ReturnType<typeof useListStaff>;
}

const UserManagement: React.FC<iUserManagementProps> = ({ ...props }) => {
  return <UserManagementWrapped {...{ ...props }} />;
};

export default UserManagement;
