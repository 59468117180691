import React, { useState } from "react";
import { InputNumber, Modal, ModalProps } from "rsuite";
import { _toInteger, _toNumber } from "../../../utils/lodash-utils";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import Modal2ActionButtons from "../../Buttons/modal2ActionButtons";
import Flex from "../../Flex";
import PageSection from "../../PageSection";

interface iInputNumberModalWrappedProps
  extends Pick<
    iProps,
    "onClose" | "title" | "description" | "onConfirm" | "onlyInteger" | "max" | "min"
  > {}

const InputNumberModalWrapped: React.FC<iInputNumberModalWrappedProps> = ({
  description,
  onClose,
  onConfirm,
  onlyInteger,
  title,
  max,
  min,
}) => {
  const [value, setValue] = useState<number>(min || 1);

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={16}>
        <PageSection {...{ title, description }} />

        <InputNumber
          {...{ max, min, value }}
          onChange={(value) => {
            if (value) setValue(onlyInteger ? _toInteger(value) : _toNumber(value));
          }}
        />

        <Modal2ActionButtons onClose={onClose} onConfirm={() => onConfirm(value)} />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm(value: number): void;
  title: string;
  description: string;
  onlyInteger?: boolean;
  max?: number;
  min?: number;
}

const InputNumberModal: React.FC<iProps> = ({
  onClose,
  onConfirm,
  title,
  description,
  onlyInteger,
  max,
  min,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "xs", onClose, ...props }}>
      <InputNumberModalWrapped
        {...{ onClose, onConfirm, onlyInteger, title, description, max, min }}
      />
    </Modal>
  );
};

export default InputNumberModal;
