import axios from "axios";
import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ContentCopyIcon } from "../../../../assets/icons/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as DonutIcon } from "../../../../assets/icons/donut_large.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../assets/icons/meeting_room.svg";
import { ReactComponent as RoomPreferencesIcon } from "../../../../assets/icons/room_preferences.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as StacksIcon } from "../../../../assets/icons/stacks.svg";
import { ReactComponent as VisibilityIcon } from "../../../../assets/icons/visibility.svg";
import PageSectionBG from "../../../../components/PageSection/PageSectionBG";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import ActionsCell from "../../../../components/Tables/ActionsCell";
import TableHeaders from "../../../../components/Tables/TableHeaders";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../../context/Permissions";
import useDeleteRequest from "../../../../hooks/apiRequests/useDeleteRequest";
import useSimpleToaster from "../../../../hooks/useSimpleToaster";
import { tHotelSpace, tHotelSpaceId } from "../../../../models/hotel";
import {
  apiAddress,
  constructApiAddress,
  SERVERS_HOSTNAMES,
  USE_SERVER,
} from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { copyToClipboard } from "../../../../utils/others";
import { TABLE_ROW_CLASSNAMES, TABLE_ROWS_CONTAINER_CLASSNAMES } from "../../../../utils/tables";
import SpaceModal from "../spaceModal";
import { SpacesContext } from "./context";

const COLUMNS: any[] = [
  {
    key: "in-graph",
    label: "",
    className: "basis-1/12",
    icon: DonutIcon,
  },
  {
    key: "name",
    label: "general.space",
    className: "basis-3/12",
    icon: MeetingRoomIcon,
  },
  {
    key: "tags",
    label: "general.tags",
    className: "flex-1",
    icon: StacksIcon,
  },
];

interface iActionsCellHOCProps {
  space: tHotelSpace;
}

const ActionsCellHOC: React.FC<iActionsCellHOCProps> = ({ space }) => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const deleteRequest = useDeleteRequest();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel, updatingHotel, resolveHotel } = useHotelDispatch();
  const toaster = useSimpleToaster();
  const [spaceModal, setSpaceModal] = useState<
    | {
        open: false;
        spaceId: null;
        spaceName: null;
      }
    | {
        open: true;
        spaceId: tHotelSpaceId;
        spaceName: string;
      }
  >({ open: false, spaceId: null, spaceName: null });
  const { hasPermission } = useContext(PermissionsContext)!;

  const options: tSimpleWhisperPopoverDropdownOptions[] = [];

  options.push({
    key: "configure",
    icon: { Element: SettingsIcon, fill: COLORS.secondary },
    label: {
      text: trans("general.configure"),
    },
    onClick() {
      setSpaceModal({
        open: true,
        spaceId: space._id,
        spaceName: space.name,
      });
    },
    show: hasPermission("update:spaces"),
  });

  options.push({
    key: "view space",
    icon: { Element: VisibilityIcon, fill: COLORS.secondary },
    label: {
      text: trans("general.view_x", { parameters: [trans("general.space")] }),
    },
    onClick() {
      navigate(`/spaces/${space._id}`);
    },
    show: true,
  });

  options.push({
    key: "copy id to clipboard",
    label: trans("pages.spaces.tabs.spaces.management.table.options.copy_space_id"),
    icon: ContentCopyIcon,
    onClick() {
      copyToClipboard(space._id);
    },
    show: apiAddress(USE_SERVER.monolith.local) === SERVERS_HOSTNAMES.monolith.local,
  });

  options.push({
    key: "delete",
    icon: { Element: DeleteIcon, fill: COLORS.error },
    label: {
      text: trans("general.remove"),
      color: COLORS.error,
    },
    onClick() {
      updatingHotel();
      deleteRequest.pending();
      axios
        .delete(
          constructApiAddress(
            `/properties/${activeProperty._id}/spaces/${space._id}`,
            USE_SERVER.propertiesMicroservice.local
          )
        )
        .then((res) => {
          const {
            data: { property },
          } = res;
          updateHotel(activeProperty._id, property);
          toaster.success(
            trans("general.removed_x", {
              parameters: [trans("general.space")],
            })
          );
          deleteRequest.resolve();
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          resolveHotel();
          deleteRequest.reject(error, true);
        });
    },
    show: space.removable && hasPermission("delete:spaces"),
  });
  return (
    <>
      {spaceModal.open && (
        <SpaceModal
          onClose={() => {
            setSpaceModal({ open: false, spaceId: null, spaceName: null });
          }}
          {...{ ...spaceModal }}
        />
      )}
      <div className="flex-1 flex justify-end items-center">
        <ActionsCell options={options} />
      </div>
    </>
  );
};

interface iShowInGraphProps {
  space: tHotelSpace;
}

const ShowInGraph: React.FC<iShowInGraphProps> = ({ space }) => {
  const { state, toggleSpace } = useContext(SpacesContext)!;
  return (
    <div>
      <input
        type="checkbox"
        checked={state.selectedSpaces[space._id]}
        onChange={() => toggleSpace(space._id)}
        style={{ accentColor: COLORS.primary_600 }}
      />
    </div>
  );
};

const Rows: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;

  const spaceIdsWithMeasures = useMemo(() => {
    return activeProperty.spaces
      .filter((space) => {
        return space.consumptionMetadata.measures.length > 0;
      })
      .map((s) => s._id);
  }, [activeProperty.spaces]);

  return (
    <div className={TABLE_ROWS_CONTAINER_CLASSNAMES + " h-fit max-h-[calc(100vh-290px)]"}>
      {activeProperty.spaces.map((space) => (
        <div key={space._id} className={TABLE_ROW_CLASSNAMES}>
          <div className={COLUMNS[0].className}>
            {spaceIdsWithMeasures.includes(space._id) ? <ShowInGraph space={space} /> : null}
          </div>
          <div className={COLUMNS[1].className}>
            <InterTag text={space.name} color={COLORS.secondary} size={12} />
          </div>
          <div className={COLUMNS[2].className}>
            <InterTag text={(space.tagList || []).join(", ")} color={COLORS.secondary} size={12} />
          </div>
          <ActionsCellHOC space={space} />
        </div>
      ))}
    </div>
  );
};

const SpacesTabTableWrapped: React.FC = () => {
  return (
    <div>
      <TableHeaders columns={COLUMNS} />
      <Rows />
    </div>
  );
};

interface iProps {}

const SpacesTabTable: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <PageSectionBG
      {...{
        title: trans("pages.spaces.tabs.spaces.management.title"),
        description: trans("pages.spaces.tabs.spaces.management.description"),
        icon: RoomPreferencesIcon,
      }}
    >
      <SpacesTabTableWrapped />
    </PageSectionBG>
  );
};

export default SpacesTabTable;
