import { createContext, useState } from "react";

const ConsumptionLiveSelectedSegmentContext = createContext<{
  selectedSegment: "co2" | "w" | "e";
  setSelectedSegment: (segment: "co2" | "w" | "e") => void;
}>({
  selectedSegment: "co2",
  setSelectedSegment: () => {},
});

const ConsumptionLiveSelectedSegmentContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [selectedSegment, setSelectedSegment] = useState<"co2" | "w" | "e">("co2");

  return (
    <ConsumptionLiveSelectedSegmentContext.Provider value={{ selectedSegment, setSelectedSegment }}>
      {children}
    </ConsumptionLiveSelectedSegmentContext.Provider>
  );
};

export { ConsumptionLiveSelectedSegmentContext };

export default ConsumptionLiveSelectedSegmentContextProvider;
