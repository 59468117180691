import { sum } from "lodash";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { HotelStateContext } from "../../../../context/Hotel";
import { FUEL_TYPES } from "../../../../interfaces/manualData";
import { _orderBy } from "../../../../utils/lodash-utils";
import ShowcasingWrapped2, { tTableItem } from "./wrapped2";

interface iShowcasingWrappedProps {
  outletContainerWidth: number;
}

const ShowcasingWrapped: React.FC<iShowcasingWrappedProps> = ({ outletContainerWidth }) => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const tableData: tTableItem[] = useMemo(() => {
    return _orderBy(
      activeProperty.manualData
        .map((item) => {
          const { from, to, _id } = item;
          const totalKWh = sum(FUEL_TYPES.map((key) => item[key]?.totalKWh || 0));
          const totalPrice = sum(FUEL_TYPES.map((key) => item[key]?.price || 0));

          return {
            from,
            to,
            _id,
            totalKWh,
            totalPrice,
            sorter: moment(from).valueOf(),
            homologueId: `${moment(from).format("YYYY/MM")}`,
          };
        })
        .filter((item) => item.totalKWh || item.totalPrice),
      ["sorter"],
      ["desc"]
    ).map((item, index) => ({ ...item, index }));
  }, [activeProperty]);

  return <ShowcasingWrapped2 {...{ outletContainerWidth, tableData }} />;
};

export default ShowcasingWrapped;
