import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "../../utils/colors";
import { _omit } from "../../utils/lodash-utils";
import Flex from "../Flex";
import InterTag from "../Text/Inter";

export interface iUnlockPageSectionProps {
  image: string;
  title: string;
  description: string[];
  onClick?: () => void;
  [key: string]: any;
}

const UnlockPageSection: React.FC<iUnlockPageSectionProps> = ({
  image,
  title,
  description,
  ...props
}) => {
  const { isMobile } = useWindowSizeState();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  if (isMobile) {
    return (
      <Flex
        column
        center
        middle
        style={{
          background:
            "linear-gradient(117.16deg, rgba(64, 191, 180, 0.5) -3.01%, rgba(255, 255, 255, 0.4) 103.91%)",
          borderRadius: "8px",
          position: "relative",
          padding: "80px 0",
          ...props.style,
        }}
        {...{ ..._omit(props, ["style"]) }}
      >
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            opacity: "50%",
            filter: "blur(4px)",
            top: 0,
            bottom: 0,
            width: "300px",
            position: "absolute",
          }}
        />
        {/* <Flex column middle gap={32} style={{ width: "80%", zIndex: 0 }}>
          <InterTag
            {...{
              size: 36,
              color: COLORS.gray_800,
              bold: true,
              text: title,
              style: { textWrap: "wrap", textAlign: "center" },
            }}
          />
          {description.map((text) => {
            return (
              <InterTag
                {...{
                  key: text,
                  size: 20,
                  color: COLORS.gray_800,
                  text,
                  style: { textWrap: "wrap", textAlign: "center" },
                }}
              />
            );
          })}
          <Button
            style={{
              backgroundColor: COLORS.primary_900,
              color: COLORS.white,
              fontWeight: "bold",
            }}
            onClick={() => {
              navigate("/settings/billing/plan");
            }}
          >
            {trans("general.upgrade_now")}
          </Button>
        </Flex> */}
      </Flex>
    );
  }

  return (
    <Flex
      column
      center
      middle
      style={{
        background:
          "linear-gradient(117.16deg, rgba(64, 191, 180, 0.5) -3.01%, rgba(255, 255, 255, 0.4) 103.91%)",
        borderRadius: "8px",
        position: "relative",
        padding: "80px 0",
        ...props.style,
      }}
      {...{ ..._omit(props, ["style"]) }}
    >
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          opacity: "50%",
          filter: "blur(4px)",
          top: 0,
          bottom: 0,
          width: "864px",
          position: "absolute",
        }}
      />
      <Flex column middle gap={32} style={{ width: "80%", zIndex: 0 }}>
        <InterTag
          {...{
            size: 36,
            color: COLORS.gray_800,
            bold: true,
            text: title,
            style: { textWrap: "wrap", textAlign: "center" },
          }}
        />
        {description.map((text) => {
          return (
            <InterTag
              {...{
                key: text,
                size: 20,
                color: COLORS.gray_800,
                text,
                style: { textWrap: "wrap", textAlign: "center" },
              }}
            />
          );
        })}
        <Button
          style={{
            backgroundColor: COLORS.primary_900,
            color: COLORS.white,
            fontWeight: "bold",
          }}
          onClick={() => {
            navigate("/settings/billing/plan");
          }}
        >
          {trans("general.upgrade_now")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default UnlockPageSection;
