import { sum } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tHotelFleet } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import { _round } from "../../../../utils/lodash-utils";

interface iProps {}

const Graph: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [graphType] = useState<"donut">("donut");

  const [totalDistance, totalFuelLiters, labels, distanceSeries, fuelsSeries] = useMemo(() => {
    const labels: string[] = [];
    const distanceSeries: number[] = [];
    const fuelsSeries: number[] = [];
    let totalDistance = 0;
    let totalFuelLiters = 0;

    activeProperty.fleet.forEach((f) => {
      labels.push(f.licensePlate);

      let fTotalDistance = 0;
      let fTotalFuelLiters = 0;

      f.entries.forEach((e) => {
        fTotalDistance += e.distanceKm;
        fTotalFuelLiters += e.fuelLiters;
      });

      distanceSeries.push(fTotalDistance);
      fuelsSeries.push(fTotalFuelLiters);

      totalDistance += fTotalDistance;
      totalFuelLiters += fTotalFuelLiters;
    });

    return [totalDistance, totalFuelLiters, labels, distanceSeries, fuelsSeries];
  }, [activeProperty.fleet]);

  const segments: {
    key: keyof tHotelFleet["entries"][0];
    label: string;
    value: number;
    unit: string;
    series: number[];
  }[] = [
    {
      key: "distanceKm",
      label: trans("general.distance"),
      value: totalDistance,
      unit: "km",
      series: distanceSeries,
    },
    {
      key: "fuelLiters",
      label: trans("general.fuel_usage"),
      value: totalFuelLiters,
      unit: "L",
      series: fuelsSeries,
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row>
        {segments.map(({ key, label, unit, value }, i) => {
          let labelColor = COLORS.gray_400;
          let valueColor = COLORS.secondary;

          if (selectedSegmentIndex === i) {
            labelColor = valueColor = COLORS.primary;
          }

          return (
            <Flex
              basis={15}
              key={key}
              column
              gap={8}
              style={{
                cursor: "pointer",
                padding: "6px",
                borderRadius: "8px",
              }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
              }}
            >
              <InterTag size={12} text={label} color={labelColor} />
              <Flex row bottom>
                <InterTag size={36} text={value} color={valueColor} />
                <InterTag size={12} text={unit} color={valueColor} />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const graph = () => {
    const { series } = segments[selectedSegmentIndex];

    if (series.length === 0) return null;

    if (graphType === "donut") {
      return (
        <Flex center>
          <ReactApexChart
            type="donut"
            {...{
              series,
              options: {
                theme: {
                  monochrome: {
                    enabled: true,
                    color: COLORS.primary_500,
                    shadeIntensity: 1,
                  },
                },
                chart: {
                  type: "donut",
                },
                tooltip: {
                  enabled: false,
                },
                labels,
                responsive: [
                  {
                    breakpoint: 10000,
                    options: {
                      chart: {
                        width: 600,
                        height: 311,
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  position: "right",
                  fontFamily: "Inter",

                  formatter(legendName, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    const val = opts.w.globals.series[opts.seriesIndex];

                    const percentage = (val / sum(opts.w.globals.series)) * 100;

                    return `
          <span>${name}<span/>
          <span style="margin-left: 8px">${_round(percentage, 1)} %<span/>
          `;
                  },
                },
                stroke: {
                  width: 0,
                },
              },
            }}
          />
        </Flex>
      );
    }
  };

  return (
    <Flex className="card-m" column gap={16}>
      {renderSegments()}
      {graph()}
    </Flex>
  );
};

export default Graph;
