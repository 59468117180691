import { at, sum } from "lodash";
import { MomentInput } from "moment";
import React, { useContext } from "react";
import { ReactComponent as EcoIcon } from "../../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as HotelIcon } from "../../../../../assets/icons/hotel.svg";
import { ReactComponent as WaterDropIcon } from "../../../../../assets/icons/water_drop.svg";
import StraightComparison from "../../../../../components/Comparions/straightComparison";
import TrendingComparison from "../../../../../components/Comparions/trendingComparison";
import Icon from "../../../../../components/Icons/Icon";
import NumberFormatted from "../../../../../components/Numbers/NumberFormatted";
import SkeletonText from "../../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../../context/Hotel";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { tBenchmarkData, useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import { tIcon } from "../../../../../interfaces/icon";
import { COLORS } from "../../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../../../../utils/dates";
import { tBenchmarkSizes } from "../../sustainability";

interface iProps {
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  benchmarkRequest: ReturnType<typeof useGetBenchmarkData>;
  period: [MomentInput, MomentInput];
  benchmarkSizes: tBenchmarkSizes;
}

const PerAccommodation: React.FC<iProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  benchmarkRequest,
  period,
  benchmarkSizes,
}) => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const { trans } = useLocalizationState();

  const {
    titleSize,
    titleIconSize,
    typeSize,
    typeIconSize,
    mainValueSize,
    mainValueUnitSize,
    mainValuePadding,
    benchmarkSize,
  } = benchmarkSizes;

  const numberOfNights = calcNumberOfNights(...period);

  const renderMainValue = (pathToMeasureValues: string, converter: nUnitConverter.tFunction) => {
    if (measuresRequest.isLoading) return <SkeletonText width={100} height={mainValueSize} />;

    if (measuresRequest.isResolved) {
      const values = at(measuresRequest.data, pathToMeasureValues)[0] as number[];

      const converted = converter(
        sum(values) / activeProperty.spaces.length / (numberOfNights || 1)
      );

      return (
        <div className="flex flex-row items-end gap-1">
          <NumberFormatted
            Wrapper={InterTag}
            props={{ size: mainValueSize, color: COLORS.secondary }}
            number={converted.value}
          />
          <InterTag
            size={mainValueUnitSize}
            color={COLORS.secondary}
            text={converted.unitInHTML}
            asHTML
          />
        </div>
      );
    }
    return null;
  };

  const renderHomologueComparison = (pathToMeasureValues: string) => {
    if (measuresRequest.isLoading || homologueMeasuresRequest.isLoading)
      return <SkeletonText width={50} height={benchmarkSize} />;

    if (measuresRequest.isResolved && homologueMeasuresRequest.isResolved) {
      const value = sum(at(measuresRequest.data, pathToMeasureValues)[0] as number[]);
      const homologueValue = sum(
        at(homologueMeasuresRequest.data, pathToMeasureValues)[0] as number[]
      );

      return <TrendingComparison size={benchmarkSize} value={homologueValue} baseValue={value} />;
    }
    return null;
  };

  const renderBenchmarkComparison = (
    pathToBenchmarkValue: string,
    pathToMeasuresValue: string,
    converter: nUnitConverter.tFunction
  ) => {
    if (benchmarkRequest.isLoading || measuresRequest.isLoading)
      return <SkeletonText height={mainValueSize} width={100} />;

    if (benchmarkRequest.isResolved && measuresRequest.isResolved) {
      const benchmarkValue = at(
        benchmarkRequest.data,
        pathToBenchmarkValue
      )[0] as unknown as number;

      const value =
        converter(sum(at(measuresRequest.data, pathToMeasuresValue)[0] as number[])).value /
        activeProperty.spaces.length /
        numberOfNights;

      return (
        <StraightComparison
          valueSize={mainValueSize}
          unitSize={mainValueUnitSize}
          baseValue={benchmarkValue}
          value={value}
        />
      );
    }
    return null;
  };

  const renderBenchmark = (
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perAccommodation"
    >}.${keyof tBenchmarkData["perAccommodation"]}`,
    unit: string
  ) => {
    if (benchmarkRequest.isLoading) return <SkeletonText height={benchmarkSize} width={50} />;

    if (benchmarkRequest.isResolved) {
      // @ts-expect-error
      const value = at(benchmarkRequest.data, pathToBenchmarkValue)[0] as number;

      return (
        <div className="flex flex-row gap-1 items-center">
          <NumberFormatted
            Wrapper={InterTag}
            props={{ size: benchmarkSize, color: COLORS.secondary }}
            number={value}
          />
          <InterTag size={benchmarkSize} color={COLORS.secondary} text={unit} asHTML />
        </div>
      );
    }
    return null;
  };

  const renderDivider = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_200,
        }}
      />
    );
  };

  const renderAccommodationCell = (
    label: string,
    icon: tIcon,
    pathToMeasureValues: string,
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perAccommodation"
    >}.${keyof tBenchmarkData["perAccommodation"]}`,
    benchmarkUnit: string,
    converter: nUnitConverter.tFunction
  ) => {
    return (
      <div className="flex flex-col gap-2.5 bg-white">
        <div className="flex flex-row gap-1 items-center">
          <Icon {...{ size: typeIconSize, ...icon }} />
          <InterTag size={typeSize} color={COLORS.gray} text={label} asHTML />
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex-1 flex justify-start">
            <div style={{ paddingLeft: `${mainValuePadding}px` }}>
              {renderMainValue(pathToMeasureValues, converter)}
            </div>
          </div>
          <div className="flex-1 flex justify-end">
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmarkComparison(pathToBenchmarkValue, pathToMeasureValues, converter)}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex-1 flex justify-start">
            <div style={{ paddingLeft: `${mainValuePadding}px` }}>
              {renderHomologueComparison(pathToMeasureValues)}
            </div>
          </div>
          <div className="flex-1 flex justify-end">
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmark(pathToBenchmarkValue, benchmarkUnit)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col bg-white p-4 rounded-lg gap-4">
      <div className="flex flex-row gap-2 items-center">
        <Icon Element={HotelIcon} size={titleIconSize} fill={COLORS.secondary} />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={trans("pages.sustainability.sections.indicators.cards.accommodation.title")}
        />
      </div>
      {renderAccommodationCell(
        trans("general.co_2_emissions"),
        {
          Element: EcoIcon,
          fill: COLORS.emissions,
        },
        "co2",
        "perAccommodation.co2_kg",
        "kg",
        convertMassUnit
      )}
      {renderDivider()}
      {renderAccommodationCell(
        trans("general.measures_.electricity"),
        {
          Element: ElectricBoltIcon,
          fill: COLORS.energy,
        },
        "grouped.te.measurements",
        "perAccommodation.electricity_kWh",
        "kWh",
        convertEnergyUnit
      )}
      {renderDivider()}
      {renderAccommodationCell(
        trans("general.measures_.water"),
        {
          Element: WaterDropIcon,
          fill: COLORS.water,
        },
        "grouped.tw.measurements",
        "perAccommodation.water_m3",
        "m<sup>3</sup>",
        convertVolumeUnit
      )}
    </div>
  );
};

export default PerAccommodation;
