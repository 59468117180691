import React, { useState } from "react";
import { ButtonProps } from "rsuite";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "../../utils/colors";
import ManualDataModal, { iManualDataModalProps } from "../Modals/ManualDataModal";
import SimpleButton from "../RsuiteWrapper/SimpleButton";

interface iProps {
  modalProps?: Omit<iManualDataModalProps, "open" | "onClose">;
  [key: string]: any;
}

const AddManualDataButton: React.FC<iProps> = ({ modalProps, ...props }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState(false);

  const [size, fontSize, paddingVertical]: [ButtonProps["size"], number, number] = isMobile
    ? ["sm", 12, 6]
    : ["md", 14, 8];

  return (
    <>
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
        {...modalProps}
      />
      <SimpleButton
        style={{
          paddingTop: `${paddingVertical}px`,
          paddingBottom: `${paddingVertical}px`,
        }}
        appearance="primary"
        size={size}
        text={{
          text: trans("components.manual_data.global_add_entry.label"),
          color: COLORS.white,
          size: fontSize,
        }}
        onClick={() => setManualDataModal(true)}
      />
    </>
  );
};

export default AddManualDataButton;
