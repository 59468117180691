import axios from "axios";
import React, { useContext, useState } from "react";
import { InlineEdit, Input, Loader } from "rsuite";
import { ReactComponent as StoreFrontIcon } from "../../../assets/icons/storefront.svg";
import Flex from "../../../components/Flex";
import PageSection from "../../../components/PageSection";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePatchRequest from "../../../hooks/apiRequests/usePostRequest";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

const MAX_DESCRIPTION_LENGTH = 2000;

interface EditDescriptionWrappedProps extends iProps {}

const EditDescriptionWrapped: React.FC<EditDescriptionWrappedProps> = ({ canWrite }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const [description, setDescription] = useState(() => activeProperty.guestApp.description || "");
  const request = usePatchRequest();

  const onSave = (event: any) => {
    if (!canWrite) return;
    if (description.length < 3) {
      return setDescription(activeProperty.guestApp.description || "");
    }
    if (event.type === "click" || (event.type === "keydown" && event.key === "Enter")) {
      if (description !== activeProperty.guestApp.description) {
        request.pending();
        axios
          .patch(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/guest-app`,
              USE_MONOLITH_SERVERLESS
            ),
            {
              description,
            }
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(activeProperty._id, hotel);
            request.resolve(
              undefined,
              trans("pages.hotel_profile.sections.description.notifications.description_changed")
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error, true);
            setDescription(activeProperty.guestApp.description || "");
          });
      }
      return;
    }
    return setDescription(activeProperty.guestApp.description || "");
  };

  return (
    <Flex column gap={8}>
      {request.isLoading ? (
        <Flex
          style={{
            height: "34px",
            backgroundColor: COLORS.gray_300,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
            paddingLeft: "8px",
          }}
          middle
        >
          <Loader size="sm" />
        </Flex>
      ) : (
        <InlineEdit
          disabled={!canWrite}
          className="inter"
          style={{
            backgroundColor: canWrite ? COLORS.white : COLORS.gray_200,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
          }}
          value={description}
          onChange={(value) => setDescription(value.slice(0, MAX_DESCRIPTION_LENGTH))}
          onCancel={() => setDescription(activeProperty.guestApp.description || "")}
          onSave={onSave}
        >
          <Input as="textarea" rows={5} />
        </InlineEdit>
      )}
      <InterTag
        size={12}
        color={COLORS.gray_400}
        text={trans("pages.hotel_profile.sections.description.characters", {
          parameters: [description.length, MAX_DESCRIPTION_LENGTH],
        })}
      />
    </Flex>
  );
};

interface iProps {
  canWrite: boolean;
}

const EditDescription: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.hotel_profile.sections.description.title")}
        icon={StoreFrontIcon}
        description={trans("pages.hotel_profile.sections.description.description")}
      />
      <EditDescriptionWrapped {...props} />
    </Flex>
  );
};

export default EditDescription;
