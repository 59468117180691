import { first, isNull } from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import { Avatar } from "rsuite";
import { ReactComponent as AssignmentIcon } from "../../../assets/icons/assignment.svg";
import { ReactComponent as BadgeIcon } from "../../../assets/icons/badge.svg";
import { ReactComponent as PunchClockIcon } from "../../../assets/icons/punch_clock.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageSection from "../../../components/PageSection";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { tManager } from "../../../models/manager";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { hotelListAccountStaffRoles } from "../../../utils/hotels/staff";

interface iProps {
  status: nRequestStatus.tStatus;
  staff: tManager | null;
}

const UserInfo: React.FC<iProps> = ({ status, staff }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { profile } = useProfileState();
  const { hasPermission } = useContext(PermissionsContext)!;

  const renderDetails = () => {
    if (isNull(staff) || status === REQUEST_STATUS.PENDING) {
      return (
        <Flex column gap={16}>
          <Flex row gap={16} middle>
            <Avatar size="lg" circle src={undefined} />
            <Flex column gap={8}>
              <SkeletonText width={300} height={36} />
              <SkeletonText width={200} height={16} />
            </Flex>
          </Flex>
          <Flex column gap={8}>
            {[
              { label: "Role", IconElement: AssignmentIcon, show: true },
              {
                label: "Last Access",
                IconElement: PunchClockIcon,
                show: hasPermission("read:staff:last-access") || profile._id === staff?._id,
              },
            ]
              .filter((e) => e.show)
              .map(({ label, IconElement }) => (
                <Flex row key={label}>
                  <Flex basis={13} middle gap={4}>
                    <Icon Element={IconElement} fill={COLORS.secondary} size={18} />
                    <InterTag bold text={trans(label)} size={14} color={COLORS.secondary} />
                  </Flex>
                  <SkeletonText width={150} height={14} />
                </Flex>
              ))}
          </Flex>
        </Flex>
      );
    }
    const { images, name, email, _id, lastAccess } = staff;

    const roles = hotelListAccountStaffRoles(activeProperty, _id);

    return (
      <Flex column gap={16}>
        <Flex row gap={16}>
          <Avatar size="lg" circle src={first(images)} />
          <Flex column gap={8}>
            <InterTag size={36} text={name} color={COLORS.secondary} />
            <InterTag size={16} text={email} color={COLORS.primary} />
          </Flex>
        </Flex>
        <Flex column gap={8}>
          {[
            {
              label: "Role",
              IconElement: AssignmentIcon,
              value: roles.map((r) => r.name),
              show: true,
            },
            {
              label: "Last Access",
              IconElement: PunchClockIcon,
              value: moment(lastAccess).format("MMMM DD, YYYY HH:mm"),
              show: hasPermission("read:staff:last-access") || profile._id === staff?._id,
            },
          ]
            .filter((e) => e.show)
            .map(({ label, IconElement, value }) => (
              <Flex row key={label}>
                <Flex basis={13} middle gap={4}>
                  <Icon Element={IconElement} fill={COLORS.secondary} size={18} />
                  <InterTag bold text={trans(label)} size={14} color={COLORS.secondary} />
                </Flex>
                <InterTag size={14} text={value} color={COLORS.secondary} />
              </Flex>
            ))}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex column gap={40}>
      <PageSection title={trans("User")} icon={{ Element: BadgeIcon }} />
      {renderDetails()}
    </Flex>
  );
};

export default UserInfo;
