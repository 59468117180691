import { MomentInput } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup, ButtonProps } from "rsuite";
import { ReactComponent as ReadinessScoreIcon } from "../../../../../assets/icons/readiness_score.svg";
import Flex from "../../../../../components/Flex";
import PageSection from "../../../../../components/PageSection";
import SimpleButton from "../../../../../components/RsuiteWrapper/SimpleButton";
import { HotelStateContext } from "../../../../../context/Hotel";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../../context/WindowSize/hooks/useWindowSizeState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import { COLORS } from "../../../../../utils/colors";
import { SustainabilityContext } from "../../context";
import { tBenchmarkSizes } from "../../sustainability";
import PerAccommodation from "./perAccommodations";
import PerGuest from "./perGuest";
import { propertyIsSettingOn } from "../../../../../utils/hotels/settings";

interface iMobileSlidesProps {
  perAccommodation: JSX.Element;
  perGuest: JSX.Element;
}

const MobileSlides: React.FC<iMobileSlidesProps> = ({ perAccommodation, perGuest }) => {
  const { trans } = useLocalizationState();
  const [index, setIndex] = useState(0);

  const segments = [
    {
      key: "accommodation",
      transKey: "general.accommodations",
      jsx: perAccommodation,
    },
    { key: "guests", transKey: "general.guests", jsx: perGuest },
  ];

  const { jsx } = segments[index];

  return (
    <Flex column gap={8}>
      <ButtonGroup>
        {segments.map(({ key, transKey }, i) => {
          const [appearance, color, onClick]: [
            ButtonProps["appearance"],
            string,
            undefined | (() => void)
          ] =
            index === i
              ? ["primary", COLORS.white, undefined]
              : ["ghost", COLORS.primary, () => setIndex(i)];
          return (
            <SimpleButton
              {...{ key, appearance, onClick }}
              size="sm"
              text={{ text: trans(transKey), color }}
            />
          );
        })}
      </ButtonGroup>
      {jsx}
    </Flex>
  );
};

interface iBenchmarkWrappedProps extends iProps {}

const BenchmarkWrapped: React.FC<iBenchmarkWrappedProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
  benchmarkSizes,
}) => {
  const { isMobile } = useWindowSizeState();
  const benchmarkRequest = useGetBenchmarkData({ doRequest: !noData });
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const { activeProperty } = useContext(HotelStateContext)!;

  useEffect(() => {
    setDataTypeToggleDisabled({ benchmarkRequest: benchmarkRequest.isLoading });
  }, [benchmarkRequest.isLoading, setDataTypeToggleDisabled]);

  const perAccommodation = (
    <PerAccommodation
      {...{
        benchmarkRequest,
        homologueMeasuresRequest,
        measuresRequest,
        period,
        benchmarkSizes,
      }}
    />
  );
  const perGuest = (
    <PerGuest
      {...{
        benchmarkRequest,
        homologueMeasuresRequest,
        measuresRequest,
        noData,
        period,
        benchmarkSizes,
      }}
    />
  );

  if (isMobile) return <MobileSlides {...{ perAccommodation, perGuest }} />;

  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="basis-1/2">{perAccommodation}</div>
      <div className="basis-1/2">
        {propertyIsSettingOn(activeProperty, "pages.sustainability.guests.show") ? perGuest : null}
      </div>
    </div>
  );
};

interface iProps {
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  period: [MomentInput, MomentInput];
  noData: boolean;
  benchmarkSizes: tBenchmarkSizes;
}

const SensorDataBenchmark: React.FC<iProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
  benchmarkSizes,
}) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans("pages.sustainability.sections.indicators.description")}
      />
      <BenchmarkWrapped
        {...{
          homologueMeasuresRequest,
          measuresRequest,
          period,
          noData,
          benchmarkSizes,
        }}
      />
    </Flex>
  );
};

export default SensorDataBenchmark;
