import React from "react";
import PageSection, { iPageSectionProps } from ".";

interface iProps extends iPageSectionProps {}

const PageSectionBG: React.FC<iProps> = ({ className, ...props }) => {
  return (
    <div className={"bg-zinc-200/45 rounded-md p-2 sm:p-4 shadow-md " + className}>
      <PageSection {...props} />
    </div>
  );
};

export default PageSectionBG;
