import axios from "axios";
import moment, { MomentInput } from "moment";
import { useContext, useEffect } from "react";
import { HotelStateContext } from "../context/Hotel";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { constructApiAddress, REQUEST_STATUS, USE_MONOLITH_SERVERLESS } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

const LS_DATA = "__n-s-g__";

const useGetNumberOfStaysAndGuests = (
  { from, to }: { from?: MomentInput | null; to?: MomentInput | null } = {
    from: null,
    to: null,
  },
  { doRequest = true }: { doRequest?: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const request = useGetRequest<{
    numberOfStays: number;
    numberOfGuests: number;
  }>(
    {
      numberOfStays: 0,
      numberOfGuests: 0,
    },
    { ...(doRequest ? {} : { status: REQUEST_STATUS.IDLE }) }
  );

  useEffectSafe(() => {
    if (activeProperty && doRequest) {
      request.pending();

      try {
        const lsData = localStorage.getItem(LS_DATA);
        if (lsData) {
          request.resolve(JSON.parse(lsData).data);
          return;
        }
      } catch (error) {
        console.error(error);
      }

      axios
        .get(
          constructApiAddress(
            `/v2/hotels/${activeProperty._id}/stays/number-of-stays-and-guests`,
            USE_MONOLITH_SERVERLESS
          ),
          {
            params: { from, to },
          }
        )
        .then((res) => {
          const {
            data: { numberOfStays, numberOfGuests },
          } = res;
          localStorage.setItem(
            LS_DATA,
            JSON.stringify({
              data: { numberOfGuests, numberOfStays },
              timestamp: moment().valueOf(),
            })
          );
          request.resolve({
            numberOfGuests: numberOfGuests,
            numberOfStays: numberOfStays,
          });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, activeProperty._id, to, trans, doRequest]);

  useEffect(() => {});

  const { data, error, status, isFinal, isLoading, isRejected, isResolved } = request;

  return { data, error, status, isFinal, isLoading, isRejected, isResolved };
};

export default useGetNumberOfStaysAndGuests;
