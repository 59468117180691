import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import { DataTypeStateContext } from "../../../context/DataType";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../utils/hotels/subscriptions";
import PageTitle from "../root/components/pageTitle";
import Archives from "./archives";
import OccupancyContextProvider, { OccupancyContext } from "./context";
import Manual from "./manual";

const OccupancyGuarded: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });
  const context = useContext(OccupancyContext);
  const dataTypeContext = useContext(DataTypeStateContext)!;

  if (activeSubscriptionIsStarter) return <Archives dataTypeToggle={<></>} />;

  const dataTypeToggle = (
    <DataTypeToggle
      loading={
        dataTypeContext.isPending ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeContext.isResolved) return null;

  if (dataTypeContext.dataTypeIsLive) return <Manual {...{ dataTypeToggle }} />;

  return <Archives {...{ dataTypeToggle }} />;
};

const Occupancy: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <PageTitle page={trans("general.occupancy")} />
      <IpValidatorGuard>
        <OccupancyGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(OccupancyContextProvider, Occupancy);
