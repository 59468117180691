import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import NoPermissions from "../../../components/Pages/NoPermissions";
import { HotelStateContext } from "../../../context/Hotel";
import { PermissionsContext } from "../../../context/Permissions";
import { propertyIsSettingOn } from "../../../utils/hotels/settings";
import EditDescription from "./editDescription";
import EditName from "./editName";
import PhotoGallery from "./photoGallery";

interface iHotelProfileMainProps {
  canWrite: boolean;
}

const HotelProfileMain: React.FC<iHotelProfileMainProps> = ({ canWrite }) => {
  return (
    <div className="flex flex-col gap-10 pb-10">
      <EditName {...{ canWrite }} />
      <EditDescription {...{ canWrite }} />
      <PhotoGallery {...{ canWrite }} />
    </div>
  );
};

const HotelProfileGuarded: React.FC = () => {
  const { hasPermission } = useContext(PermissionsContext)!;

  return (
    <>
      {hasPermission("read:guest-app-data") ? (
        <IpValidatorGuard>
          <HotelProfileMain canWrite={hasPermission("update:guest-app-data")} />
        </IpValidatorGuard>
      ) : (
        <NoPermissions />
      )}
    </>
  );
};

const HotelProfile: React.FC = () => {
  const { activeProperty } = useContext(HotelStateContext)!;

  if (!propertyIsSettingOn(activeProperty, "pages.guestAppProfile.show"))
    return <Navigate to="/" />;

  return <HotelProfileGuarded />;
};

export default HotelProfile;
