import React, { useContext, useState } from "react";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as InsightsIcon } from "../../../../assets/icons/insights.svg";
import AddManualDataButton from "../../../../components/Buttons/addManualDataButton";
import Flex from "../../../../components/Flex";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSection from "../../../../components/PageSection";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../../context/Permissions";
import Graph from "./graph";
import List from "./list";

interface iProps {
  dataTypeToggle: JSX.Element;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState({ open: false });
  const { hasPermission } = useContext(PermissionsContext)!;

  return (
    <div>
      {hasPermission("create:manual-data") && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="occupancy"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <SecondaryTopBar>
        <Flex row middle gap={8}>
          {dataTypeToggle}
          {hasPermission("create:manual-data") && (
            <AddManualDataButton onClick={() => setManualDataModal({ open: true })} />
          )}
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        <Flex column gap={16}>
          <PageSection
            title={trans("pages.occupancy.archive.sections.data.title")}
            description={trans("pages.occupancy.archive.sections.data.description")}
            icon={InsightsIcon}
          />
          <Graph />
        </Flex>
        <Flex column gap={16}>
          <PageSection
            title={trans("pages.occupancy.archive.sections.history.title")}
            description={trans("pages.occupancy.archive.sections.history.description")}
            icon={HotelIcon}
          />
          <List />
        </Flex>
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default Archives;
