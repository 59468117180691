import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import PageBottomPadding from "../../../components/PageBottomPadding";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tGetRequest } from "../../../interfaces/apiCalls";
import { tHotelId } from "../../../models/hotel";
import { tManager, tManagerId } from "../../../models/manager";
import {
  constructApiAddress,
  REQUEST_STATUS,
  requestStatusIsFinal,
  setGetRequestStateResolved,
  setRequestStatePending,
  setRequestStateRejected,
  USE_MONOLITH_SERVERLESS,
} from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import PageTitle from "../root/components/pageTitle";
import UserActivitiesWrapped from "./userActivities";
import UserInfo from "./userInfo";

interface iUserWrappedProps {
  staffId: tManagerId;
}

const UserWrapped: React.FC<iUserWrappedProps> = ({ staffId }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const [requestState, setRequestState] = useState<tGetRequest<tManager | null>>({
    data: null,
    status: REQUEST_STATUS.PENDING,
    error: null,
  });

  const run = useCallback(
    (staffId: tManagerId, hotelId: tHotelId) => {
      setRequestStatePending(setRequestState);
      axios
        .get(constructApiAddress(`/v2/hotels/${hotelId}/staff/${staffId}`, USE_MONOLITH_SERVERLESS))
        .then((res) => {
          const {
            data: { staffMember },
          } = res;
          setGetRequestStateResolved(setRequestState, staffMember);
        })
        .catch((err) => {
          setRequestStateRejected(setRequestState, getErrorMessage(err, trans));
        });
    },
    [trans]
  );

  useEffectSafe(() => {
    if (staffId && activeProperty._id) {
      run(staffId, activeProperty._id);
    }
  }, [activeProperty._id, run, staffId]);

  return (
    <div>
      <PageTitle
        loading={!requestStatusIsFinal(requestState.status)}
        page={requestState.data?.name || ""}
        parentPages={[{ transKey: "general.users", to: "/users" }]}
      />
      <UserInfo {...{ status: requestState.status, staff: requestState.data }} />
    </div>
  );
};

const User: React.FC = () => {
  const { staffId } = useParams<{ staffId: string }>();

  if (!staffId) return <Navigate to="/users" />;

  return (
    <div>
      <UserWrapped {...{ staffId }} />
      <div style={{ marginTop: "40px" }}>
        <UserActivitiesWrapped {...{ staffId }} />
      </div>
      <PageBottomPadding />
    </div>
  );
};

export default User;
