import { tHotel } from "../../models/hotel";
import { tManagerId } from "../../models/manager";

export const hotelListAccountStaffRoles = (hotel: tHotel, staffId: tManagerId) => {
  return hotel.staffRoles.filter((r) => r.staff.includes(staffId));
};

export const hotelListVisibleRoles = (hotel: tHotel, profileId: tManagerId) => {
  const roles = hotelListAccountStaffRoles(hotel, profileId);
  const rolesIds = roles.map((r) => r._id);

  return hotel.staffRoles.filter((sr) => !sr.isShadowRole || rolesIds.includes(sr._id));
};

export const hotelListVisibleStaff = (hotel: tHotel, profileId: tManagerId) => {
  const roles = hotelListAccountStaffRoles(hotel, profileId);
  const rolesStaffIds = roles.flatMap((r) => r.staff);

  return hotel.staff.filter((s) => !s.shadowAccount || rolesStaffIds.includes(s.staffId));
};

export const hoteStaffMemberIsAdmin = (hotel: tHotel, staffId: tManagerId) => {
  const roles = hotelListAccountStaffRoles(hotel, staffId);

  for (const role of roles) {
    if (role.isAdmin) return true;
  }
  return false;
};

export const hotelCheckAccountsShareRoles = (
  hotel: tHotel,
  member1: tManagerId,
  member2: tManagerId
) => {
  const roles1 = hotelListAccountStaffRoles(hotel, member1);
  const roles2 = hotelListAccountStaffRoles(hotel, member2);

  for (const r of roles1) {
    if (roles2.includes(r)) return true;
  }
  return false;
};
