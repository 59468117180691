import React, { Fragment } from "react";
import useProfileState from "../../context/Profile/hooks/useProfileState";
import { _isString, _round } from "../../utils/lodash-utils";
import { numberFormatter } from "../../utils/numbers";

interface iProps {
  number: number | string;
  Wrapper?: React.FC;
  props?: React.ComponentProps<React.FC>;
}

const NumberFormatted: React.FC<iProps> = ({ number, Wrapper = Fragment, props }) => {
  const { profile } = useProfileState();

  const fixedNumber =
    _isString(number) || _round(number, 0) === number ? number : number.toFixed(2);

  return <Wrapper {...props}>{numberFormatter(fixedNumber, profile)}</Wrapper>;
};

export default NumberFormatted;
