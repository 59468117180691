import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import moment, { MomentInput } from "moment";
import { useContext } from "react";
import { HotelStateContext } from "../../context/Hotel";
import { tHotelSpaceId } from "../../models/hotel";
import { constructApiAddress, USE_SERVER } from "../../utils/apiCall";

export type tConsumptionTotalBySpace = { [spaceId: string]: Record<"e" | "w", number> };

function useGetConsumptionTotalBySpace(
  id: string,
  params: {
    timeframe: [MomentInput, MomentInput];
    enabled?: boolean;
    spaces?: tHotelSpaceId[];
    measures?: ("e" | "w")[];
  }[]
) {
  const { activeProperty } = useContext(HotelStateContext)!;
  const requests = useQueries<tConsumptionTotalBySpace[]>({
    queries: params.map(
      ({ timeframe: [startDate, endDate], enabled = false, spaces, measures }) => ({
        queryKey: [
          `consumption.total.by-space|${id}|${activeProperty._id}|${startDate}|${endDate}`,
        ],
        retry: 3,
        retryDelay(failureCount, error) {
          return Math.min(failureCount > 1 ? 2 ** failureCount * 1000 : 1000, 30 * 1000);
        },
        enabled,
        queryFn: async ({ signal }: { signal: AbortSignal }) => {
          return axios
            .post<{ consumption: tConsumptionTotalBySpace }>(
              constructApiAddress(
                `/properties/${activeProperty._id}/consumption/total/by-space`,
                USE_SERVER.propertiesMicroservice.local
              ),
              {
                startDate: moment(startDate).toISOString(),
                endDate: moment(endDate).toISOString(),
                spaces,
                measures,
              },
              { signal }
            )
            .then((res) => {
              return res.data.consumption;
            });
        },
      })
    ),
  });

  return requests;
}

export default useGetConsumptionTotalBySpace;
