import { zip } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex/Chart";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../utils/colors";
import { tTableItem } from "./list";

interface iProps {
  tableDataSorted: tTableItem[];
}

const Graph: React.FC<iProps> = ({ tableDataSorted }) => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const [totalDistance, totalFuel] = useMemo(() => {
    let totalDistance = 0;
    let totalFuel = 0;

    activeProperty.fleet.forEach((f) => {
      f.entries.forEach((e) => {
        totalDistance += e.distanceKm;
        totalFuel += e.fuelLiters;
      });
    });

    return [totalDistance, totalFuel];
  }, [activeProperty.fleet]);

  const segments: {
    key: keyof Pick<tTableItem, "distanceKm" | "fuelLiters">;
    label: string;
    value: number;
    unit: string;
  }[] = [
    {
      key: "distanceKm",
      label: trans("general.distance"),
      value: totalDistance,
      unit: "km",
    },
    {
      key: "fuelLiters",
      label: trans("general.fuel"),
      value: totalFuel,
      unit: "L",
    },
  ];

  const renderSegments = () => {
    return (
      <Flex row>
        {segments.map(({ key, label, unit, value }, i) => {
          let labelColor = COLORS.gray_400;
          let valueColor = COLORS.secondary;

          if (selectedSegmentIndex === i) {
            labelColor = valueColor = COLORS.primary;
          }

          return (
            <Flex
              basis={15}
              key={key}
              column
              gap={8}
              style={{
                cursor: "pointer",
                padding: "6px",
                borderRadius: "8px",
              }}
              className="hover-darken-white-bg"
              onClick={() => {
                if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
              }}
            >
              <InterTag size={12} text={label} color={labelColor} />
              <Flex row bottom>
                <InterTag size={36} text={value} color={valueColor} />
                <InterTag size={12} text={unit} color={valueColor} />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const renderGraph = () => {
    const segment = segments[selectedSegmentIndex];

    const [categories, yAxis]: [string[], number[]] = zip(
      ...tableDataSorted.map((item) => {
        const value = item[segment.key];
        return [item.licensePlate, value];
      })
    ) as [string[], number[]];

    if (categories && yAxis)
      return (
        <ApexChart
          {...{
            type: "bar",
            series: [{ name: "", data: yAxis, color: COLORS.primary }],
            categories,
          }}
        />
      );
  };

  return (
    <Flex column gap={16} className="card-m">
      {renderSegments()}
      {renderGraph()}
    </Flex>
  );
};

export default Graph;
