import React, { useState } from "react";
import { LANGAUGES } from "../../utils/languages";
import { _find } from "../../utils/lodash-utils";
import { DEFAULT_LANGAUGE } from "./utils";

export const LS_DATA_LANGUAGE = "__l__";

const LocalizationDispatchContext = React.createContext<
  React.Dispatch<React.SetStateAction<string>> | undefined
>(undefined);
LocalizationDispatchContext.displayName = "LocalizationDispatchContext";

const LocalizationStateContext = React.createContext<string | undefined>(undefined);
LocalizationStateContext.displayName = "LocalizationStateContext";

const LocalizationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>(() => {
    const storage = localStorage.getItem(LS_DATA_LANGUAGE);

    if (!storage || !_find(LANGAUGES, (l) => l.code === storage)) return DEFAULT_LANGAUGE;

    return storage;
  });

  return (
    <LocalizationStateContext.Provider value={language}>
      <LocalizationDispatchContext.Provider value={setLanguage}>
        {children}
      </LocalizationDispatchContext.Provider>
    </LocalizationStateContext.Provider>
  );
};

export { LocalizationContextProvider, LocalizationDispatchContext, LocalizationStateContext };
