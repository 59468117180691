import axios from "axios";
import { find, toNumber, uniqueId } from "lodash";
import React, { useContext, useEffect, useReducer } from "react";
import {
  Button,
  IconButton,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/target.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSection from "../../../components/PageSection";
import { HotelStateContext } from "../../../context/Hotel";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tWithKeyProperty } from "../../../interfaces/others";
import { tHotelLoyaltyProgramItem } from "../../../models/hotel";
import { constructApiAddress, USE_MONOLITH_SERVERLESS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { triDevPrd } from "../../../utils/environment";
import { getAwardDescription } from "../../../utils/hotels/awards";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";

const buildTransKey = (key: string) => `pages.loyalty.modals.loyalty_program.${key}`;

type tData = Omit<
  tHotelLoyaltyProgramItem,
  "_id" | "timesAwarded" | "awardingHistory" | "awards"
> & {
  awards: tWithKeyProperty<tHotelLoyaltyProgramItem["awards"][0]>[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | {
      type: "awards";
      award: Pick<tData["awards"][0], "key"> & Partial<Omit<tData["awards"][0], "key">>;
    }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: { awards: [], goal: 1, status: "active", category: "nights" },
  errors: { awards: "", goal: "", status: "", category: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "awards": {
      const { award } = action;
      for (const [index, a] of Object.entries(state.data.awards)) {
        if (a.key === award.key) {
          state.data.awards[toNumber(index)] = { ...a, ...award };
          break;
        }
      }
      return {
        ...state,
        errors: { ...state.errors, awards: "" },
        data: { ...state.data, awards: [...state.data.awards] },
      };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateEditLoyaltyProgramWrappedProps extends Pick<iProps, "onClose" | "programId"> {}

const CreateEditLoyaltyProgramWrapped: React.FC<iCreateEditLoyaltyProgramWrappedProps> = ({
  onClose,
  programId,
}) => {
  const { trans, language } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const request = usePostRequest();

  useEffect(() => {
    if (programId) {
      const program = find(activeProperty.loyaltyProgram, (lp) => lp._id === programId);

      if (!program) {
        onClose();
        return;
      }

      dispatch({
        type: "data",
        data: {
          awards: program.awards.map(({ awardId, count }) => ({
            awardId,
            count,
            key: uniqueId(),
          })),
          category: program.category,
          goal: program.goal,
          status: program.status,
        },
      });
    }
  }, [activeProperty.loyaltyProgram, onClose, programId]);

  const handleConfirm = () => {
    const awardsToSubmit: tHotelLoyaltyProgramItem["awards"] = state.data.awards
      .filter((a) => a.count > 0 && a.awardId && a.awardId.length > 0)
      .map(({ awardId, count }) => ({ awardId, count }));

    const requestBody: Pick<tHotelLoyaltyProgramItem, "awards" | "category" | "goal" | "status"> = {
      awards: awardsToSubmit,
      category: state.data.category,
      goal: state.data.goal,
      status: state.data.status,
    };

    request.pending();
    (programId
      ? axios.patch(
          constructApiAddress(
            `/v2/hotels/${activeProperty._id}/loyalty/${programId}`,
            USE_MONOLITH_SERVERLESS
          ),
          requestBody
        )
      : axios.post(
          constructApiAddress(`/v2/hotels/${activeProperty._id}/loyalty`, USE_MONOLITH_SERVERLESS),
          requestBody
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(activeProperty._id, hotel);
        request.resolve();
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
      });
  };

  const availableAwards = activeProperty.awards.filter((a) => a.active && !a.removed);

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  return (
    <>
      <Flex column gap={12}>
        <InputWrapper label={trans("general.goal")} icon={TargetIcon}>
          <Flex row gap={8}>
            <InputNumber
              disabled={request.isLoading}
              style={{ flexBasis: "20%" }}
              value={state.data.goal}
              onChange={handleChange("goal")}
              min={1}
            />
            <SelectPicker
              disabled={request.isLoading}
              onChange={(value) => {
                if (value) handleChange("category")(value);
              }}
              cleanable={false}
              searchable={false}
              style={{ flexBasis: "20%" }}
              data={[
                { label: trans("general.nights"), value: "nights" },
                { label: trans("general.stays"), value: "stays" },
              ].map(({ label, value }) => ({
                label,
                value,
              }))}
              value={state.data.category}
            />
          </Flex>
        </InputWrapper>
        <InputWrapper label={trans("general.status")}>
          <Toggle
            disabled={request.isLoading}
            style={{ width: "fit-content" }}
            checked={state.data["status"] === "active"}
            onChange={(checked) =>
              dispatch({
                type: "key value",
                key: "status",
                value: checked ? "active" : "inactive",
              })
            }
            checkedChildren={trans("general.active")}
            unCheckedChildren={trans("general.inactive")}
            size="md"
          />
        </InputWrapper>
        <InputWrapper
          icon={VerifiedIcon}
          label={trans("general.awards")}
          error={state.errors["awards"]}
        >
          {state.data.awards.map(({ awardId, count, key }, i) => {
            return (
              <Flex row gap={16} key={key}>
                <SelectPicker
                  cleanable={false}
                  disabled={request.isLoading}
                  placement="right"
                  menuMaxHeight={200}
                  style={{ flexBasis: triDevPrd("50%", "85%") }}
                  value={awardId}
                  onChange={(value) => {
                    dispatch({
                      type: "awards",
                      award: { awardId: value || "", key },
                    });
                    const awards = state.data.awards;
                    awards[i] = {
                      ...state.data.awards[i],
                      awardId: value || "",
                    };
                    dispatch({
                      type: "key value",
                      key: "awards",
                      value: awards,
                    });
                  }}
                  data={availableAwards.map((a) => {
                    const description = getAwardDescription(a, language);
                    return {
                      label: (
                        <Whisper
                          trigger="hover"
                          placement="topStart"
                          speaker={<Tooltip>{description?.longDescription}</Tooltip>}
                        >
                          <div>{description?.shortDescription}</div>
                        </Whisper>
                      ),
                      value: a._id,
                    };
                  })}
                />
                <InputNumber
                  disabled={request.isLoading}
                  style={{ flexBasis: "15%" }}
                  value={count}
                  min={1}
                  onChange={(value) => {
                    dispatch({
                      type: "awards",
                      award: { awardId, count: toNumber(value) || 1, key },
                    });
                  }}
                />
                <IconButton
                  disabled={request.isLoading}
                  circle
                  icon={
                    <Icon
                      Element={DeleteIcon}
                      fill={request.isLoading ? COLORS.gray_200 : COLORS.error}
                      size={18}
                    />
                  }
                  onClick={() => {
                    const awards = state.data.awards.filter((a) => a.key !== key);
                    dispatch({
                      type: "key value",
                      key: "awards",
                      value: awards,
                    });
                  }}
                />
              </Flex>
            );
          })}
          <Button
            disabled={request.isLoading}
            style={{ width: "fit-content" }}
            className="button-gray"
            onClick={() =>
              dispatch({
                type: "key value",
                key: "awards",
                value: [...state.data.awards, { awardId: "", count: 1, key: uniqueId() }],
              })
            }
            appearance="default"
          >
            {trans(buildTransKey("add_new_award"))}
          </Button>
        </InputWrapper>
        <Modal2ActionButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          isLoading={request.isLoading}
        />
      </Flex>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  programId?: tHotelLoyaltyProgramItem["_id"];
  onClose(): void;
}

const CreateEditLoyaltyProgramItem: React.FC<iProps> = ({ onClose, programId, ...rest }) => {
  const { trans } = useLocalizationState();

  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, overflow: false, ...rest }}>
      <Modal.Header></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <PageSection
            title={
              programId
                ? trans("general.update_x", {
                    parameters: [trans(buildTransKey("title"))],
                  })
                : trans("general.create_x", {
                    parameters: [trans(buildTransKey("title"))],
                  })
            }
          />
          <CreateEditLoyaltyProgramWrapped {...{ onClose, programId }} />
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEditLoyaltyProgramItem;
