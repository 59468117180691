import React, { useContext } from "react";
import { createPortal } from "react-dom";
import { InputGroup, InputNumber } from "rsuite";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { TimeframeDispatchContext, TimeframeStateContext } from "../../../../context/Timeframe";
import WithContextProvider from "../../../../hoc/withProvider";
import useElementForPortal from "../../../../hooks/useElementForPortal";
import { _isString, _toInteger } from "../../../../utils/lodash-utils";
import { ELEMENT_IDS } from "../../../../utils/portal";
import ConsumptionLiveDataContextProvider, {
  ConsumptionLiveDataContext,
} from "./context/dataContext";
import ConsumptionLiveSelectedSegmentContextProvider from "./context/selectedSegmentContext";
import LiveDataGraph from "./graph";
import LiveDataTable from "./table";

const LiveData: React.FC = () => {
  const { trans } = useLocalizationState();
  const { binInfo, setBinInfo } = useContext(ConsumptionLiveDataContext)!;
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;
  const { setSensorDataTimeframe } = useContext(TimeframeDispatchContext)!;
  const portalContainer = useElementForPortal(ELEMENT_IDS.consumptionPageSecondaryTopBar, true);
  return (
    <>
      {portalContainer &&
        createPortal(
          <div className="flex flex-row gap-4 items-center">
            <SimpleDateRangePicker
              value={sensorDataTimeframe}
              onChange={setSensorDataTimeframe}
              style={{ paddingLeft: "8px" }}
            />
            <div className="flex flex-row gap-2 items-center">
              <InterTag text={trans("general.group_by")} />
              <InputGroup>
                <InputNumber
                  className="w-20"
                  min={1}
                  type="number"
                  value={binInfo.binSize}
                  onChange={(value) => {
                    if (
                      !value ||
                      (_isString(value) && value.indexOf(".") !== -1) ||
                      _toInteger(value) < 1
                    )
                      return;
                    setBinInfo({ ...binInfo, binSize: _toInteger(value) });
                  }}
                />
                <InputGroup.Addon>
                  {binInfo.binSize === 1 ? trans("general.day") : trans("general.days")}
                </InputGroup.Addon>
              </InputGroup>
            </div>
          </div>,
          portalContainer
        )}
      <div className="flex flex-col gap-5">
        <div className="flex flex-col 2xl:flex-row gap-5">
          <ConsumptionLiveSelectedSegmentContextProvider>
            <div className="flex-1">
              <LiveDataGraph />
            </div>
            <div className="flex-1">
              <LiveDataTable />
            </div>
          </ConsumptionLiveSelectedSegmentContextProvider>
        </div>
        <div />
      </div>
    </>
  );
};

export default WithContextProvider(ConsumptionLiveDataContextProvider, React.memo(LiveData));
