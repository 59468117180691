import moment from "moment";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import BasicCard from "../../../../components/Cards/BasicCard";
import { iIconBgProps } from "../../../../components/Icons/IconBg";
import { iTextProps } from "../../../../components/Text";
import { toTextProps } from "../../../../components/Text/utils";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { TimeframeStateContext } from "../../../../context/Timeframe";
import useGetConsumptionTotal from "../../../../hooks/consumption/useGetConsumptionTotal";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { TE, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_MASS_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { propertyGetBaseMeasuresTrackedV2 } from "../../../../utils/hotels/measures";
import { _round } from "../../../../utils/lodash-utils";
import { getMeasureInfo } from "../../../../utils/measures";

type tTotalConsumption = Record<"e" | "w", number>;

type tCardMeasures = {
  to: string;
  label: string | iTextProps;
  tooltip?: string;
  icon: iIconBgProps;
  key: string;
  valueFromMeasuresRequest(data: tTotalConsumption): number;
  unit: string;
  converter: nUnitConverter.tFunction;
  loading: boolean;
};

interface iProps {}

const LiveConsumptionCards: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;
  const conversionFactors = useGetDefaultConverionFactors("PT");

  const [measuresRequest2, homologueMeasuresRequest2] = useGetConsumptionTotal("dashboard.live", [
    {
      timeframe: [
        moment(sensorDataTimeframe[0]).toISOString(),
        moment(sensorDataTimeframe[1]).toISOString(),
      ],
    },
    {
      timeframe: [
        moment(sensorDataTimeframe[0]).subtract(1, "year").toISOString(),
        moment(sensorDataTimeframe[1]).subtract(1, "year").toISOString(),
      ],
    },
  ]);

  useEffectSafe(() => {
    measuresRequest2.refetch();
    homologueMeasuresRequest2.refetch();
  }, [sensorDataTimeframe]);

  const measuresList: tCardMeasures[] = [];

  const trackedBaseMeasures = propertyGetBaseMeasuresTrackedV2(activeProperty);

  if (trackedBaseMeasures.e) {
    measuresList.push({
      to: "/consumption/electricity",
      label: trans("general.measures_.electricity"),
      icon: {
        Element: ElectricBoltIcon,
        fill: getMeasureInfo(TE).color,
        backgroundTrasparency: 20,
      },
      key: "e",
      loading: measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return data.e || 0;
      },
      unit: DEFAULT_ENERGY_MEASURE_UNIT,
      converter: convertEnergyUnit,
    });
  }

  if (trackedBaseMeasures.w) {
    measuresList.push({
      to: "/consumption/water",
      label: trans("general.measures_.water"),
      icon: { Element: WaterDropIcon, fill: getMeasureInfo(TW).color },
      key: "w",
      loading: measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return data.w || 0;
      },
      unit: DEFAULT_WATER_MEASURE_UNIT,
      converter: convertVolumeUnit,
    });
  }
  if (measuresList.length) {
    measuresList.unshift({
      to: "/consumption",
      label: { text: trans("general.co_2_emissions"), asHTML: true },
      icon: { Element: EcoIcon, fill: COLORS.esg_environment },
      key: "co2",
      loading: conversionFactors.isLoading || measuresRequest2.isLoading,
      valueFromMeasuresRequest(data) {
        return (
          ((data.e || 0) / 1000) * conversionFactors.data.electricity_kWh_to_kgCO2e +
          ((data.w || 0) / 1000) * conversionFactors.data.water_m3_to_kgCO2e
        );
      },
      unit: DEFAULT_MASS_MEASURE_UNIT,
      converter: convertMassUnit,
    });
  }

  if (measuresRequest2.isError)
    return (
      <>
        {measuresList.map(({ to, label, icon, key }) => {
          return (
            <BasicCard
              key={key}
              onClick={() => {
                navigate(to);
              }}
              hover
              icon={icon}
              label={{ ...toTextProps(label), asHTML: true }}
              error={measuresRequest2.error?.message || ""}
              reload
              onReload={(e) => {
                e.stopPropagation();
                measuresRequest2.refetch();
                homologueMeasuresRequest2.refetch();
              }}
            />
          );
        })}
      </>
    );

  if (measuresRequest2.isSuccess)
    return (
      <>
        {measuresList.map(({ to, label, icon, key, unit, valueFromMeasuresRequest, converter }) => {
          const value = valueFromMeasuresRequest(measuresRequest2.data as tTotalConsumption);
          const converted = converter(value, { from: unit });
          return (
            <BasicCard
              key={key}
              onClick={() => {
                navigate(to);
              }}
              hover
              icon={icon}
              value={_round(converted.value, 2)}
              valueUnit={converted.unitInHTML}
              valueProps={{ asHTML: true }}
              label={{ ...toTextProps(label) }}
              comparisonLoading={
                !homologueMeasuresRequest2.isSuccess && !homologueMeasuresRequest2.isError
              }
              {...(homologueMeasuresRequest2.isSuccess && {
                comparison: {
                  baseValue: valueFromMeasuresRequest(
                    homologueMeasuresRequest2.data as tTotalConsumption
                  ),
                  value: value,
                },
              })}
            />
          );
        })}
      </>
    );
  return (
    <>
      {measuresList.map(({ to, label, icon, key }) => (
        <BasicCard
          key={key}
          onClick={() => {
            navigate(to);
          }}
          hover
          icon={icon}
          value={""}
          valueLoading
          comparisonLoading
          label={{ ...toTextProps(label), asHTML: true }}
        />
      ))}
    </>
  );
};

export default LiveConsumptionCards;
