import tHotel, {
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
} from "../../models/hotel";
import { _find, _groupBy, _isArray } from "../lodash-utils";

export const propertyFindSpace = (hotel: tHotel, spaceId: tHotelSpaceId) => {
  return _find(hotel.spaces, (s) => s._id === spaceId);
};

export const propertyFindSpaces = (hotel: tHotel, spaceIds: tHotelSpaceId[]) => {
  return hotel.spaces.filter((s) => spaceIds.includes(s._id));
};

export const propertyFindSpaceByName = (hotel: tHotel, spaceName: string) => {
  return _find(hotel.spaces, (s) => s.name === spaceName);
};

export const propertyFindSpaceAggregate = (
  hotel: tHotel,
  spaceAggregateId: tHotelSpaceAggregateId
) => {
  return _find(hotel.spaceAggregates, (s) => s._id === spaceAggregateId);
};

export const propertyFindSpaceAggregates = (
  hotel: tHotel,
  spaceAggregateIds: tHotelSpaceAggregateId[]
) => {
  return hotel.spaceAggregates.filter((s) => spaceAggregateIds.includes(s._id));
};

export const propertyFindSpaceAggregateByName = (hotel: tHotel, spaceAggregateName: string) => {
  return _find(hotel.spaceAggregates, (s) => s.name === spaceAggregateName);
};

export const propertyGroupSpaceAggregatesByCategory = (hotel: tHotel) => {
  const obj = {
    group: [],
    type: [],
    zone: [],
    ..._groupBy(hotel.spaceAggregates, "category"),
  } as Record<"type" | "group" | "zone", tHotelSpaceAggregate[]>;
  return obj;
};

export const propertyFindSpaceSpaceAggregates = (
  activeProperty: tHotel,
  spaceId: tHotelSpaceId,
  categories?: tHotelSpaceAggregate["category"][] | tHotelSpaceAggregate["category"]
): tHotelSpaceAggregate[] => {
  const spaceAggregatesToUse =
    categories === undefined
      ? activeProperty.spaceAggregates
      : (
          (_isArray(categories) ? categories : [categories]) as tHotelSpaceAggregate["category"][]
        ).flatMap((category) => propertyGroupSpaceAggregatesByCategory(activeProperty)[category]);

  const spaceAggregates = spaceAggregatesToUse.filter((g) => g.spaces.includes(spaceId));
  return spaceAggregates;
};
