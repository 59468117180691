import { DEFAULT_LANGAUGE } from "../context/Localization/utils";
import { tLanguageCode } from "./languages";
import { _find } from "./lodash-utils";

export const sleep = async (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(false);
    }, ms);
  });

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const NOYTRALL_0 = "∅";

export const openNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export function getDescription<T = any>(
  descriptions: ({ language: string } & T)[],
  language: tLanguageCode | string
): ({ language: string } & T) | undefined {
  return (
    _find(descriptions, (d) => d.language === language) ||
    _find(descriptions, (d) => d.language === DEFAULT_LANGAUGE) ||
    descriptions[0]
  );
}

export const preventDefault = (func: () => void) => (e: any) => {
  e.preventDefault();
  func();
};

export const naryOperator = (
  baseValue: number,
  condition1: { compare: number; returnValue: number },
  ...conditions: { compare: number; returnValue: number }[]
) =>
  [condition1, ...conditions].find((c) => c.compare > baseValue)?.returnValue ||
  condition1.returnValue;
