import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import BasicCard from "../../../../components/Cards/BasicCard";
import { HotelStateContext } from "../../../../context/Hotel";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { TimeframeStateContext } from "../../../../context/Timeframe";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import { tIconElement } from "../../../../interfaces/icon";
import { tHotelSpaceId } from "../../../../models/hotel";
import { constructApiAddress, USE_SERVER } from "../../../../utils/apiCall";
import { propertyIsOneSettingOn } from "../../../../utils/hotels/settings";
import { propertyActiveSubscriptionTypeIsStarter } from "../../../../utils/hotels/subscriptions";
import { _round } from "../../../../utils/lodash-utils";

type tOccupancyData = {
  numberOfGuests: number;
  occupancy: number;
  spacesOccupied: tHotelSpaceId[];
};

interface iProps {}

const LiveOccupancyCard: React.FC<iProps> = () => {
  const {
    data: { sensorDataTimeframe },
  } = useContext(TimeframeStateContext)!;
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const activeSubscriptionIsStarter = propertyActiveSubscriptionTypeIsStarter({
    property: activeProperty,
  });

  const [occupancyRequest, homologueOccupancyRequest] = useQueries<tOccupancyData[], any>({
    queries: [
      [moment(sensorDataTimeframe[0]).toISOString(), moment(sensorDataTimeframe[1]).toISOString()],
      [
        moment(sensorDataTimeframe[0]).subtract(1, "year").toISOString(),
        moment(sensorDataTimeframe[1]).subtract(1, "year").toISOString(),
      ],
    ].map(([from, to]) => ({
      queryKey: [`occupancy-${activeProperty._id}|${from}|${to}`],
      enabled: false,
      queryFn: async ({ signal }: { signal: AbortSignal }) => {
        if (activeSubscriptionIsStarter)
          return { numberOfGuests: 0, occupancy: 0, spacesOccupied: [] };
        return axios
          .get(
            constructApiAddress(
              `/v2/hotels/${activeProperty._id}/occupancy/guests-spaces-occupancy`,
              USE_SERVER.monolith.local
            ),
            {
              params: {
                from,
                to,
              },
              signal,
            }
          )
          .then((res) => res.data.data);
      },
    })),
  });

  useEffectSafe(() => {
    occupancyRequest.refetch();
    homologueOccupancyRequest.refetch();
  }, [sensorDataTimeframe]);

  const list: {
    key: keyof Pick<tOccupancyData, "numberOfGuests" | "occupancy">;
    label: string;
    icon: tIconElement;
    to: string;
    transformer(value: number): number;
    unit?: string;
  }[] = [
    {
      key: "numberOfGuests",
      label: trans("general.guests"),
      icon: GroupIcon,
      to: "/guests",
      transformer: (v) => v,
    },
    {
      key: "occupancy",
      label: trans("general.occupancy"),
      icon: HotelIcon,
      to: "/occupancy",
      transformer: (v) => v * 100,
      unit: "%",
    },
  ];

  if (
    !propertyIsOneSettingOn(activeProperty, [
      "pages.dashboard.guests.show",
      "pages.dashboard.occupancy.show",
    ])
  )
    return null;

  return (
    <>
      {list.map(({ key, label, to, icon, transformer, unit }) => {
        return (
          <BasicCard
            key={key}
            hover
            onClick={() => {
              navigate(to);
            }}
            {...{
              icon,
              label,
              ...(occupancyRequest.isError
                ? null
                : occupancyRequest.isSuccess
                ? {
                    value: `${_round(transformer(occupancyRequest.data[key]), 2)}${
                      unit ? ` ${unit}` : ""
                    }`,

                    ...(homologueOccupancyRequest.isError
                      ? null
                      : homologueOccupancyRequest.isSuccess
                      ? {
                          comparison: {
                            baseValue: homologueOccupancyRequest.data[key],
                            value: occupancyRequest.data[key],
                          },
                        }
                      : { comparisonLoading: true }),
                  }
                : { valueLoading: true, value: "", comparisonLoading: true }),
            }}
          />
        );
      })}
    </>
  );
};

export default LiveOccupancyCard;
