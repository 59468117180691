import axios from "axios";
import React, { useContext, useState } from "react";
import { Button } from "rsuite";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import { HotelStateContext } from "../../../../context/Hotel";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../../context/Permissions";
import useProfileState from "../../../../context/Profile/hooks/useProfileState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import useScreenSize from "../../../../hooks/useScreenSize";
import useSimpleToaster from "../../../../hooks/useSimpleToaster";
import {
  tHotelStaffPermissions,
  tPropertyStaffRole,
  tPropertyStaffRoleId,
} from "../../../../models/hotel";
import { tPropertyRolePermission } from "../../../../models/hotel/roles";
import { constructApiAddress, USE_SERVER } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { hotelListVisibleRoles } from "../../../../utils/hotels/staff";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { _has, _omit } from "../../../../utils/lodash-utils";

interface iProps {
  outletContainerHeight: number;
  outletContainerWidth: number;
}

const PermissionsTabWrapped: React.FC<iProps> = ({
  outletContainerHeight,
  outletContainerWidth,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const { updateHotel } = useHotelDispatch();
  const { profile } = useProfileState();
  const [permissionsUpdates, setPermissionsUpdates] = useState<
    Record<tPropertyStaffRoleId, Partial<Record<tPropertyRolePermission, boolean>>>
  >({});
  const putRequest = usePutRequest();
  const { hasPermission } = useContext(PermissionsContext)!;

  const handleChange = (
    roleId: tPropertyStaffRoleId,
    key: tPropertyRolePermission,
    value: boolean
  ) => {
    setPermissionsUpdates((prev) => {
      if (_has(prev, [roleId, key]) && prev[roleId][key] !== value) {
        return Object.fromEntries(
          Object.entries({
            ...prev,
            [roleId]: { ..._omit(prev[roleId], [key]) },
          })
            .filter((entry) => {
              const [, permissions] = entry as [tPropertyStaffRoleId, tHotelStaffPermissions];
              return Object.values(permissions).length;
            })
            .map((entry) => entry)
        );
      }
      return {
        ...prev,
        [roleId]: { ...prev[roleId], [key]: value },
      };
    });
  };

  const hasChanges = Object.keys(permissionsUpdates).length !== 0;

  const permissionsStructure: {
    key: string;
    label: string;
    children: { key: tPropertyRolePermission; label: string }[];
  }[] = [
    {
      key: "manualData",
      label: "Manual Data",
      children: [
        {
          key: "read:manual-data",
          label: "pages.users.sections.management.segments.permissions.read",
        },
        {
          key: "create:manual-data",
          label: "pages.users.sections.management.segments.permissions.write",
        },
      ],
    },
    {
      key: "stays",
      label: "general.reservations",
      children: [
        {
          key: "read:stays",
          label: "pages.users.sections.management.segments.permissions.read",
        },
        {
          key: "create:stays",
          label: "pages.users.sections.management.segments.permissions.write",
        },
      ],
    },
    {
      key: "spaces",
      label: "general.spaces",
      children: [
        {
          key: "read:spaces",
          label: "pages.users.sections.management.segments.permissions.read",
        },
        {
          key: "create:spaces",
          label: "pages.users.sections.management.segments.permissions.write",
        },
      ],
    },
    {
      key: "awards",
      label: "general.awards",
      children: [
        {
          key: "read:awards",
          label: "pages.users.sections.management.segments.permissions.read",
        },
        {
          key: "create:awards",
          label: "pages.users.sections.management.segments.permissions.write",
        },
      ],
    },
    {
      key: "challenges",
      label: "general.challenges",
      children: [
        {
          key: "read:challenges",
          label: "pages.users.sections.management.segments.permissions.read",
        },
        {
          key: "create:challenges",
          label: "pages.users.sections.management.segments.permissions.write",
        },
      ],
    },
    {
      key: "users",
      label: "general.users",
      children: [
        {
          key: "create:staff",
          label: "pages.users.sections.management.segments.permissions.write",
        },
        {
          key: "read:staff:last-access",
          label: "pages.users.sections.management.segments.permissions.view_user_last_access",
        },
        {
          key: "read:staff:activities",
          label: "pages.users.sections.management.segments.permissions.view_user_activities",
        },
      ],
    },
  ];

  const renderPermissionsLabels = () => {
    return (
      <Flex column gap={12}>
        {permissionsStructure.map(({ key, children, label }) => {
          return (
            <Flex column gap={8} key={key}>
              <InterTag
                style={{ marginLeft: "12px" }}
                size={14}
                text={trans(label)}
                color={COLORS.secondary}
              />
              <Flex column>
                {children.map(({ key, label }) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        borderBottom: `1px solid ${COLORS.gray_200}`,
                        padding: "4px 0",
                      }}
                      key={key}
                    >
                      <InterTag
                        style={{ marginLeft: "24px" }}
                        text={trans(label)}
                        size={12}
                        color={COLORS.secondary}
                      />
                    </div>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const renderPermissionsCheckboxes = (staffRole: tPropertyStaffRole) => {
    return (
      <Flex column gap={12}>
        {permissionsStructure.map(({ key, children, label }) => {
          return (
            <Flex column gap={8} key={key}>
              <InterTag
                size={14}
                text={trans(label)}
                color={COLORS.secondary}
                style={{ visibility: "hidden" }}
              />
              <Flex column>
                {children.map(({ key }) => {
                  return (
                    <div
                      key={key}
                      style={{
                        width: "100%",
                        borderBottom: `1px solid ${COLORS.gray_200}`,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        <input
                          style={{
                            ...(_has(permissionsUpdates, `${staffRole._id}.${key}`)
                              ? {
                                  outline: `2px solid ${COLORS.primary}`,
                                }
                              : {}),
                          }}
                          disabled={
                            staffRole.isAdmin ||
                            !hasPermission("update:roles") ||
                            putRequest.isLoading
                          }
                          type="checkbox"
                          checked={
                            _has(permissionsUpdates, [staffRole._id, key])
                              ? permissionsUpdates[staffRole._id][key]
                              : staffRole.permissionsList.includes(key)
                          }
                          onChange={(event) => {
                            !staffRole.isAdmin &&
                              handleChange(staffRole._id, key, event.target.checked);
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const handleSave = () => {
    putRequest.pending();
    axios
      .patch(
        constructApiAddress(
          `/properties/${activeProperty._id}/staff-roles/permissions`,
          USE_SERVER.propertiesMicroservice.local
        ),
        {
          staffRoles: permissionsUpdates,
        }
      )
      .then((res) => {
        const {
          data: { property },
        } = res;
        updateHotel(activeProperty._id, property);
        toaster.success(
          trans(
            "pages.users.sections.management.segments.permissions.notifications.permissions_updated"
          )
        );
        putRequest.resolve();
        setPermissionsUpdates({});
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
        toaster.error(error);
      });
  };

  const visibleRoles = hotelListVisibleRoles(activeProperty, profile._id);

  return (
    <Flex column gap={8}>
      <div className="table-wrapper permissions-checkboxes">
        <div
          style={{
            maxHeight: `${outletContainerHeight - 330}px`,
            overflow: "auto",
            backgroundColor: COLORS.gray_0,
          }}
        >
          <Flex row>
            <Flex column gap={8}>
              <Flex
                center
                style={{
                  backgroundColor: COLORS.gray_200,
                  padding: "8px",
                  borderTopLeftRadius: "4px",
                }}
              >
                <InterTag
                  size={14}
                  text={trans("general.permissions")}
                  style={{ visibility: "hidden" }}
                  color={COLORS.secondary}
                />
              </Flex>
              {renderPermissionsLabels()}
            </Flex>
            <Flex
              one
              row
              style={{
                maxWidth: `${outletContainerWidth - 185}px`,
                overflowX: "auto",
              }}
            >
              {visibleRoles.map((staffRole, i) => {
                return (
                  <Flex column one gap={8} key={staffRole._id}>
                    <Flex
                      center
                      style={{
                        backgroundColor: COLORS.gray_200,
                        padding: "8px",
                        ...(i + 1 === visibleRoles.length ? { borderTopRightRadius: "4px" } : {}),
                      }}
                    >
                      <InterTag size={14} bold text={staffRole.name} color={COLORS.secondary} />
                    </Flex>
                    {renderPermissionsCheckboxes(staffRole)}
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </div>
      </div>
      <Flex row gap={8} right>
        <Button
          disabled={!hasChanges || putRequest.isLoading}
          style={{ paddingTop: "4px", paddingBottom: "4px" }}
          appearance="primary"
          onClick={() => setPermissionsUpdates({})}
        >
          {trans("general.reset")}
        </Button>
        <Button
          disabled={!hasChanges || putRequest.isLoading}
          style={{ paddingTop: "4px", paddingBottom: "4px" }}
          appearance="primary"
          onClick={handleSave}
        >
          {trans("general.save")}
        </Button>
      </Flex>
    </Flex>
  );
};

const PermissionsTab: React.FC = () => {
  const { outlet: oc } = useScreenSize();

  if (!oc) return null;

  return (
    <PermissionsTabWrapped outletContainerHeight={oc.height} outletContainerWidth={oc.width} />
  );
};

export default PermissionsTab;
