import tHotel from "../../models/hotel";
import { _get, _isString } from "../lodash-utils";

export const propertyIsSettingOn = (hotel: tHotel, path: string | string[]) => {
  const paths = _isString(path) ? [path] : path;
  for (const path of paths) {
    if (!_get(hotel.settings, path)) return false;
  }
  return true;
};

export const propertyAreAllSettingsOn = (hotel: tHotel, paths: string[]) => {
  for (const path of paths) {
    if (!_get(hotel.settings, path)) return false;
  }
  return true;
};

export const propertyIsOneSettingOn = (hotel: tHotel, paths: string[]) => {
  for (const path of paths) {
    if (_get(hotel.settings, path)) return true;
  }
  return false;
};
