import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import { _isNumber } from "../../../utils/lodash-utils";
import ReloadButton from "../../Buttons/ReloadButton";
import StraightComparison, { iStraightComparisonProps } from "../../Comparions/straightComparison";
import Icon from "../../Icons/Icon";
import { toIconProps } from "../../Icons/Icon/utils";
import IconBg, { iIconBgProps } from "../../Icons/IconBg";
import NumberFormatted from "../../Numbers/NumberFormatted";
import SkeletonText from "../../Skeleton/SkeletonText";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

export interface iBasicCardProps {
  icon: tIconElement | iIconBgProps;
  label: string | iTextProps;
  value?: number | string;
  valueUnit?: string;
  valueProps?: Partial<iTextProps>;
  valueLoading?: boolean;
  error?: string;
  reload?: boolean;
  onReload?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  comparison?: iStraightComparisonProps;
  comparisonLoading?: boolean;
  hover?: boolean;
  showBorder?: boolean;
  [key: string]: any;
}

const BasicCard: React.FC<iBasicCardProps> = ({
  icon,
  label,
  value,
  valueLoading,
  valueProps,
  valueUnit,
  comparison,
  comparisonLoading,
  hover,
  error,
  reload,
  onReload,
  showBorder,
  ...props
}) => {
  const textProps = {
    size: 20,
    bold: true,
    color: COLORS.secondary,
    ...valueProps,
  };

  const iconProps = toIconProps(icon);
  const color = iconProps.fill || COLORS.secondary;

  const { className, style, ...rest } = props;
  return (
    <div
      className={"p-1 rounded-xl" + (hover ? " hover-box-shadow" : "")}
      style={{ backgroundColor: showBorder ? color : COLORS.white }}
    >
      <div
        className={[
          "flex flex-row items-center gap-4 rounded-lg bg-white",
          className,
          className && className.match(/p-\d(\.\d)?/g) ? "" : "p-3",
        ]
          .filter((v) => v)
          .join(" ")}
        {...{
          ...rest,
          style: {
            cursor: hover ? "pointer" : "default",
            ...style,
          },
        }}
      >
        <IconBg {...{ size: 32, circle: true, ...iconProps }} />
        <div className="flex flex-col gap-2 flex-1 justify-center">
          <InterTag {...{ size: 14, color: COLORS.secondary, ...toTextProps(label) }} />
          {error ? (
            <div className="flex flex-row gap-1 items-center">
              <Whisper trigger="hover" placement="topStart" speaker={<Tooltip>{error}</Tooltip>}>
                <div className="flex items-center justify-center">
                  <Icon Element={WarningIcon} fill={COLORS.warning} size={24} />
                </div>
              </Whisper>
              {reload && onReload && <ReloadButton onClick={onReload} />}
            </div>
          ) : (
            <div className="flex flex-col gap-1">
              {valueLoading ? (
                <SkeletonText width={100} height={textProps.size} />
              ) : (
                <div className="flex flex-row gap-1">
                  {_isNumber(value) ? (
                    <NumberFormatted Wrapper={InterTag} props={textProps} number={value} />
                  ) : (
                    <InterTag {...textProps} text={value} />
                  )}
                  {valueUnit && (
                    <InterTag
                      {...{
                        ...textProps,
                        style: {
                          ...textProps.style,
                        },
                      }}
                      text={valueUnit}
                    />
                  )}
                </div>
              )}
              {comparisonLoading ? (
                <SkeletonText width={100} height={14} />
              ) : (
                comparison && (
                  <StraightComparison {...{ unitSize: 14, valueSize: 14, ...comparison }} />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicCard;
