import React, { useEffect, useRef } from "react";
import { COLORS } from "../../utils/colors";
import { _isString } from "../../utils/lodash-utils";

interface iProps {
  fromColor?: string;
  toColor?: string;
  style?: React.CSSProperties;
  height?: number | string;
  width?: number | string;
}

const SkeletonText: React.FC<iProps> = ({
  fromColor = COLORS.gray_200,
  toColor = COLORS.gray_400,
  width = 100,
  height = 20,
  style,
}) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current) {
      ref.current.animate(
        [
          // key frames
          { backgroundColor: fromColor },
          { backgroundColor: toColor },
          { backgroundColor: fromColor },
        ],
        {
          // sync options
          duration: 1000,
          iterations: Infinity,
        }
      );
    }
  }, [fromColor, toColor]);

  const widthProperty = _isString(width) ? width : `${width}px`;
  const heightProperty = _isString(height) ? height : `${height}px`;

  return (
    <div
      ref={ref}
      className="rounded"
      style={{
        width: widthProperty,
        height: heightProperty,
        ...style,
      }}
    />
  );
};

export default SkeletonText;
