import React, { useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspacesIcon } from "../../../assets/icons/workspaces.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import Icon from "../../../components/Icons/Icon";
import MeasureConsumption from "../../../components/MeasureConsumption";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelSpace } from "../../../models/hotel";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  TE,
  tMeasureEnergySpecific,
  tMeasureWaterSpecific,
  TW,
} from "../../../models/measures";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../utils/convertUnits";
import { hotelGetMeasuresTracked } from "../../../utils/hotels/measures";
import { propertyFindSpace, propertyFindSpaceSpaceAggregates } from "../../../utils/hotels/spaces";
import PageTitle from "../root/components/pageTitle";

interface iProps {
  space: tHotelSpace;
}

const SpaceWrapped: React.FC<iProps> = ({ space }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;

  const [period, setPeriod] = usePeriodState();

  const spaceGroups = propertyFindSpaceSpaceAggregates(activeProperty, space._id, "group");
  const spaceZones = propertyFindSpaceSpaceAggregates(activeProperty, space._id, "zone");
  const spaceTypes = propertyFindSpaceSpaceAggregates(activeProperty, space._id, "type");

  const trackedMeasures = hotelGetMeasuresTracked(activeProperty, space._id);

  const electricitySpecificMeasures: {
    measureKey: tMeasureEnergySpecific;
  }[] = [];
  const waterSpecificMeasures: {
    measureKey: tMeasureWaterSpecific;
  }[] = [];

  if (trackedMeasures.te) {
    MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m]) electricitySpecificMeasures.push({ measureKey: m });
    });
  }
  if (trackedMeasures.tw) {
    MEASURES_WATER_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m]) waterSpecificMeasures.push({ measureKey: m });
    });
  }

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8}>
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
          {spaceGroups.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceGroups.map((sg) => ({
                key: sg._id,
                label: sg.name,
                onClick() {
                  navigate(`/space-aggregates/${sg._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon Element={WorkspacesIcon} size={16} fill={COLORS.secondary} />
                <InterTag
                  size={14}
                  text={`${trans("general.space_categories_.short.groups")}: ${spaceGroups
                    .map(({ name }) => name)
                    .join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
          {spaceZones.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceZones.map((sz) => ({
                key: sz._id,
                label: sz.name,
                onClick() {
                  navigate(`/space-aggregates/${sz._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon Element={StacksIcon} size={16} fill={COLORS.secondary} />
                <InterTag
                  size={14}
                  text={`${trans("general.space_categories_.short.zones")}: ${spaceZones
                    .map(({ name }) => name)
                    .join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
          {spaceTypes.length > 0 && (
            <SimpleWhisperPopoverDropdown
              options={spaceTypes.map((st) => ({
                key: st._id,
                label: st.name,
                onClick() {
                  navigate(`/space-aggregates/${st._id}`);
                },
              }))}
            >
              <Flex
                row
                gap={4}
                middle
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  width: "fit-content",
                  padding: "9px",
                  cursor: "pointer",
                }}
                className="hover-box-shadow"
              >
                <Icon Element={CategoryIcon} size={16} fill={COLORS.secondary} />
                <InterTag
                  size={14}
                  text={`${trans("general.space_categories_.short.types")}: ${spaceTypes
                    .map(({ name }) => name)
                    .join(", ")}`}
                />
              </Flex>
            </SimpleWhisperPopoverDropdown>
          )}
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.te && (
          <MeasureConsumption
            mainMeasureKey={TE}
            specificMeasures={electricitySpecificMeasures}
            converter={convertEnergyUnit}
            binUnit={"day"}
            binValue={1}
            mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
            {...{
              spaces: [space._id],
              from: period[0],
              to: period[1],
            }}
          />
        )}
        {trackedMeasures.tw && (
          <MeasureConsumption
            mainMeasureKey={TW}
            specificMeasures={waterSpecificMeasures}
            converter={convertVolumeUnit}
            binUnit={"day"}
            binValue={1}
            mainUnit={DEFAULT_WATER_MEASURE_UNIT}
            {...{
              spaces: [space._id],
              from: period[0],
              to: period[1],
            }}
          />
        )}
      </Flex>
    </div>
  );
};

const Space: React.FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const { activeProperty } = useContext(HotelStateContext)!;

  if (!spaceId) return <Navigate to="/spaces" />;

  const space = propertyFindSpace(activeProperty, spaceId);

  if (!space) return <Navigate to="/spaces" />;

  return (
    <>
      <PageTitle page={space.name} parentPages={[{ transKey: "general.spaces", to: "/spaces" }]} />
      <IpValidatorGuard>
        <div>
          <SpaceWrapped {...{ space }} />
          <PageBottomPadding />
        </div>
      </IpValidatorGuard>
    </>
  );
};

export default Space;
