import React, { useContext, useState } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import GettingStarted, { GettingStartedFinalModal } from "../../../components/GettingStarted";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageSection from "../../../components/PageSection";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import { DataTypeStateContext } from "../../../context/DataType";
import { HotelStateContext } from "../../../context/Hotel";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { PermissionsContext } from "../../../context/Permissions";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import { TimeframeDispatchContext, TimeframeStateContext } from "../../../context/Timeframe";
import { propertyActiveSubscriptionTypeIsEnterprise } from "../../../utils/hotels/subscriptions";
import PageTitle from "../root/components/pageTitle";
import ArchivesConsumptionCards from "./archives/consumptionCards";
import ArchivesOccupancyCard from "./archives/occupancyCard";
import CheckInCheckOut from "./checkInCheckOut";
import LiveConsumptionCards from "./live/consumptionCards";
import LiveOccupancyCard from "./live/occupancyCard";

const buildTransKey = (key: string) => `pages.overview.sections.summary.${key}`;

const DashboardWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeProperty } = useContext(HotelStateContext)!;
  const [finalModal, setFinalModal] = useState(false);
  const activeSubscriptionIsEnterprise = propertyActiveSubscriptionTypeIsEnterprise({
    property: activeProperty,
  });
  const {
    data: { sensorDataTimeframe, manualDataIndex },
  } = useContext(TimeframeStateContext)!;
  const { setSensorDataTimeframe, setManualDataIndex } = useContext(TimeframeDispatchContext)!;
  const dataTypeContext = useContext(DataTypeStateContext)!;
  const { hasPermission } = useContext(PermissionsContext)!;

  const [OccupancyCardComponent, ConsumptionCardsComponent] = (() => {
    if (!activeSubscriptionIsEnterprise) return [ArchivesOccupancyCard, ArchivesConsumptionCards];
    if (dataTypeContext.isPending) return [null, null];
    if (dataTypeContext.dataTypeIsLive) return [LiveOccupancyCard, LiveConsumptionCards];
    return [ArchivesOccupancyCard, ArchivesConsumptionCards];
  })();

  const render = () => (
    <div className="bg-zinc-200/45 rounded-md p-3 shadow-md">
      <PageSection
        title={trans(buildTransKey("title"))}
        description={trans(buildTransKey("description"))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <CheckInCheckOut />
            {OccupancyCardComponent && <OccupancyCardComponent />}
          </div>
          <div className="flex flex-row gap-4 flex-wrap">
            {ConsumptionCardsComponent && <ConsumptionCardsComponent />}
          </div>
        </div>
      </PageSection>
    </div>
  );

  return (
    <div>
      <SecondaryTopBar>
        <div className="flex flex-row gap-2 items-center">
          <DataTypeToggle />
          {dataTypeContext.dataTypeIsLive ? (
            <SimpleDateRangePicker
              value={sensorDataTimeframe}
              onChange={setSensorDataTimeframe}
              style={{ paddingLeft: "8px" }}
            />
          ) : (
            <>
              <SimpleDateRangePickerForManualDataPeriods2
                {...{ manualDataIndex, setManualDataIndex }}
              />
            </>
          )}
        </div>
      </SecondaryTopBar>
      {dataTypeContext.dataTypeIsArchives ? (
        activeProperty.isSetup ? (
          <>
            <GettingStartedFinalModal open={finalModal} onClose={() => setFinalModal(false)} />
            {render()}
          </>
        ) : hasPermission("create:manual-data") ? (
          <GettingStarted startModalOpen={true} onInsert={() => setFinalModal(true)} />
        ) : null
      ) : (
        render()
      )}
    </div>
  );
};

const Dashboard: React.FC = () => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();

  const names = profile.name.split(" ");

  return (
    <>
      <PageTitle
        page={`${trans("pages.overview.title", {
          parameters: [names.length > 1 ? names.slice(0, -1).join(" ") : names[0]],
        })} &#128075;`}
      />
      <IpValidatorGuard>
        <DashboardWrapped />
      </IpValidatorGuard>
    </>
  );
};

export default Dashboard;
