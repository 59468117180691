import React, { useEffect, useReducer } from "react";
import {
  DATA_TYPE_TOGGLE_ARCHIVES,
  DATA_TYPE_TOGGLE_LIVE,
  DATA_TYPE_TOGGLE_LS,
  tDataTypeToggle,
} from "../../utils/dataTypeToggle";
import { nDataType } from "./interfaces";

const DataTypeDispatchContext = React.createContext<nDataType.tDispatchContext | undefined>(
  undefined
);
DataTypeDispatchContext.displayName = "DataTypeDispatchContext";
const DataTypeStateContext = React.createContext<nDataType.tStateContext | undefined>(undefined);
DataTypeStateContext.displayName = "DataTypeStateContext";

const LS_DATA = "";

const initialState: nDataType.tState = {
  data: {
    dataType: null,
  },
  status: "pending",
};

const reducer = (state: nDataType.tState, action: nDataType.tAction): nDataType.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved" };
    }

    default:
      return { ...state };
  }
};

const DataTypeContextProvider: React.FC<nDataType.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nDataType.tState, React.Dispatch<nDataType.tAction>] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    const get = () => {
      const stored = localStorage.getItem(DATA_TYPE_TOGGLE_LS);
      switch (stored) {
        case DATA_TYPE_TOGGLE_ARCHIVES:
          return DATA_TYPE_TOGGLE_ARCHIVES;
        case DATA_TYPE_TOGGLE_LIVE:
          return DATA_TYPE_TOGGLE_LIVE;
      }
      return DATA_TYPE_TOGGLE_LIVE;
    };
    const dataType = get();

    dispatch({ type: "set data", data: { dataType } });
  }, []);

  const setDataType = (dataType: tDataTypeToggle) => {
    dispatch({ type: "set data", data: { dataType } });
  };

  return (
    <DataTypeStateContext.Provider
      value={{
        isPending: state.status === "pending",
        isResolved: state.status === "resolved",
        dataTypeIsNull: state.data.dataType === null,
        dataTypeIsLive: state.data.dataType === DATA_TYPE_TOGGLE_LIVE,
        dataTypeIsArchives: state.data.dataType === DATA_TYPE_TOGGLE_ARCHIVES,
      }}
    >
      <DataTypeDispatchContext.Provider value={{ setDataType }}>
        {children}
      </DataTypeDispatchContext.Provider>
    </DataTypeStateContext.Provider>
  );
};

export { DataTypeContextProvider, DataTypeDispatchContext, DataTypeStateContext };
